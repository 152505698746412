<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          block
          :x-large="full"
          color="primary"
          @click="openDialog('Tags')"
        >
          <v-icon left> mdi-tag-text </v-icon>
          {{ $t("sidebar.submitTag") }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          :x-large="full"
          dark
          color="green"
          @click="openDialog('Releases')"
        >
          <v-icon left> mdi-fish </v-icon>
          {{ $t("sidebar.submitRelease") }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          :x-large="full"
          dark
          color="orange"
          @click="openDialog('Recaptures')"
        >
          <v-icon left> mdi-fishbowl-outline </v-icon>
          {{ $t("sidebar.submitRecapture") }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          :x-large="full"
          dark
          color="indigo"
          @click="openDialog('Juvenile')"
        >
          <v-icon left> mdi-fishbowl </v-icon>
          {{ $t("sidebar.submitJuvenile") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sendRecaptureMail } from "../../handlers";
export default {
  name: "record-entry",
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    openDialog(type) {
      this.$emit("open", type);
    },
    async mailRecapture() {
      await sendRecaptureMail();
    },
  },
};
</script>

<style scoped>
.col {
  padding: 0;
}
.v-btn {
  border-radius: 0;
  height: 72px !important;
}
</style>
