<template>
  <v-container>
    <v-row class="marquee-view text-center" align="center" justify="center">
      <v-col v-for="s in sponsors" :key="s.name">
        <a :href="s.link" target="_blank">
          <img :src="getSrc(s.img)" />
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sponsors from "./sponsors";
export default {
  name: "marquee-image",
  data: () => ({
    sponsors: Sponsors,
    options: {
      list: { class: "marquee-view", padding: 0 },
    },
  }),
  methods: {
    getSrc(img) {
      return require(`@/assets/images/sponsors/${img}`);
    },
  },
};
</script>

<style scoped>
.marquee-view {
  background: white;
}
.marquee-view img {
  max-height: 50px;
  max-width: 100%;
}
</style>
