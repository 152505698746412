import axios from "axios";

//Import http clients
import entries from "@/api/httpClients/entries";

//Import and initialize http client factory
import HttpClientFactory from "@/api/httpClientFactory";

const clientFactory = new HttpClientFactory(axios);

clientFactory.add(entries);

export default clientFactory;
