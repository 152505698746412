export default [
  {
    img: "Casa-Vieja.jpg",
    link: "https://www.casaviejalodge.com/",
    name: "Casa Vieja Lodge & Sportfishing Fleet",
  },
  {
    img: "Costa.jpg",
    link: "https://www.costadelmar.com/en-us",
    name: "Costa Del Mar",
  },
  {
    img: "Garmin-e1421182373549.png",
    link: "https://www.garmin.com/en-US/",
    name: "Garmin International",
  },
  {
    img: "King-Sailfish-Mounts.jpg",
    link: "http://www.kingsailfishmounts.com/",
    name: "King Sailfish Mounts",
  },
  {
    img: "YETI_RGB-e1442855084551-1.jpg",
    link: "https://www.yeti.com/en_US",
    name: "YETI",
  },
  {
    img: "DG8A2253-resized-400x500-e1452181072320-1.jpg",
    link: "http://www.igfto.org/",
    name: "International Game Fish Tournament Observers Inc.",
  },
  {
    img: "boaterslist.png",
    link: "https://boaterslist.com/",
    name: "Boaters List",
  },
  {
    img: "rto_logo.png",
    link: "https://www.rocktheocean.com/",
    name: "Rock The Ocean",
  },
];
