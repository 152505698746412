<template>
  <v-card>
    <v-snackbar v-model="notify" :timeout="3000" top>
      {{ message }}
    </v-snackbar>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn dark icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ loading ? `${$t("loading")}...` : title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
      striped
      height="5"
    ></v-progress-linear>
    <v-card-text>
      <v-form ref="userForm" class="mt-5" :disabled="loading">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="`* ${$t('form.firstName')}`"
              :rules="rule"
              v-model="input.firstName"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('form.middleName')"
              v-model="input.middleName"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="`* ${$t('form.lastName')}`"
              :rules="rule"
              v-model="input.lastName"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('profileForm.suffix')"
              v-model="input.suffixName"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('profileForm.personalPhone')"
              v-model="input.personalPhone"
              v-mask="'(###) ### ####'"
              :rules="phoneRule"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('profileForm.workPhone')"
              v-model="input.workPhone"
              v-mask="'(###) ### ####'"
              :rules="phoneRule"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('profileForm.homePhone')"
              v-model="input.homePhone"
              v-mask="'(###) ### ####'"
              :rules="phoneRule"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="`* ${$t('email')}`"
              v-model="input.email"
              :rules="rule.concat(emailRule)"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <date-picker
              v-model="input.birthday"
              :label="$t('profileForm.birthday')"
              @clear="input.birthday = null"
              :disable="!!input['virtuousId']"
            />
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('form.memberId')"
              v-model="input.memberId"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
              :items="['Male', 'Female', 'NA']"
              :label="$t('profileForm.gender')"
              v-model="input.gender"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            />
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('profileForm.shirtSize')"
              v-model="input.shirtSize"
              outlined
              clearable
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('profileForm.line1')"
              v-model="input.address.line1"
              outlined
              clearable
              hide-details
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('profileForm.line2')"
              v-model="input.address.line2"
              outlined
              clearable
              hide-details
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('profileForm.city')"
              v-model="input.address.city"
              outlined
              clearable
              hide-details
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
              :items="states"
              :label="$t('profileForm.state')"
              item-text="name"
              item-value="acronym"
              v-model="input.address.state"
              outlined
              clearable
              hide-details
              :dense="!full"
              :disabled="!!input['virtuousId']"
            />
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('profileForm.zip')"
              v-model="input.address.zip"
              outlined
              clearable
              hide-details
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('profileForm.country')"
              v-model="input.address.country"
              outlined
              clearable
              hide-details
              :dense="!full"
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-toolbar flat dark color="primary" dense>
      <v-spacer />
      <v-btn
        small
        @click="submit"
        :disabled="loading || !!input['virtuousId']"
        color="white"
        class="primary--text"
      >
        {{ $t("save") }}
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "../../components/appComponents/DatePicker.vue";
export default {
  components: { DatePicker },
  name: "new-user",
  data: () => ({
    input: initInput,
    notify: false,
    message: null,
    states: [],
    save: "Save",
    loading: false,
    title: "",
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    edit() {
      return !!this.input?._id;
    },
    rule() {
      return [(v) => !!v || this.$t("form.required")];
    },
    emailRule() {
      return [(v) => !v || /.+@.+\..+/.test(v) || this.$t("form.emailRule")];
    },
    phoneRule() {
      return [(v) => !v || v?.length === 14 || this.$t("form.phoneRule")];
    },
  },
  mounted() {
    this.getStates();
  },
  methods: {
    ...mapActions("users", [
      "addUser",
      "getUser",
      "saveUserInfo",
      "checkEmail",
      "checkPersonalPhone",
    ]),
    async submit() {
      const validForm = this.$refs.userForm && this.$refs.userForm.validate();
      if (validForm) {
        this.loading = true;
        this.save = `${this.$t("saving")}...`;

        if (this.edit) {
          const response = await this.saveUserInfo(this.input);
          const { success } = response?.data || {};
          this.notify = true;
          this.message = success ? this.$t("updated") : this.$t("failed");
          this.loading = false;
          this.save = this.$t("save");
          this.close();
          return;
        }

        const { email, personalPhone } = this.input || {};
        const { status } = (await this.checkEmail({ email })) || {};
        if (status === 200) {
          this.notify = true;
          this.message = this.$t("form.emailUsed");
          this.loading = false;
          this.save = this.$t("save");
          return;
        }

        if (personalPhone) {
          const { status } =
            (await this.checkPersonalPhone({ personalPhone })) || {};
          if (status === 200) {
            this.notify = true;
            this.message = this.$t("form.personalPhoneUsed");
            this.loading = false;
            this.save = "Save";
            return;
          }
        }

        const response = await this.addUser(this.input);
        const { success } = response?.data || {};
        this.notify = true;
        this.message = success
          ? this.$t("created")
          : this.$t("form.userExists");
        this.loading = false;
        this.save = this.$t("save");
        this.close();
      }
    },
    init() {
      this.title = this.$t("form.addUser");
      this.$refs.userForm && this.$refs.userForm.reset();
    },
    async getStates(name = "") {
      const payload = { name, item: "states", page: 1, limit: 10 };
      const response = await this.getItems(payload);
      response &&
        response?.data &&
        response.data?.docs &&
        (this.states = response.data.docs);
    },
    async initUser(id) {
      this.loading = true;
      this.input = initInput;
      this.title = this.$t("form.editUser");
      const { data } = await this.getUser({ id });
      data?.address?.state?.length && this.getStates(data.address.state[0]);
      data && (this.input = { ...this.input, ...data });
      this.loading = false;
    },
    close() {
      this.$refs.userForm && this.$refs.userForm.reset();
      this.$emit("close");
    },
    ...mapActions("dashboard", ["getItems"]),
  },
};

const initInput = {
  firstName: null,
  lastName: null,
  role: ["angler"],
  email: null,
  password: "123456abc",
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  },
  personalPhone: null,
  workPhone: null,
  homePhone: null,
  birthday: null,
  gender: null,
  shirtSize: null,
  virtuousId: null,
};
</script>
