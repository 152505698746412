<template>
  <v-app-bar app clipped-left clipped-right color="indigo" dark>
    <!---Logo part -->
    <v-toolbar-title class="align-center d-flex">
      <span>
        <img alt="Billfish" width="60" :src="defaultLogo" />
      </span>
      <span class="logo-text ml-2" :class="`${showLogo ? '' : 'hidelogo'}`">
        <!--<img src="../../../assets/images/logo-light-text.png" class="mt-2" />-->
        <b>TBF</b>Online
      </span>
    </v-toolbar-title>
    <!---Logo part -->
    <!---/Toggle sidebar part -->
    <!-- <div @click="showhideLogo">
      <v-app-bar-nav-icon @click="SET_SIDEBAR_DRAWER(!Sidebar_drawer)" />
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? SET_SIDEBAR_DRAWER(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div> -->
    <v-btn icon @click="toogle">
      <v-icon>
        {{ `mdi-${showLogo ? "back" : "forward"}burger` }}
      </v-icon>
    </v-btn>
    <!---/Toggle sidebar part -->
    <v-spacer />
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text dark v-bind="attrs" v-on="on" tile class="mr-5">
          {{ lang }}
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in langs.filter(
            (lang) => lang.value !== $i18n.locale
          )"
          :key="index"
          @click="$i18n.locale = item.value"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---right part -->
    <!---User -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <v-img :src="srcImg" :lazy-src="defaultAvatar" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          @click="launch(item.title)"
        >
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations, mapActions } from "vuex";
import { defaultAvatar, defaultLogo } from "../../../handlers";
export default {
  name: "HeaderSection",

  components: {},

  props: {
    id: String,
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    image: null,
    // notifications: [
    //   {
    //     title: "Launch Admin",
    //     iconbg: "error",
    //     icon: "mdi-link-variant",
    //     desc: "Just see the my new admin!",
    //     time: "9:30AM",
    //   },
    // ],
    // messages: [
    //   {
    //     title: "Sonu Nigam",
    //     avatar: "1",
    //     avatarstatus: "success",
    //     desc: "Singing Show tonigh at 9pm!",
    //     time: "9:30AM",
    //   },
    // ],
    userprofile: [
      { title: "profile" },
      { title: "tutorials" },
      { title: "logout" },
    ],
    href() {
      return undefined;
    },
    langs: [
      { text: "ENG", value: "en" },
      { text: "ESP", value: "es" },
    ],
  }),
  computed: {
    ...mapState("sidebar", ["Sidebar_drawer"]),
    srcImg() {
      if (typeof this.image === "string") {
        return this.image || defaultAvatar;
      } else {
        const { mime, data } = this.image || {};
        const avatar = mime && data && `data:${mime};base64,${data}`;
        return avatar || defaultAvatar;
      }
    },
    lang() {
      const lang = this.langs?.find(
        (lang) => lang?.value === this.$i18n.locale
      );
      return lang?.text || "eng";
    },
    defaultLogo() {
      return defaultLogo;
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  watch: {
    Sidebar_drawer: {
      handler: "sidebarChanges",
      deep: true,
      immediate: true,
    },
    "$i18n.locale": {
      handler: "setLocale",
    },
  },
  methods: {
    ...mapMutations("sidebar", ["SET_SIDEBAR_DRAWER"]),
    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
    launch(value) {
      switch (value) {
        case "logout":
          localStorage.removeItem("data");
          this.$router.push({ path: "login" });
          break;

        case "profile":
          this.$emit("profile");
          break;

        case "tutorials":
          this.$emit("tutorial");
          break;

        default:
          break;
      }
    },
    updateAvatar(img) {
      this.image = img;
    },
    toogle() {
      this.showLogo = !this.showLogo;
      this.$vuetify.breakpoint.smAndDown
        ? this.SET_SIDEBAR_DRAWER(!this.Sidebar_drawer)
        : this.$emit("input", !this.value);
    },
    sidebarChanges(v) {
      this.showLogo = v;
    },
    setLocale(value) {
      localStorage.setItem("locale", value);
    },
  },
};
</script>

<style lang="scss">
.v-application #main-sidebar.theme--dark.white {
  background: #363636 !important;
}
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>
