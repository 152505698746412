<template>
  <v-navigation-drawer
    v-model="drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
    v-bar
    class="sidebar"
  >
    <!---USer Area -->
    <v-list-item class="profile-bg" dark>
      <v-list-item-avatar size="64">
        <v-img :src="srcImg" :lazy-src="defaultAvatar" />
      </v-list-item-avatar>

      <v-list-item-content class="text-center">
        <v-list-item-title> {{ $t("sidebar.welcome") }} </v-list-item-title>
        <v-list-item-subtitle>
          {{ firstName }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="become-member" v-if="askMembership">
          <v-chip
            x-small
            rounded
            href="https://billfish.org/become-a-member/"
            target="_blank"
            color="indigo"
            @click="askMembership = false"
          >
            {{ $t("profileForm.becomeMember") }}
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list expand nav class="mt-1">
      <template v-for="(item, i) in items">
        <!---If Sidebar Caption -->
        <v-row v-if="item.header" :key="item.header" align="center">
          <v-col cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">
              {{ item.header }}
            </v-subheader>
          </v-col>
        </v-row>
        <!---If Sidebar Caption -->
        <!-- <base-item-group v-else-if="item.children" :key="`group-${i}`" :item="item" /> -->

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
          @emit="(v) => $emit(v)"
        />
      </template>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import BaseItem from "../../../components/commonComponents/BaseItem.vue";
// import BaseItemGroup from '../../../components/commonComponents/BaseItemGroup.vue';
import { defaultAvatar, getUserInfo } from "../../../handlers";
import SidebarItems from "./SidebarItems";
export default {
  components: { BaseItem },
  name: "SideBar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: SidebarItems,
    image: null,
    askMembership: false,
  }),
  computed: {
    ...mapState("sidebar", ["SidebarColor", "SidebarBg", "Sidebar_drawer"]),
    drawer: {
      get() {
        return this.Sidebar_drawer;
      },
      set(val) {
        this.SET_SIDEBAR_DRAWER(val);
      },
    },
    firstName() {
      const user = getUserInfo();
      return user?.firstName || "";
    },
    srcImg() {
      if (typeof this.image === "string") {
        return this.image || defaultAvatar;
      } else {
        const { mime, data } = this.image || {};
        const avatar = mime && data && `data:${mime};base64,${data}`;
        return avatar || defaultAvatar;
      }
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },
  methods: {
    ...mapMutations("sidebar", ["SET_SIDEBAR_DRAWER"]),
    ...mapActions("users", ["getUser"]),
    updateAvatar(img) {
      this.image = img;
    },
    async checkMembership() {
      const user = getUserInfo();
      if (user?.id) {
        try {
          const response = await this.getUser({ id: user.id });
          const { memberId } = response?.data || {};
          if (!memberId) {
            this.askMembership = true;
          }
        } catch (error) {}
      }
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  async mounted() {
    await this.checkMembership();
  },
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .profile-bg {
    background: url("../../../assets/user-info.jpg") no-repeat;
    background-size: auto 100%;
    .v-avatar {
      padding: 45px 0;
    }
  }

  .become-member {
    transition: transform 0.3s ease-in-out;
  }

  .become-member:hover {
    transform: translateY(5px) scale(1.2);
  }
}
</style>
