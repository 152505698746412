<template>
  <v-card flat title>
    <record-fish ref="recordFish" @done="load()" />
    <juvenile-billfish-form
      ref="juvenileBillfishForm"
      :open="openJuvenile"
      @update:open="(value) => (openJuvenile = value)"
      set-user
    />
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
      striped
      height="5"
    ></v-progress-linear>
    <v-card-title>
      <v-row>
        <v-col cols="12" v-if="mobileView">
          <h3>
            <v-icon class="mr-2">mdi-tag-multiple</v-icon>
            {{ $t("dashboard.fishingHistory.title") }}
          </h3>
        </v-col>
        <v-col>
          <v-btn
            text
            @click="forOthers = false"
            :class="!forOthers ? 'primary' : null"
          >
            {{
              mobileView
                ? $t("dashboard.fishingHistory.own")
                : $t("dashboard.fishingHistory.title")
            }}
          </v-btn>
          <v-btn
            text
            @click="forOthers = true"
            :class="forOthers ? 'primary ml-5' : 'ml-5'"
            :disabled="tab === 'Recaptures'"
          >
            {{
              mobileView
                ? $t("dashboard.fishingHistory.others")
                : $t("dashboard.fishingHistory.forOthers")
            }}
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer />
      <v-btn icon @click="$emit('up')">
        <v-icon>mdi-chevron-up-circle</v-icon>
      </v-btn>
      <v-btn icon @click="$emit('down')">
        <v-icon>mdi-chevron-down-circle</v-icon>
      </v-btn>
    </v-card-title>
    <search-panel @search="searchByFilter" :loading="loading" />
    <tab-menu
      :tabs="tabs"
      :hideDrafts="!forOthers"
      @type="(value) => (tab = value)"
      @draft="(value) => (filter.draft = value)"
    >
      <template v-slot:tab-items>
        <v-tab-item v-for="tab in tabs" v-bind:key="tab.key">
          <v-data-table
            dense
            :headers="headers"
            :items="processedItems || data"
            :options.sync="options"
            item-key="id"
            :footer-props="footerOpts"
            :server-items-length="totalDocs"
            :hide-default-header="!mobileView"
            class="elevation-1 row-pointer"
          >
            <template v-slot:[`item.enteredDate`]="{ item }">
              <span>{{ formatDate(item.enteredDate) }}</span>
            </template>
            <template v-slot:[`item.createdDate`]="{ item }">
              <span>{{ formatDate(item.createdDate) }}</span>
            </template>
            <template v-slot:[`item.locality`]="{ item }">
              <span>{{ item.locality || item.coordinates?.description }}</span>
            </template>
            <template v-slot:[`item.capture.fish`]="{ item }">
              <span v-if="Array.isArray(item.capture.fish)">
                <v-list-item v-for="(fish, i) in item.capture.fish" :key="i">
                  <v-list-item-content>
                    {{ fish.name || $t("notSpecified") }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="fish.qty">
                    <template> {{ fish.qty }} </template>
                  </v-list-item-icon>
                </v-list-item>
              </span>
              <span v-else>{{ getFishName(item) }}</span>
            </template>
            <template
              v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
            >
              <div v-show="!!totalDocs">
                {{ pageStart }} - {{ pageStop }} of {{ totalDocs }} ({{
                  footerOpts.pageCount
                }}
                {{ itemsLength === 1 ? "page" : "pages" }})
              </div>
              <div v-show="!totalDocs">{{ $t("noRecords") }}</div>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn
                fab
                depressed
                x-small
                color="blue-grey"
                dark
                @click="editInfo(item)"
              >
                <v-icon> mdi-circle-edit-outline </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.social`]="{ item }">
              <v-btn
                fab
                depressed
                x-small
                :class="`primary lighten-3 mr-2 mt-1 mb-1`"
                dark
                :href="`https://twitter.com/intent/tweet/?text=${msg(
                  item
                )}&url=https://www.tagbillfish.org/`"
                target="_blank"
              >
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.picture`]="{ item }">
              <v-avatar size="64" rounded>
                <v-img
                  v-if="item.picture"
                  :lazySrc="billfishDefaultImg"
                  :src="item.picture"
                />
                <span v-else> - </span>
              </v-avatar>
            </template>
            <template v-slot:header="{ props }" v-if="!mobileView">
              <th
                v-for="(head, i) in props.headers"
                :key="i"
                class="grey--text darken-1 pointer pt-5"
              >
                {{ headText($t(`dashboard.fishingHistory.${head.key}`)) }}
                <v-btn
                  x-small
                  fab
                  icon
                  v-if="head.sortable"
                  @click="sorting(head.key)"
                >
                  <v-icon>mdi-swap-vertical</v-icon>
                </v-btn>
              </th>
            </template>
          </v-data-table>
        </v-tab-item>
      </template>
    </tab-menu>
  </v-card>
</template>

<script>
import moment from "moment";
import TabMenu from "../../components/appComponents/TabMenu.vue";
import RecordFish from "../Records/RecordFish.vue";
import SearchPanel from "../../components/appComponents/SearchPanel.vue";
import { mapActions } from "vuex";
import { billfishDefaultImg, fixTimezone } from "../../handlers";
import JuvenileBillfishForm from "../Records/JuvenileBillfishForm.vue";
export default {
  components: { TabMenu, RecordFish, JuvenileBillfishForm, SearchPanel },
  name: "fishing-history",
  props: {
    items: Array,
    totalDocs: Number,
    pageCount: Number,
    loading: Boolean,
  },
  data: () => ({
    headers: headersOwn,
    options: {},
    tab: "Tags",
    sort: null,
    forOthers: false,
    processedItems: [],
    order: false,
    tempOptions: {},
    filter: { draft: false },
    openJuvenile: false,
  }),
  computed: {
    footerOpts() {
      return {
        "items-per-page-options": [10, 20, 50],
        pageCount: this.pageCount,
      };
    },
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tabs() {
      if (this.forOthers) {
        return [
          { name: this.$t("tags"), key: "Tags" },
          { name: this.$t("releases"), key: "Releases" },
        ];
      }

      return [
        { name: this.$t("tags"), key: "Tags" },
        { name: this.$t("releases"), key: "Releases" },
        { name: this.$t("recaptures"), key: "Recaptures" },
        { name: this.$t("juveniles"), key: "Juveniles" },
      ];
    },
    data() {
      return items;
    },
    billfishDefaultImg() {
      return billfishDefaultImg;
    },
  },
  watch: {
    options: {
      handler: "load",
    },
    tab: {
      handler: "resetOpts",
    },
    forOthers: {
      handler: "changeMode",
    },
    items: {
      handler: "getPictures",
    },
    "filter.draft": {
      handler: "changeMode",
    },
  },
  methods: {
    ...mapActions("entry", ["getFishPicture"]),
    headText(str) {
      if (this.$i18n.locale === "en") {
        return str === this.$t("dashboard.fishingHistory.created")
          ? `${String(this.tab).slice(0, this.tab.length - 1)} Date`
          : str;
      }

      return str;
    },
    sortBySpecies() {
      this.data.sort((a, b) => {
        if (a.capture.fish.specie.name < b.capture.fish.specie.name) {
          return -1;
        }
        if (a.capture.fish.specie.name > b.capture.fish.specie.name) {
          return 1;
        }
        return 0;
      });
    },
    sortByTime() {
      this.data.sort((a, b) => {
        if (a.enteredDate < b.enteredDate) {
          return -1;
        }
        if (a.enteredDate > b.enteredDate) {
          return 1;
        }
        return 0;
      });
    },
    sorting(sort) {
      this.sort = sort;
      this.order = !this.order;
      this.load();
    },
    async editInfo(record) {
      if (record.isTagOrRelease === "J") {
        if (this.$refs.juvenileBillfishForm) {
          await this.$refs.juvenileBillfishForm.initRecord(record);
        }
        return;
      }

      this.$refs.recordFish && this.$refs.recordFish.init(record, this.tab);
    },
    msg(item) {
      const { angler: user, isTagOrRelease } = item || {};
      const { firstName: aFN, lastName: aLN } = user || {};
      const angler = aFN && aLN ? `${aFN} ${aLN}` : "Angler";
      const action =
        isTagOrRelease === "T"
          ? "tagged"
          : isTagOrRelease === "R"
          ? "released"
          : isTagOrRelease === "J"
          ? "reported"
          : "recaptured";
      let fish = item?.capture?.fish?.specie?.name || "fish";
      if (isTagOrRelease === "J") {
        fish = "juvenile ".concat(fish);
      }
      const boat = item?.boat?.name || "a boat";
      const { firstName: cFN, lastName: cLN } = item?.captain || {};
      const captain = cFN && cLN ? `${cFN} ${cLN}` : "";
      const date = moment(item?.createdDate || "").format("MM/DD/YYYY");
      const msg = `${angler} ${action} a ${fish} aboard ${boat} with Captain ${captain} on ${date}`;
      return encodeURIComponent(msg);
    },
    load() {
      this.$emit("load", {
        tab: this.tab,
        options: this.options,
        sort: this.sort,
        forOthers: this.forOthers,
        order: this.order,
        filter: this.filter,
      });
    },
    resetOpts() {
      const sameOptions =
        JSON.stringify(this.options) === JSON.stringify(this.tempOptions);
      this.order = false;
      if (sameOptions) {
        this.load();
        return;
      }
      this.tempOptions = this.options;
    },
    changeMode() {
      this.headers = this.draft
        ? headerDraft
        : this.forOthers
        ? headers
        : headersOwn;
      this.load();
    },
    async getPictures(items = []) {
      if (!items?.length) {
        this.processedItems = [];
        return;
      }
      this.processedItems = await Promise.all(
        items.map(async (item) => {
          const { data: response } =
            (await this.getFishPicture({ id: item.id })) || {};
          const image = response?.docs && JSON.parse(response.docs)?.image;
          const { mime, data } = image || {};
          mime && data && (item.picture = `data:${mime};base64,${data}`);
          return item;
        })
      );
    },
    formatDate(value) {
      const fixedDate = fixTimezone(value);
      const date = moment(fixedDate || null);
      return date.isValid() ? date.format("MM/DD/YYYY") : "";
    },
    getFishName(item) {
      return item?.capture?.fish?.specie?.name || "";
    },
    searchByFilter(filter) {
      this.filter = { ...this.filter, ...filter };
      this.load();
    },
  },
};

const headers = [
  { text: "", value: "edit", sortable: false, key: "edit" },
  {
    text: "Tag Number",
    align: "center",
    sortable: true,
    value: "number",
    key: "number",
  },
  {
    text: "Angler",
    align: "center",
    sortable: true,
    value: "angler.fullName",
    key: "angler",
  },
  {
    text: "Captain",
    align: "center",
    sortable: true,
    value: "captain.fullName",
    key: "captain",
  },
  {
    text: "Ocean",
    align: "center",
    sortable: true,
    value: "capture.ocean",
    key: "ocean",
  },
  {
    text: "Species",
    align: "center",
    sortable: true,
    value: "capture.fish",
    key: "species",
  },
  {
    text: "Location Description",
    align: "center",
    sortable: true,
    value: "locality",
    key: "location",
  },
  {
    text: "Date Entered",
    align: "center",
    sortable: true,
    value: "enteredDate",
    key: "date",
  },
  {
    text: "Captured Date",
    align: "center",
    sortable: true,
    value: "createdDate",
    key: "created",
  },
  { text: "Picture", value: "picture", sortable: false, key: "picture" },
  { text: "Share on", value: "social", sortable: false, key: "share" },
];
const headersOwn = headers.filter((head) => head.key !== "angler");
const headerDraft = headers.filter((head) => head.key !== "share");
</script>

<style scoped>
.title {
  color: white !important;
}
/* https://www.facebook.com/sharer.php?u=YOUR_URL_HERE
https://twitter.com/intent/tweet?url=YOUR_URL_HERE
https://plus.google.com/share?url=YOUR_URL_HERE
https://www.linkedin.com/shareArticle?mini=true&url=YOUR_URL_HERE */
</style>
