<template>
  <v-card>
    <v-card-title class="mb-4">
      {{ title }}
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            label="Items"
            :items="editableItems"
            item-text="name"
            item-value="value"
            v-model="editableItem"
            clearable
            v-if="editMode"
            @change="editableItemChanged"
            @click:clear="clearRecords"
          />
        </v-col>
      </v-row>
      <v-spacer v-if="editMode" />
      <v-btn
        color="error"
        dark
        text
        v-if="selectedItem"
        :disabled="disableRemove"
        @click="remove"
      >
        Delete
        <v-icon right dark>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        dark
        text
        v-if="selectedItem"
        @click="openEditItemDialog"
      >
        Add new
        <v-icon right dark>mdi-plus</v-icon>
      </v-btn>
      <v-spacer v-if="searchable" />
      <v-text-field
        v-model="search"
        label="Search by name"
        v-if="searchable"
        append-icon="mdi-magnify"
        @click:append="runSearch"
        v-on:keyup.enter="runSearch"
        clearable
      ></v-text-field>
      <v-spacer v-if="exportable" />
      <v-menu offset-y v-if="exportable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark small v-bind="attrs" v-on="on" :loading="loadingExport" :disabled="asyncLoading">
            Export
            <v-icon right dark>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list class="export-list">
          <v-list-item
            :key="'csv'"
            class="dropdown-item"
            @click="() => $emit('export', 'csv')"
            dense
          >
            <v-list-item-title>
              <v-icon left>mdi-file-delimited-outline</v-icon>
              CSV
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            :key="'xls'"
            class="dropdown-item"
            @click="() => $emit('export', 'excel')"
            dense
          >
            <v-list-item-title>
              <v-icon left>mdi-file-excel-outline</v-icon>
              Excel
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-data-table
      :ripple="{ center: true }"
      v-model="selectedRows"
      :headers="headers"
      :items="records"
      :single-select="false"
      :item-key="itemKey"
      :search="search"
      :loading="asyncLoading"
      :show-select="showSelect"
      :options.sync="options"
      :pageOptions.sync="trackPagination"
      :footer-props="footerOpts"
      :server-items-length="footerOptions.pageCount"
      :hide-default-header="hideHeader"
      class="elevation-1 row-pointer"
      @click:row="conditionalClick"
    >
      <template v-slot:header="{ props }">
        <slot name="header" :row="props"></slot>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <slot name="list-item" :row="item"></slot>
      </template>
      <template v-slot:[`item.captures`]="{ item }">
        <slot name="captures" :row="item"></slot>
      </template>
      <template v-slot:item[`dateCreated`]="{ item }">
        <span>{{ formatDate(item.dateCreated) }}</span>
      </template>
      <template v-slot:item[`dateSubmitted`]="{ item }">
        <span>{{ formatDate(item.dateSubmitted) }}</span>
      </template>
      <template v-slot:[`item.taggedDate`]="{ item }">
        <span>{{ formatDate(item.taggedDate) }}</span>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ formatDate(item.createdDate) }}</span>
      </template>
      <template v-slot:[`item.enteredDate`]="{ item }">
        <span>{{ formatDate(item.enteredDate) }}</span>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div v-show="!!totalDocs">
          {{ pageStart }} - {{ pageStop }} of {{ itemsLength }} ({{
            totalDocs
          }}
          {{ totalDocs === 1 ? "record" : "records" }})
        </div>
        <div v-show="!totalDocs">No records found</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import * as XLSX from "xlsx";
import moment from "moment";
import { fixTimezone } from "../../handlers";
export default {
  name: "user-list-view",
  props: {
    title: String,
    headers: Array,
    records: Array,
    asyncLoading: Boolean,
    showSelect: Boolean,
    query: Object,
    footerOptions: Object,
    totalDocs: Number,
    serverItemsLength: Number,
    itemKey: {
      type: [String, Number],
    },
    exportable: Boolean,
    searchable: Boolean,
    editableItems: Array,
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: "",
    options: {},
    model: null,
    file: null,
    editableItem: null,
    selectedRows: [],
    loadingExport: false,
  }),
  computed: {
    footerOpts() {
      return {
        "items-per-page-options": [10, 20, 50],
        ...this.footerOptions,
      };
    },
    editMode() {
      return !!this.editableItems?.length;
    },
    selectedItem() {
      return !!this.editableItem?.length && this.editableItem === "tournaments";
    },
    disableRemove() {
      return !this.selectedRows?.length;
    },
    trackPagination() {
      this.$emit("onPaginate", {
        ...this.options,
        search: this.search,
        item: this.editableItem,
      });
      return this.options;
    },
    records_json() {
      return this.records.map((record) => {
        return {
          "Legacy Id": record?.legacyId,
          Name: this.formatStr(record?.fullName),
          Gender: record?.gender,
          Email: record?.email,
          Address: this.formatStr(record?.address),
          Birthday: record?.birthDay,
          "Is Member": record?.isMember,
          "Member Id": record?.memberId,
          "Membership Term": record?.membershipTerm,
          Active: record?.active,
          "Date Created": record?.dateCreated,
        };
      });
    },
    tags_json() {
      return this.records.map((record) => {
        return {
          "Tag number": record?.number,
          Angler: this.formatStr(record?.angler?.fullName),
          Captain: this.formatStr(record?.captain?.fullName),
          Ocean: record?.capture?.ocean,
          Species: this.formatStr(record?.capture?.fish?.species),
          Bait: this.formatStr(record?.capture?.fish?.bait),
          Gear: this.formatStr(record?.capture?.fish?.gear),
          Event: this.formatStr(record?.capture?.fish?.tournament),
          Location: this.formatStr(record?.location),
          "Location Description": this.formatStr(
            record?.coordinates?.description
          ),
          "Date Created": record?.dateCreated,
          "Date Submitted": record?.dateSubmitted,
        };
      });
    },
  },
  methods: {
    conditionalClick(item) {
      this.$emit("onClick", item);
    },
    exportFile(type) {
      this.file = type;
      const filename = `${
        this.title
      } ${new Date().getFullYear()}.${new Date().getMonth()}.${new Date().getDate()}`;
      const tags = String(this.title || "").includes("Search");
      const items = tags ? this.tags_json : this.records_json;
      if (type === "csv") {
        const fileType = "data:attachment/csv;charset=utf-8,";
        const array = [Object.keys(items[0])].concat(items);

        const csvString = array
          .map((item) => Object.values(item).toString())
          .join("\r\n");

        const blob = new Blob([csvString], {
          type: fileType,
        });

        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.target = "_blank";
        a.download = `${filename}.csv`;
        document.body.appendChild(a);
        a.click();
      } else if (type === "excel") {
        const workSheet = XLSX.utils.json_to_sheet(items);
        var workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, filename);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      }
    },
    formatStr(str) {
      return this.file === "csv" ? `"${str}"` : str;
    },
    formatDate(value) {
      const fixedDate = fixTimezone(value);
      const date = moment(fixedDate || null);
      return date.isValid() ? date.format("MM/DD/YYYY") : "";
    },
    async runSearch() {
      this.$emit("search", { ...this.options, value: this.search });
    },
    editableItemChanged() {
      this.$emit("change", { ...this.options, item: this.editableItem });
    },
    clearRecords() {
      this.$emit("clear");
    },
    openEditItemDialog() {
      this.$emit("editItem");
    },
    remove() {
      this.$emit("remove", this.selectedRows);
    },
    ...mapActions("entry", ["getAllEntries"]),
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-text-field__details {
  min-height: 0;
}
.v-card__title {
  padding: 16px 16px 0 16px;
}
.dropdown-item {
  cursor: pointer;
  min-height: 30px;
}
.dropdown-item:hover {
  background-color: aliceblue;
}
.export-list {
  padding: 4px 0;
}
</style>
