<template>
  <v-container fluid>
    <v-dialog v-model="onCreateBoat" max-width="400px" :fullscreen="!full">
      <new-boat ref="newBoat" @close="onCreateBoat = false" />
    </v-dialog>
    <v-form ref="tagForm" @submit="submit" :disabled="loading">
      <v-banner
        class="text-caption banner-note"
        two-line
        color="blue lighten-5"
        v-if="isRecapture && bannerNote"
      >
        <span>{{ $t("form.warningNote") }}</span>
        <template v-slot:icon>
          <v-btn icon small @click="bannerNote = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
      <v-row>
        <v-col cols="12" sm="6" md="4" v-if="isRecapture">
          <v-text-field
            id="input-tagnumber"
            :label="$t('form.prior')"
            :rules="rulePriorTag.concat(tagRule)"
            v-mask="editMode ? null : 'BF#######'"
            v-model="priorNumber"
            outlined
            dense
            clearable
            :hint="$t('form.recaptureHint')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 4 : 3">
          <v-text-field
            :label="tagNumberLabel"
            :rules="
              isRecapture
                ? recaptureRule.concat(noRepeatNumberRule)
                : rule.concat(tagRule)
            "
            v-model="number"
            v-mask="editMode ? null : 'BF#######'"
            outlined
            :dense="full"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 4 : 3">
          <auto-complete
            :label="$t('form.ocean')"
            :rules="rule"
            itemType="oceans"
            v-model="input.capture.ocean"
            details
          />
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 6 : 3">
          <auto-complete
            ref="specieCombo"
            :label="$t('form.species')"
            :rules="rule"
            itemType="species"
            v-model="input.capture.fish.specie"
            :ocean="input.capture.ocean"
            details
          />
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 6 : 3">
          <date-picker
            v-model="input.createdDate"
            :label="isRecapture ? $t('form.recaptureDate') : $t('form.tagDate')"
            @clear="input.createdDate = null"
            :rules="rule"
          />
        </v-col>
      </v-row>

      <coordinates-form
        v-model="input.coordinates"
        ref="coordsForm"
        @change="setMarker"
      />

      <v-row>
        <v-col cols="12">
          <fishing-locations
            ref="fishingLocations"
            @coords="setCoords"
            @validate="notifyInvalidCoords"
            draggable
            :input="input"
          />
        </v-col>
        <v-col cols="12">
          <auto-complete-location
            :label="$t('form.location')"
            v-model="input.coordinates"
            @ocean="(value) => (input.capture.ocean = value)"
            addNew
            :rules="rule"
            :hint="false"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h3 class="font-weight-medium">{{ $t("form.fishLength") }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fishlength"
            v-model="input.capture.fish.length.value"
            :label="$t('form.value')"
            v-mask="'#######'"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            :rules="lengthRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.unit"
            :label="$t('form.unit')"
            :items="length.units"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.type"
            :label="$t('form.type')"
            :items="length.types"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.determination"
            :label="$t('form.determination')"
            :items="determinations"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <h3 class="font-weight-medium">{{ $t("form.fishWeight") }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fishweight"
            v-model="input.capture.fish.weight.value"
            :label="$t('form.value')"
            v-mask="'#######'"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            :rules="weightRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.unit"
            :label="$t('form.unit')"
            :items="weight.units"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.type"
            :label="$t('form.type')"
            :items="weight.types"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.determination"
            :label="$t('form.determination')"
            :items="determinations"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <h3 class="font-weight-medium">{{ $t("form.fightTime") }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fighttime-hours"
            v-model="input.capture.fish.fight.hours"
            :label="$t('form.hours')"
            v-mask="'###'"
            min="0"
            max="59"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fighttime-minutes"
            v-model="input.capture.fish.fight.minutes"
            :label="$t('form.minutes')"
            v-mask="'##'"
            min="0"
            max="59"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            type="number"
            :rules="timeRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-switch
            inset
            :label="$t('form.sharkEncounter')"
            v-model="input.sharkEncounter"
            hide-details
            v-if="input.sharkEncounter !== 'N/A'"
          ></v-switch>
          <h4 class="mt-2" v-else>{{ $t("form.sharkEncounter") }}: N/A</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <auto-complete
            :label="$t('form.condition')"
            itemType="conditions"
            v-model="input.capture.fish.condition"
            :isRecapture="isRecapture"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <auto-complete
            :label="$t('form.bait')"
            itemType="baits"
            v-model="input.capture.bait"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <auto-complete
            :label="$t('form.hook')"
            itemType="hooks"
            v-model="input.capture.hook"
          />
          <v-checkbox
            v-model="input.capture.hook.isRemoved"
            hide-details
            :label="$t('form.hookRemoved')"
            class="shrink mr-2 mt-0"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            auto-grow
            rows="1"
            :label="$t('form.notes')"
            v-model="input.capture.notes"
            outlined
            clearable
            dense
            counter
            :rules="notesRule"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete
            :label="$t('form.gear')"
            itemType="gears"
            v-model="input.capture.gear"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete
            :label="$t('form.tournament')"
            itemType="tournaments"
            v-model="input.capture.tournament"
            :rules="tournamentRule"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end">
          <v-bottom-sheet
            v-model="sheet"
            persistent
            :fullscreen="!full"
            :scrollable="!full"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn small color="primary" v-bind="attrs" v-on="on">
                {{ $t("form.addUser") }}
              </v-btn>
            </template>
            <new-user
              ref="newUser"
              @close="closeEditMode"
              @added="newUserAdded"
            />
          </v-bottom-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.angler')"
            v-model="input.angler"
            addNew
            @add="editUser(input.angler)"
            @edit="editUser(input.angler)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.captain')"
            v-model="input.captain"
            addNew
            @add="editUser(input.captain)"
            @edit="editUser(input.captain)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="0" sm="3" />
        <v-col cols="12" sm="6">
          <auto-complete
            :label="$t('form.boat')"
            itemType="boats"
            v-model="input.boat"
            @new="createBoat"
            add
            :rules="boatRule"
          />
        </v-col>
        <v-col cols="0" sm="3" />
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.firstMate')"
            v-model="input.firstMate"
            addNew
            @add="editUser(input.firstMate)"
            @edit="editUser(input.firstMate)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.secondMate')"
            v-model="input.secondMate"
            addNew
            @add="editUser(input.secondMate)"
            @edit="editUser(input.secondMate)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AutoComplete from "../../components/appComponents/AutoComplete.vue";
import AutoCompleteUsers from "../../components/appComponents/AutoCompleteUsers.vue";
import {
  DETERMINATIONS,
  getUserInfo,
  LENGTH_TYPES,
  LENGTH_UNITS,
  setRequestedUserData,
  WEIGHT_TYPES,
  WEIGHT_UNITS,
  getLengthLimitRules,
  getWeightLimitRules,
} from "../../handlers";
import { notifyMessage } from "../../handlers/notifications";
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel,
} from "../models";
import NewBoat from "../Records/NewBoat.vue";
import NewUser from "../Records/NewUser.vue";
import FishingLocations from "../Dashboard/FishingLocations.vue";
import DatePicker from "../../components/appComponents/DatePicker.vue";
import AutoCompleteLocation from "../../components/appComponents/AutoCompleteLocation.vue";
import CoordinatesForm from "../Records/CoordinatesForm.vue";
import moment from "moment";
export default {
  name: "tag-form",
  components: {
    AutoCompleteUsers,
    AutoComplete,
    NewUser,
    NewBoat,
    FishingLocations,
    DatePicker,
    AutoCompleteLocation,
    CoordinatesForm,
  },
  props: {
    fillAngler: {
      type: Boolean,
      default: false,
    },
    isRecapture: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    number: {
      get: function () {
        return this.input.number;
      },
      set: function (v) {
        this.input.number =
          !v || v === "BF" ? null : String(v).includes("BF") ? v : `BF${v}`;
      },
    },
    rule() {
      return [(v) => !!v || this.$t("form.required")];
    },
    ruleCoords() {
      return [
        (v) => !(v === null || v === undefined) || this.$t("form.required"),
      ];
    },
    timeRule() {
      return [(v) => (v <= 59 && v >= 0) || this.$t("form.timeRule")];
    },
    tagRule() {
      return [(v) => v?.length >= 8 || this.$t("form.tagRule")];
    },
    recaptureRule() {
      return [(v) => !v || v?.length >= 8 || this.$t("form.tagRule")];
    },
    notesRule() {
      return [
        (v) => !v || v?.length <= 300 || this.$t("form.maxLimit_300Rule"),
      ];
    },
    priorNumber: {
      get: function () {
        return this.input.priorTag.priorTagNumber;
      },
      set: function (v) {
        this.input.priorTag.priorTagNumber =
          !v || v === "BF" ? null : String(v).includes("BF") ? v : `BF${v}`;
        this.input.priorTag.havePriorTag =
          !!this.input.priorTag.priorTagNumber?.length;
      },
    },
    rulePriorTag() {
      return [
        () => this.input?.priorTag?.havePriorTag || this.$t("form.required"),
      ];
    },
    lengthRule() {
      const { specie, length } = this.input?.capture?.fish || {};
      const { value, unit } = length || {};
      const { acronym } = specie || {};
      const rule = getLengthLimitRules(acronym, value, unit);
      return rule;
    },
    weightRule() {
      const { specie, weight } = this.input?.capture?.fish || {};
      const { value, unit } = weight || {};
      const { acronym } = specie || {};
      const rule = getWeightLimitRules(acronym, value, unit);
      return rule;
    },
    validCoordinates() {
      return this.$refs.coordsForm && this.$refs.coordsForm.validate();
    },
    tagNumberLabel() {
      return this.isRecapture
        ? this.$t("form.newNumber")
        : this.$t("form.number");
    },
    noRepeatNumberRule() {
      return [
        (v) => !v || v !== this.priorNumber || this.$t("form.noRepeatPriorTag"),
      ];
    },
    boatRule() {
      const { boat } = this.input || {};
      return [
        (value) => {
          if (boat?.name && !value?.id) {
            return this.$t("form.required");
          }

          return true;
        },
      ];
    },
    tournamentRule() {
      const { tournament } = this.input?.capture || {};
      return [
        (value) => {
          if (tournament?.name && !value?.id) {
            return this.$t("form.required");
          }

          return true;
        },
      ];
    },
  },
  data: () => ({
    input: window.structuredClone(initInput),
    onCreateBoat: false,
    sheet: false,
    editUserId: null,
    loading: false,
    saving: false,
    determinations: DETERMINATIONS,
    weight: {
      units: WEIGHT_UNITS,
      types: WEIGHT_TYPES,
    },
    length: {
      units: LENGTH_UNITS,
      types: LENGTH_TYPES,
    },
    showImgUploader: false,
    invalidCoords: false,
    editMode: false,
    prevUserList: [],
    previousLocationsActive: false,
    bannerNote: true,
  }),
  methods: {
    ...mapActions("users", ["getUser"]),
    async initRecord(record) {
      this.$refs.tagForm && (this.$refs.tagForm.scrollTop = 0);
      this.loading = true;
      this.resetAll();
      this.newRecord = !record;
      if (!record) {
        if (this.setUser) {
          const userInfo = getUserInfo();
          const { data } = (await this.getUser({ id: userInfo?.id })) || {};
          if (data && userInfo?.selectedRole) {
            this.input[userInfo.selectedRole] = getRequestedUserData(data);
          }
        }
      } else {
        this.input = { ...this.input, ...record };
        if (
          !("sharkEncounter" in this.input) ||
          this.input["sharkEncounter"] === null
        ) {
          if (moment(this.input.enteredDate).isBefore(moment("10/01/2022"))) {
            this.input["sharkEncounter"] = "N/A";
          }
        }
      }
      this.loading = false;
    },
    async submit() {
      if (!this.$refs.tagForm) {
        notifyMessage(this.$t("form.invalid"));
        return;
      }

      const formValid = await this.$refs.tagForm.validate();
      let valid = formValid && this.validCoordinates;

      if (this.invalidCoords) {
        valid = null;
        this.notifyInvalidCoords(false);
      }
      const data = setRequestedUserData(this.input);
      const { errorBag, inputs } = this.$refs.tagForm;

      const errors = [];
      for (const key in errorBag) {
        if (!errorBag[key]) {
          continue;
        }

        const field = inputs.find(({ _uid }) => String(_uid) === key);

        if (!field) {
          continue;
        }

        errors.push(field.id);
      }

      if (!this.validCoordinates) {
        errors.push("form-coordinates");
      }

      return { valid, data, errors };
    },
    notifyInvalidCoords(isWater) {
      if (isWater) {
        this.invalidCoords = false;
        return;
      }
      this.invalidCoords = true;
      notifyMessage(this.$t("form.noSeawaterSurface"));
    },
    createBoat() {
      this.$refs.newBoat && this.$refs.newBoat.init();
      this.onCreateBoat = true;
    },
    resetAll() {
      this.input = window.structuredClone(initInput);
      this.$refs.tagForm && this.$refs.tagForm.resetValidation();
    },
    resetInfo() {
      this.input.number = null;
      const { capture, sharkEncounter } = window.structuredClone(initInput);
      const { fish: captureFish, notes } = capture;
      this.input.capture.fish.fight = captureFish.fight;
      this.input.capture.fish.length = captureFish.length;
      this.input.capture.fish.weight = captureFish.weight;
      this.input.capture.fish.condition = captureFish.condition;
      this.input.capture.fish.specie = captureFish.specie;
      this.input.capture.notes = notes;
      this.input.sharkEncounter = sharkEncounter;
      this.$refs.tagForm && this.$refs.tagForm.resetValidation();
    },
    setCoords(coordinates) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }

      if (this.$refs.fishingLocations) {
        this.$refs.fishingLocations.getDescription(coordinates);
      }

      this.input.coordinates = { ...coordinates };
    },
    setBoatName(name) {
      if (name) {
        if (this.input?.boatName && !this.input?.boat?.name) {
          this.input.boat.name = this.input.boatName;
        }

        if (this.input?.boat?.name) {
          this.input.boatName = this.input.boat.name;
        }
      }
    },
    setMarker(coordinates = null) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }
      this.input.coordinates = { ...coordinates };
    },
    editUser(user = {}) {
      const { id, _id } = user || {};
      this.editUserId = id || _id;
      this.sheet = true;
    },
    closeEditMode() {
      this.sheet = false;
      this.editUserId = null;
    },
    initEditUser(value) {
      if (value) {
        setTimeout(async () => {
          if (this.$refs.newUser) {
            await this.$refs.newUser.init(this.editUserId);
          }
        }, 100);
      }
    },
    async setPreviousLocations(input, forceRender = false) {
      const { angler, captain, firstMate, secondMate } = input;
      const userList = [
        angler?._id,
        captain?._id,
        firstMate?._id,
        secondMate?._id,
      ].filter(Boolean);

      if (!userList.length) {
        this.$refs.fishingLocations.clearLocationMarkers();
        return;
      }

      if (!forceRender && userList.length === this.prevUserList.length) {
        const newUsers = userList.filter(
          (id) => !this.prevUserList.includes(id)
        );
        if (!newUsers.length) {
          return;
        }
      }

      if (this.$refs.fishingLocations) {
        await this.$refs.fishingLocations.setLocationMarkers(userList);
        this.prevUserList = userList;
      }
    },

    newUserAdded(user) {
      const { email } = user || {};
      for (const crewPosition of [
        "angler",
        "captain",
        "firstMate",
        "secondMate",
      ]) {
        if (this.input[crewPosition].id === email) {
          this.input[crewPosition] = user;
        }
      }
      this.$emit("newUser", user);
    },
  },
  watch: {
    "input.boatName": {
      handler: "setBoatName",
    },
    "input.boat.name": {
      handler: "setBoatName",
    },
    sheet: {
      handler: "initEditUser",
      immediate: true,
    },
    input: {
      handler: async function (input) {
        if (!this.previousLocationsActive) {
          return;
        }

        await this.setPreviousLocations(input);
      },
      deep: true,
    },
    previousLocationsActive: {
      handler: async function (value) {
        if (!value) {
          if (this.$refs.fishingLocations) {
            this.$refs.fishingLocations.clearLocationMarkers();
          }
          return;
        }

        await this.setPreviousLocations(this.input, value);
      },
      immediate: true,
    },
    record: {
      handler: async function (record) {
        if (record) {
          await this.initRecord(window.structuredClone(record));
        }
      },
      immediate: true,
    },
  },
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
  ...{ boatName: null },
};
</script>

<style>
.v-input.v-input--switch--inset {
  margin-top: 4px;
}

.v-input.v-input--switch--inset .v-input--switch__track::after {
  content: "No";
  color: #333333;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 5px;
  margin-top: 2px;
}

.v-input.v-input--switch--inset.v-input--is-label-active.v-input--is-dirty
  .v-input--switch__track::after {
  content: "Yes";
  font-size: 8px;
  /* font-weight: bold; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 6px;
  margin-top: 3px;
  color: white;
}

.banner-note div[class~="v-banner__wrapper"] {
  padding: 0 1em !important;
  border-bottom: 0 !important;
  margin-bottom: 10px;
}

.banner-note div[class~="v-banner__content"] {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 24px;
}

.banner-note div[class~="v-banner__icon"] {
  margin: 0 !important;
}
</style>
