<template>
  <div>
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header color="light-blue lighten-5">
          {{ $t("dashboard.fishingHistory.searchTitle") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form class="pt-5">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :label="$t('search.number')"
                  v-model="tagNumber"
                  :rules="tagRule"
                  v-mask="'BF########'"
                  hide-details
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="4">
                <auto-complete
                  :label="$t('search.species')"
                  itemType="species"
                  v-model="species"
                  :ocean="ocean"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <auto-complete
                  :label="$t('search.ocean')"
                  itemType="oceans"
                  v-model="ocean"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <date-picker
                  v-model="createdDate[0]"
                  :label="$t('search.catchStartDate')"
                  @clear="createdDate = [null, null]"
                />
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <date-picker
                  v-model="createdDate[1]"
                  :label="$t('search.catchEndDate')"
                  @clear="createdDate = [null, null]"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-btn
                  color="primary"
                  block
                  @click="search"
                  :disabled="loading"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import DatePicker from "@/components/appComponents/DatePicker";
import AutoComplete from "@/components/appComponents/AutoComplete";
export default {
  name: "SearchPanel",
  components: { DatePicker, AutoComplete },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tagNumber: "",
    species: "",
    ocean: "",
    createdDate: [null, null],
  }),
  computed: {
    tagRule() {
      return [(v) => !v || v?.length >= 8 || "Minimum 6 digits required"];
    },
  },
  methods: {
    search() {
      const [startDate, endDate] = this.createdDate;
      this.$emit("search", {
        tagNumber: this.tagNumber || null,
        species: this.species?.name || null,
        ocean: this.ocean || null,
        createdDate: startDate && endDate ? this.createdDate : null,
      });
    },
  },
  // Add your component logic here
};
</script>

<style scoped>
/* Add your component styles here */
</style>
