<template>
  <v-card flat>
    <v-tabs center-active v-model="selection">
      <v-tab v-for="tab in tabs" :key="tab.key" @click="$emit('type', tab.key)">
        {{ tab.name }}
      </v-tab>
      <v-spacer />
      <v-btn dark depressed color="primary" @click="addNew" v-if="visible">
        <v-icon left dark>mdi-plus</v-icon>
        {{ $t("new") }} {{ caption }}
      </v-btn>
      <v-checkbox
        v-model="draft"
        :label="$t('draft')"
        class="check-draft"
        @click="$emit('draft', draft)"
        v-if="hideDrafts"
      />
    </v-tabs>
    <v-tabs-items v-model="selection">
      <slot name="tab-items" v-bind:selection="selection"></slot>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  name: "tab-menu",
  props: {
    tabs: Array,
    hideDrafts: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    selection: null,
    draft: false,
  }),
  computed: {
    caption() {
      return String(this.tabs[this.selection]).slice(0, -1);
    },
    visible() {
      const title = this.tabs[this.selection];
      return (
        (String(title).includes(this.$t("tags")) ||
          String(title).includes(this.$t("releases")) ||
          String(title).includes(this.$t("recaptures"))) &&
        false
      );
    },
  },
  methods: {
    addNew() {
      this.$emit("add", this.tabs[this.selection]);
    },
  },
};
</script>

<style>
.check-draft {
  display: inline-block;
  padding-top: 0;
  margin-right: 1em;
}
</style>
