<template>
  <v-card flat title>
    <v-dialog v-model="openConfirm" width="600" :fullscreen="full">
      <v-card>
        <v-card-title>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary" size="36">mdi-seal</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span class="text-h6">
                {{ description.trophy }} won as
                {{ description.role }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="description.haveLastRecord">
          <v-row>
            <v-col cols="12" md="6">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.event || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.eventType") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.number || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.number") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.priorNumber || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.prior") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.taggedDate || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.taggedDate") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.specie || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.species") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.ocean || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.ocean") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.locality || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.locality") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.angler || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.angler") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.captain || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.captain") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ description.boat || $t("notSpecified") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("dashboard.trophyRoom.boat") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-banner single-line v-else>
          <v-icon slot="icon" size="24"> mdi-trophy-broken </v-icon>
          {{ $t("dashboard.trophyRoom.noInfo") }}
        </v-banner>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="openConfirm = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-icon>mdi-trophy</v-icon>
      <h3 class="ml-2">{{ $t("dashboard.trophyRoom.title") }}</h3>
      <v-spacer />
      <v-btn icon @click="$emit('up')">
        <v-icon>mdi-chevron-up-circle</v-icon>
      </v-btn>
      <v-btn icon @click="$emit('down')">
        <v-icon>mdi-chevron-down-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      item-key="number"
      :footer-props="footerOpts"
      :server-items-length="totalDocs"
      class="elevation-1 row-pointer"
      @click:row="more"
    >
      <template v-slot:[`item.taggedDate`]="{ item }">
        <span>{{ formatDate(item.taggedDate) }}</span>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span>{{ String(item.name).toUpperCase() }}</span>
      </template>
      <template
        v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div v-show="!!itemsLength">
          {{ pageStart }} - {{ pageStop }} of {{ itemsLength }} ({{
            footer.pageCount
          }}
          {{ footer.pageCount === 1 ? "page" : "pages" }})
        </div>
        <div v-show="!itemsLength">{{ $t("noRecords") }}</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { getUserInfo, formatDate, fixTimezone } from "../../handlers";
import moment from "moment";
export default {
  name: "trophy-room",
  data: () => ({
    items: [],
    loading: false,
    footer: {
      pageCount: 0,
    },
    footerOpts: {
      "items-per-page-options": [30, 50, 90],
    },
    totalDocs: 0,
    legacyId: null,
    options: {},
    openConfirm: false,
    description: description,
  }),
  mounted() {
    const user = getUserInfo();
    this.legacyId = user?.id;
  },
  watch: {
    options: {
      handler: "loadListView",
    },
  },
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    headers() {
      return [
        {
          text: this.$t("dashboard.trophyRoom.name"),
          align: "start",
          sortable: false,
          value: "name",
          width: "33%",
        },
        {
          text: this.$t("dashboard.trophyRoom.fisherName"),
          align: "start",
          sortable: false,
          value: "fisherName",
          width: "33%",
        },
        {
          text: this.$t("dashboard.trophyRoom.taggedDate"),
          align: "start",
          sortable: false,
          value: "taggedDate",
          width: "33%",
        },
      ];
    },
  },
  methods: {
    ...mapActions("dashboard", ["getTrophies"]),
    async loadListView() {
      this.loading = true;
      this.items = [];
      const items = await this.getTrophies({
        legacyId: this.legacyId,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      });
      if (items?.data && items.data?.docs) {
        this.items = items.data.docs;
        this.footer.pageCount = items.data.totalPages;
        this.totalDocs = items.data.totalDocs;
      }
      this.loading = false;
    },
    more(v) {
      if (v?.lastRecord) {
        const record = v.lastRecord;
        this.description = {
          trophy: v?.name,
          event: String(v?.eventType || "").toUpperCase(),
          number: record?.number,
          priorNumber: record?.priorTag?.priorTagNumber,
          taggedDate: formatDate(v?.taggedDate),
          specie: record?.capture?.fish?.specie?.name,
          ocean: record?.capture?.ocean,
          locality: record?.locality,
          angler: `${record?.angler?.firstName || ""} ${
            record?.angler?.lastName || ""
          }`,
          captain: `${record?.captain?.firstName || ""} ${
            record?.captain?.lastName || ""
          }`,
          boat: record?.boat?.name,
          haveLastRecord: true,
          role: String(v?.role || "").toUpperCase(),
        };
      } else this.description = description;
      this.openConfirm = true;
    },
    formatDate(value) {
      const fixedDate = fixTimezone(value);
      const date = moment(fixedDate || null);
      return date.isValid() ? date.format("MM/DD/YYYY") : "";
    },
  },
};

const description = {
  trophy: null,
  event: null,
  number: null,
  priorNumber: null,
  taggedDate: null,
  specie: null,
  ocean: null,
  locality: null,
  angler: null,
  captain: null,
  boat: null,
  haveLastRecord: false,
  role: null,
};
</script>

<style scoped>
.title {
  color: white !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
