<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="(href && href !== '#') || privacy ? '_blank' : undefined"
    :to="item.to"
    :active-class="``"
    @click="item.emit ? $emit('emit', item.emit) : () => {}"
    :class="item.color"
    :dark="!!item.color"
  >
    <v-list-item-icon v-if="item.icon">
      <v-icon> {{ item.icon }} </v-icon>
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title :class="item.bold ? 'font-weight-bold' : ''">
        {{ $t(`sidebar.${item.title}`) }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  name: "BaseItem",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
        emit: null,
        color: null,
        bold: false,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
    privacy() {
      return this.item.to === "/privacy";
    },
  },
};
</script>
