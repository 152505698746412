<template>
  <v-container class="fill-height justify-center">
    <v-snackbar v-model="notify" :timeout="5000" top>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="notify = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="center">
      <v-col md="8" lg="6">
        <v-card class="elevation-4">
          <div class="pa-7 pa-sm-12 text-center">
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              The Billfish Foundation Tag & Release Application
            </h2>
            <v-avatar class="profile" color="grey" size="164" tile>
              <v-img :src="defaultAvatar" alt="Billfish" />
            </v-avatar>
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              {{ $t("activationEmail.title") }}
            </h2>
            <h6 class="subtitle-1 mb-5">
              {{ $t("activationEmail.subtitle") }}
            </h6>
            <v-form ref="form" @submit="send">
              <v-text-field
                :label="$t('email')"
                v-model="email"
                :rules="rules"
                outlined
                clearable
              />
            </v-form>
            <v-btn color="primary" @click="send">
              {{ $t("activationEmail.okButton") }}
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-5"
              depressed
              @click="$router.push({ path: 'login' })"
            >
              {{ $t("activationEmail.cancelButton") }}
            </v-btn>
            <v-row justify="end" align="center" class="mt-5">
              <v-col cols="8" align-self="center" class="text-start">
                <span>
                  &copy;
                  <span v-text="` - The Billfish Foundation - ${year}`" />
                </span>
              </v-col>
              <v-spacer />
              <v-col>
                <a href="privacy" target="_blank" class="link">
                  {{ $t("privacy") }}
                </a>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { defaultAvatar } from "../../handlers";

export default {
  name: "email-activation",
  data: () => ({
    notify: false,
    message: null,
    overlay: false,
    email: null,
    rules: [
      (v) => !!v || "An email is required",
      (v) => /.+@.+\..+/.test(v) || "The value entered is not a valid email",
    ],
  }),
  computed: {
    year() {
      return new Date().getFullYear();
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  mounted() {
    const { email } = this.$route?.query || {};

    if (email) {
      this.email = email;
    }
  },
  methods: {
    ...mapActions("users", ["activationEmail"]),
    async send() {
      this.overlay = true;
      if (this.$refs.form.validate()) {
        this.message = "Unable to send the activation email at this time";
        try {
          const response = await this.activationEmail({ email: this.email });
          const { success, message } = response?.data || {};

          if (success) {
            this.message =
              "An activation email was sent to this email address, please check it and follow the instructions";
          } else if (message) {
            this.message = message;
          }
        } catch (error) {}

        this.notify = true;
      }
      this.overlay = false;
    },
  },
};
</script>
