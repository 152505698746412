<template>
  <v-container fluid>
    <v-dialog v-model="onCreateBoat" max-width="400px" :fullscreen="full">
      <new-boat ref="newBoat" @close="onCreateBoat = false" />
    </v-dialog>
    <v-snackbar v-model="notify" :timeout="3000" top>
      {{ message }}
    </v-snackbar>
    <form ref="coordsForm" :disabled="loading" v-if="coordsStep">
      <coordinates-form
        v-model="input.coordinates"
        ref="coordsInputs"
        @change="setMarker"
      />
      <fishing-locations
        ref="fishingLocations"
        @coords="setCoords"
        @validate="notifyInvalidCoords"
        draggable
        :input="input"
      />
    </form>
    <v-form
      ref="releaseForm"
      @submit="submit"
      :disabled="loading"
      class="mt-5"
      v-else
    >
      <v-row>
        <v-col cols="12" sm="6" md="9">
          <auto-complete-location
            ref="locations"
            :label="$t('form.location')"
            v-model="input.coordinates"
            @locate="setMarker"
            @ocean="(value) => (input.capture.ocean = value)"
            addNew
            :rules="rule"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <auto-complete
            :label="$t('form.ocean')"
            :rules="rule"
            itemType="oceans"
            v-model="input.capture.ocean"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3">
          <date-picker
            v-model="input.createdDate"
            :label="$t('form.releaseDate')"
            @clear="input.createdDate = null"
            :rules="rule"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col :class="full ? 'text-end' : 'text-center'">
          <v-bottom-sheet
            v-model="sheet"
            persistent
            :fullscreen="!full"
            :scrollable="!full"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="reset"
                :x-large="!full"
              >
                {{ $t("form.addUser") }}
              </v-btn>
            </template>
            <new-user ref="newUser" @close="sheet = false" />
          </v-bottom-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.angler')"
            v-model="input.angler"
            @edit="editUser(input.angler)"
            addNew
            @add="sheet = true"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.captain')"
            v-model="input.captain"
            @edit="editUser(input.captain)"
            addNew
            @add="sheet = true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.firstMate')"
            v-model="input.firstMate"
            @edit="editUser(input.firstMate)"
            addNew
            @add="sheet = true"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.secondMate')"
            v-model="input.secondMate"
            @edit="editUser(input.secondMate)"
            addNew
            @add="sheet = true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="0" sm="3" />
        <v-col cols="12" sm="6">
          <auto-complete
            :label="$t('form.boat')"
            itemType="boats"
            v-model="input.boat"
            @new="createBoat"
            add
          />
        </v-col>
        <v-col cols="0" sm="3" />
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <auto-complete
            ref="specieCombo"
            :label="$t('form.species')"
            :rules="releaseValidation"
            itemType="species"
            v-model="specie"
            :exceptions="
              Array.isArray(input.capture.fish) ? input.capture.fish : []
            "
            :ocean="input.capture.ocean"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="quantity"
            :label="$t('form.quantity')"
            :rules="releaseValidation.concat(maxLimit_100)"
            v-mask="'###'"
            maxLength="3"
            outlined
            :dense="full"
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
            color="primary"
            block
            @click="newReleaseFish"
            class="mb-5"
            :x-large="!full"
          >
            {{ $t("form.addFish") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-scroll-y-transition>
        <v-banner
          v-if="!!hintMsg"
          :color="`${hintMsg.includes('NEW') ? 'light-blue' : 'red'} lighten-5`"
        >
          {{ hintMsg }}
        </v-banner>
      </v-scroll-y-transition>
      <v-chip-group
        v-if="Array.isArray(input.capture.fish)"
        show-arrows
        class="mb-5"
      >
        <v-chip
          v-for="(fish, i) in input.capture.fish"
          :key="i"
          color="primary"
          large
          outlined
        >
          <span class="chip_label">{{ fish.name }}: {{ fish.qty }}</span>
          <v-icon large right class="chip_icon" @click="deleteItem(i)">
            mdi-delete
          </v-icon>
        </v-chip>
      </v-chip-group>

      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="input.relLines"
            :label="$t('form.linesIn')"
            type="time"
            outlined
            :dense="full"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="input.relLines0"
            :label="$t('form.linesOut')"
            type="time"
            outlined
            :dense="full"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="input.relNumLi"
            v-mask="'##'"
            maxLength="2"
            :rules="maxLimit"
            :label="$t('form.totalLines')"
            outlined
            :dense="full"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <auto-complete
            :label="$t('form.bait')"
            itemType="baits"
            v-model="input.capture.bait"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <auto-complete
            :label="$t('form.hook')"
            itemType="hooks"
            v-model="input.capture.hook"
          />
          <v-checkbox
            v-model="input.capture.hook.isRemoved"
            hide-details
            :label="$t('form.hookRemoved')"
            class="shrink mr-2 mt-0"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <!-- <v-checkbox
            v-model="input.sharkEncounter"
            hide-details
            :label="$t('form.sharkEncounter')"
            class="shrink mr-2 mt-0"
            :dense="full"
          /> -->
          <v-switch
            inset
            :label="$t('form.sharkEncounter')"
            v-model="input.sharkEncounter"
            hide-details
            v-if="input.sharkEncounter !== 'N/A'"
          ></v-switch>
          <h4 class="mt-2" v-else>{{ $t("form.sharkEncounter") }}: N/A</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            auto-grow
            rows="1"
            :label="$t('form.notes')"
            v-model="input.capture.notes"
            outlined
            clearable
            :dense="full"
            counter
            :rules="notesRule"
            :hint="$t('form.notesHint')"
            persistent-hint
            class="hint-msg"
            :class="{ focused: focused }"
            ref="notes"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AutoComplete from "../../components/appComponents/AutoComplete.vue";
import AutoCompleteUsers from "../../components/appComponents/AutoCompleteUsers.vue";
import {
  cloneObject,
  getRequestedUserData,
  getUserInfo,
  setRequestedUserData,
  validCoordinates,
} from "../../handlers";
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel,
} from "../models";
import NewBoat from "./NewBoat.vue";
import NewUser from "./NewUser.vue";
import FishingLocations from "../Dashboard/FishingLocations.vue";
import DatePicker from "../../components/appComponents/DatePicker.vue";
import CoordinatesForm from "./CoordinatesForm.vue";
import AutoCompleteLocation from "../../components/appComponents/AutoCompleteLocation.vue";
import moment from "moment";
export default {
  name: "release-form",
  components: {
    AutoCompleteUsers,
    AutoComplete,
    NewUser,
    NewBoat,
    FishingLocations,
    DatePicker,
    CoordinatesForm,
    AutoCompleteLocation,
  },
  props: {
    type: String,
    setUser: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    coordsStep() {
      return this.step;
    },
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    maxLimit_100() {
      return [(v) => !v || v <= 100 || this.$t("form.maxLimit_100Rule")];
    },
    releaseValidation() {
      return [
        () =>
          this.input.capture.fish?.length > 0 ||
          !!this.quantity ||
          !!this.specie?.id ||
          "",
      ];
    },
    maxLimit() {
      return [(v) => !v || v <= 10 || this.$t("form.maxLimit_10Rule")];
    },
    minLimit() {
      return [(v) => !v || v >= 0 || this.$t("form.minLimit")];
    },
    hintMsg() {
      const msg = this.submitted
        ? this.$t("form.addOne")
        : this.quantity && this.specie?.id && !this.input.capture.fish?.length
        ? this.$t("form.clickAddNew")
        : "";
      return msg;
    },
    rule() {
      return [(v) => !!v || this.$t("form.required")];
    },
    ruleCoords() {
      return [
        (v) => !(v === null || v === undefined) || this.$t("form.required"),
      ];
    },
    notesRule() {
      return [
        (v) => !v || v?.length <= 300 || this.$t("form.maxLimit_300Rule"),
      ];
    },
  },
  data: () => ({
    input: cloneObject(initInput),
    menuActive: false,
    onCreateBoat: false,
    sheet: false,
    loading: false,
    specie: null,
    quantity: null,
    submitted: false,
    focused: false,
    invalidCoords: false,
    notify: false,
    message: false,
  }),
  beforeUpdate() {
    if (!Array.isArray(this.input.capture.fish)) {
      this.input.capture.fish = [];
    }
  },
  methods: {
    ...mapActions("users", ["getUser"]),
    async initRecord(record) {
      this.$refs.releaseForm && (this.$refs.releaseForm.scrollTop = 0);
      this.loading = true;
      this.quantity = null;
      this.specie = {};
      this.resetAll();
      if (!record) {
        if (this.setUser) {
          const userInfo = getUserInfo();
          const { data } = (await this.getUser({ id: userInfo?.id })) || {};
          if (data && userInfo?.selectedRole) {
            this.input[userInfo.selectedRole] = getRequestedUserData(data);
          }
        }
      } else {
        this.input = { ...this.input, ...record };
        if (
          !("sharkEncounter" in this.input) ||
          this.input["sharkEncounter"] === null
        ) {
          if (moment(this.input.enteredDate).isBefore(moment("10/01/2022"))) {
            this.input["sharkEncounter"] = "N/A";
          }
        }
      }
      this.loading = false;
    },
    validateCoordinates() {
      return this.$refs.coordsInputs && this.$refs.coordsInputs.validate();
    },
    submit() {
      const validCoords = validCoordinates(this.input.coordinates);
      let valid = this.$refs.releaseForm.validate() && validCoords;
      if (this.input.capture.fish?.length < 1) {
        valid = false;
        this.submitted = true;
        setTimeout(() => {
          this.submitted = false;
        }, 4000);
      }
      if (this.invalidCoords) {
        valid = false;
        this.notifyInvalidCoords(false);
      }
      const data = setRequestedUserData(this.input);
      return { valid, data };
    },
    notifyInvalidCoords(isWater) {
      if (isWater) {
        this.invalidCoords = false;
        return;
      }
      this.invalidCoords = true;
      this.message = "Invalid coords, no seawater surface";
      this.notify = true;
    },
    createBoat() {
      this.$refs.newBoat && this.$refs.newBoat.init();
      this.onCreateBoat = true;
    },
    resetForBatch() {
      const input = cloneObject(initInput);
      input.createdDate = this.input.createdDate;
      input.capture.ocean = this.input.capture.ocean;
      input.capture.tournament = this.input.capture.tournament;
      input.boat = this.input.boat;
      input.captain = this.input.captain;
      input.firstMate = this.input.firstMate;
      input.secondMate = this.input.secondMate;
      this.input = input;
      this.input.capture.fish = [];
      this.$refs.releaseForm && this.$refs.releaseForm.resetValidation();
    },
    resetAll() {
      this.input = cloneObject(initInput);
      this.$refs.releaseForm && this.$refs.releaseForm.resetValidation();
    },
    reset() {
      this.$refs.newUser && this.$refs.newUser.init();
    },
    resetInfo() {
      const { capture, sharkEncounter, relLines, relLines0, relNumLi } =
        cloneObject(initInput);
      this.input.relLines = relLines;
      this.input.relLines0 = relLines0;
      this.input.relNumLi = relNumLi;
      this.input.capture.fish = [];
      this.input.capture.notes = capture.notes;
      this.input.sharkEncounter = sharkEncounter;
      this.$refs.releaseForm && this.$refs.releaseForm.resetValidation();
    },
    editUser(user) {
      if (user?.id) {
        this.sheet = true;
        setTimeout(() => {
          this.$refs.newUser && this.$refs.newUser.initUser(user.id);
        }, 50);
      }
    },
    setCoords(data) {
      this.$refs.fishingLocations &&
        this.$refs.fishingLocations.getDescription(data);
      data && (this.input.coordinates = { ...this.input.coordinates, ...data });
    },
    newReleaseFish() {
      const qty = Number(this.quantity) || 0;
      if (!!this.specie?.id?.length && !!qty && !Number.isNaN(qty)) {
        this.input.capture.fish.push({
          id: this.specie.id,
          name: this.specie.name,
          qty,
          acronym: this.specie.acronym,
        });
        this.$refs.specieCombo && this.$refs.specieCombo.getFilterItems();
        this.quantity = null;
        this.specie = {};
      }
    },
    getSpeciesList() {
      this.$refs.specieCombo && this.$refs.specieCombo.getFilterItems();
    },
    deleteItem(v) {
      this.input.capture.fish.splice(v, 1);
      this.$refs.specieCombo && this.$refs.specieCombo.getFilterItems();
    },
    focusNotes() {
      if (this.$refs.notes) {
        this.$refs.notes.focus();
        this.focused = true;
        setTimeout(() => {
          this.focused = false;
        }, 1500);
      }
    },
    setBoatName(name) {
      if (name) {
        if (this.input?.boatName && !this.input?.boat?.name) {
          this.input.boat.name = this.input.boatName;
        }

        if (this.input?.boat?.name) {
          this.input.boatName = this.input.boat.name;
        }
      }
    },
    setMarker(coordinates = null) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }

      this.input.coordinates = { ...this.input.coordinates, ...coordinates };
    },
    initMap(value) {
      setTimeout(async () => {
        if (value) {
          this.$refs.coordsInputs &&
            this.$refs.coordsInputs.updateForm(this.input.coordinates);
          this.$refs.fishingLocations &&
            this.$refs.fishingLocations.handleData(this.input.coordinates);
        } else {
          this.$refs.locations &&
            (await this.$refs.locations.getLocationByName());
        }
      }, 50);
    },
  },
  watch: {
    "input.boatName": {
      handler: "setBoatName",
    },
    "input.boat.name": {
      handler: "setBoatName",
    },
    "input.capture.fish": {
      handler: "getSpeciesList",
      deep: true,
    },
    step: {
      handler: "initMap",
    },
  },
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
  ...{ boatName: null },
};
</script>

<style>
.hint-msg div[class="v-messages__message"] {
  line-height: 18px !important;
  font-size: 15px !important;
}

.focused fieldset {
  border: 2px solid red;
  color: red !important;
}

.v-input.v-input--switch--inset {
  margin-top: 4px;
}

.v-input.v-input--switch--inset .v-input--switch__track::after {
  content: "No";
  color: #333333;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 5px;
  margin-top: 2px;
}

.v-input.v-input--switch--inset.v-input--is-label-active.v-input--is-dirty
  .v-input--switch__track::after {
  content: "Yes";
  font-size: 8px;
  /* font-weight: bold; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 6px;
  margin-top: 3px;
  color: white;
}
</style>
