<template>
  <v-dialog v-model="open" scrollable fullscreen>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="confirm" width="500">
      <v-card>
        <v-card-title> {{ $t("batchReview.clear") }} </v-card-title>
        <v-card-text> {{ $t("batchReview.askAction") }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirm = false"> {{ $t("cancel") }} </v-btn>
          <v-btn
            text
            @click="
              () => {
                confirm = false;
                batch = [];
              }
            "
          >
            {{ $t("clear") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="pa-0">
      <record-batch
        ref="record"
        :batch="batch"
        @done="getRecords"
        @save="editRecord"
      />
      <v-snackbar v-model="notify" :timeout="3000" top>
        {{ message }}
      </v-snackbar>
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary" max-height="100">
          <v-btn icon dark @click="open = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text dark @click="confirm = true" v-if="batch.length > 0">
            {{ $t("clear") }}
          </v-btn>
          <v-btn text dark @click="openForm">
            {{ $t("batchReview.addRecord") }}
          </v-btn>
          <v-btn text dark @click="saveBatch" :disabled="!batch.length">
            {{ $t("submit") }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-iterator
          :items="batch"
          item-key="number"
          hide-default-footer
          v-if="batch.length > 0"
          class="pa-5"
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="(item, index) in items"
                :key="item.number"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card>
                  <v-card-title>
                    <v-list-item two-line>
                      <v-list-item-action>
                        <v-btn
                          icon
                          x-large
                          color="primary"
                          @click="openRecord(item, index)"
                        >
                          <v-icon>mdi-circle-edit-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ type === "tags" ? item.number : "Release" }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ fullName(item.angler) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          icon
                          x-large
                          color="error"
                          @click="deleteRecord(index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <v-banner v-else color="light-blue lighten-5">
          <v-icon slot="icon" color="primary" size="36">
            mdi-information
          </v-icon>
          {{ $t("batchReview.clickOn") }}
          <strong>{{ $t("batchReview.addRecord") }}</strong>
          {{ $t("batchReview.toAdd") }}
        </v-banner>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import RecordBatch from "./RecordBatch.vue";
export default {
  components: { RecordBatch },
  name: "batch-review",
  data: () => ({
    batch: [],
    open: false,
    notify: false,
    message: "",
    confirm: false,
    loading: false,
    type: "tags",
  }),
  methods: {
    ...mapActions("entry", ["addTag", "addRelease"]),
    init(type) {
      this.open = true;
      this.batch = [];
      type && (this.type = String(type).toLowerCase());
    },
    openForm() {
      this.$refs.record && this.$refs.record.init(null, this.type);
    },
    getRecords(record) {
      const list = this.batch;
      list.push(record);
      this.batch = [...list];
      this.message = this.$t("batchReview.added");
      this.notify = true;
    },
    fullName(angler) {
      const { firstName, lastName } = angler || {};
      return firstName && lastName ? `${firstName} ${lastName}` : "";
    },
    openRecord(record, index) {
      this.$refs.record &&
        this.$refs.record.init({ ...record, index }, this.type);
    },
    editRecord(record) {
      const index = record.index;
      delete record.index;
      const list = this.batch;
      list[index] = record;
      this.batch = [...list];
      this.message = this.$t("updated");
      this.notify = true;
    },
    async saveBatch() {
      const response = await (this.type === "tags"
        ? this.addTag(this.batch)
        : this.addRelease(this.batch));
      this.notify = true;
      if (response?.data?.success) {
        this.$emit("done");
        this.message = this.$t("registered");
        this.open = false;
        this.batch = [];
      } else {
        this.message = this.$t("failed");
      }
    },
    deleteRecord(index) {
      const list = this.batch;
      list.splice(index, 1);
      this.batch = [...list];
    },
  },
};
</script>
