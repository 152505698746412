<template>
  <v-row justify="center">
    <v-snackbar v-model="notify" :timeout="2500" top>
      {{ notification }}
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-col cols="12" sm="8" md="7" lg="5" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12 text-center">
          <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
            The Billfish Foundation Tag & Release Application
          </h2>
          <img :src="defaultAvatar" alt="Billfish" />
          <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
            {{ $t("signUp.title") }}
          </h2>
          <h6 class="subtitle-1 mb-2">
            {{ $t("signUp.haveAccount") }}
            <a
              @click="$router.push({ path: 'login' })"
              class="link font-weight-bold"
            >
              {{ $t("login.signIn") }}
            </a>
          </h6>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            action="boxedlogin"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="firstName"
                  :rules="firstNameRules"
                  :label="$t('signUp.firstName')"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="lastName"
                  :rules="lastNameRules"
                  :label="$t('signUp.lastName')"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$t('email')"
              required
              outlined
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="password"
                  :rules="passwordRules.concat(passwordRequirements)"
                  :label="$t('password')"
                  required
                  outlined
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="confirm"
                  :rules="
                    confirmRules.concat(passwordMatchRule, passwordRequirements)
                  "
                  :label="$t('signUp.confirm')"
                  required
                  outlined
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                />
              </v-col>
            </v-row>

            <v-btn
              :disabled="!valid"
              color="info"
              block
              class="mr-4"
              submit
              @click="submit"
            >
              {{ $t("signUp.title") }}
            </v-btn>
          </v-form>
          <v-row justify="end" align="center" class="mt-5">
            <v-col cols="8" align-self="center" class="text-start">
              <span>
                &copy;
                <span v-text="` - The Billfish Foundation - ${year}`" />
              </span>
            </v-col>
            <v-spacer />
            <v-col>
              <a href="privacy" target="_blank" class="link">
                {{ $t("privacy") }}
              </a>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { defaultAvatar } from "../../handlers";
export default {
  name: "SignUp",
  data: () => ({
    password: "",
    show1: false,
    passwordRules: [(v) => !!v || "Password is required"],
    confirm: "",
    show2: false,
    confirmRules: [(v) => !!v || "Confirm password is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    firstName: "",
    firstNameRules: [(v) => !!v || "First name is required"],
    lastName: "",
    lastNameRules: [(v) => !!v || "Last name is required"],
    valid: false,
    notify: false,
    notification: null,
    overlay: false,
  }),
  computed: {
    passwordMatchRule() {
      return () => this.password === this.confirm || "Passwords must match";
    },
    passwordRequirements() {
      const strongRegex = new RegExp("(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})");
      return (v) => strongRegex.test(v) || "Password is not strong enough";
    },
    year() {
      return new Date().getFullYear();
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  methods: {
    ...mapActions("users", ["signup", "activationEmail"]),
    async submit() {
      const valid = this.$refs.form && this.$refs.form.validate();
      if (valid) {
        this.overlay = true;

        const response = await this.signup({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          roles: ["angler"],
        });

        this.overlay = false;

        const { success, message } = response?.data || {};
        if (success) {
          this.$router.push({
            path: "login",
            query: { firstTime: true, email: this.email },
          });
        } else {
          this.notification = message || "Something went wrong";
          this.notify = true;
        }
      }
    },
  },
};
</script>

<style scoped>
img {
  background-color: dodgerblue;
  border-radius: 10px;
}
</style>
