<template>
  <v-bottom-sheet
    v-model="open"
    persistent
    :fullscreen="full"
    :scrollable="full"
  >
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="open && on"
        @click="$emit('check')"
        color="white"
        class="primary--text"
        style="min-width: 164px"
      >
        {{ $t("submit") }}
      </v-btn>
    </template>
    <v-card height="580px">
      <v-snackbar v-model="notify" :timeout="6000" top multi-line>
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="notify = false">
            {{ $t("close") }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-card-text>
        <v-toolbar flat dark color="primary" max-height="100px">
          <v-toolbar-title> {{ $t("confirmation") }} </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              :text="!full"
              :icon="full"
              v-for="(item, i) in actions"
              :key="i"
              @click="item.action"
            >
              <v-icon left> {{ `mdi-content-save${item.icon}` }} </v-icon>
              {{ full ? "" : item.name }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row>
          <v-col cols="12" md="6">
            <v-row class="text-start mt-3">
              <v-col cols="6" :class="full ? 'pl-8' : 'pl-10'">
                <p>
                  <span>
                    {{
                      event === "releases"
                        ? $t("form.releaseDate")
                        : $t("form.tagDate")
                    }}:
                  </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.createdDate && getFormatted(data.createdDate)
                  }}</span>
                </p>
                <p v-if="!!data.number">
                  <span> {{ $t("form.number") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.number
                  }}</span>
                </p>
                <p v-if="havePriorTag">
                  <span> {{ $t("form.prior") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getPriorTag(data)
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.enteredDate") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.enteredDate && getFormatted(data.enteredDate)
                  }}</span>
                </p>
                <p v-if="!!data.daysAtLarge">
                  <span> {{ $t("form.daysAtLarge") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.daysAtLarge
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.angler") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.angler)
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.boat") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.boat.name || $t("notSpecified")
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.captain") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.captain)
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.firstMate") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.firstMate)
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.secondMate") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.secondMate)
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.location") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.coordinates.description
                  }}</span>
                </p>
                <!-- <p>
                  <span> Notes: </span>
                  <span class="font-weight-black text-uppercase">{{ data.capture.notes }}</span>
                </p> -->
              </v-col>
              <v-col cols="6" :class="!full && 'pl-10'">
                <div v-if="event === 'releases'">
                  <p>
                    <span> {{ $t("releases").toUpperCase() }}: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-for="(fish, i) in data.capture.fish"
                      :key="i"
                    >
                      {{ fishList(fish, i) }}
                    </span>
                  </p>
                  <p>
                    <span> {{ $t("form.linesIn") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      data.relLines || $t("notSpecified")
                    }}</span>
                  </p>
                  <p>
                    <span> {{ $t("form.linesOut") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      data.relLines0 || $t("notSpecified")
                    }}</span>
                  </p>
                  <p>
                    <span> {{ $t("form.totalLines") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      data.relNumLi || $t("notSpecified")
                    }}</span>
                  </p>
                </div>
                <div v-else>
                  <p>
                    <span> {{ $t("form.species") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      specie
                    }}</span>
                  </p>
                  <p v-if="event !== 'juvenile'">
                    <span> {{ $t("form.condition") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      condition
                    }}</span>
                  </p>
                  <p v-if="event !== 'juvenile'">
                    <span> {{ $t("form.fishLength") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      getFishStat(data.capture.fish.length)
                    }}</span>
                  </p>
                  <p v-if="event !== 'juvenile'">
                    <span> {{ $t("form.fishWeight") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      getFishStat(data.capture.fish.weight)
                    }}</span>
                  </p>
                  <p>
                    <span> {{ $t("form.fightTime") }}: </span>
                    <span class="font-weight-black text-uppercase">{{
                      getFightTime()
                    }}</span>
                  </p>
                </div>
                <p>
                  <span> {{ $t("form.gear") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.capture.gear.name
                  }}</span>
                </p>
                <p v-if="event !== 'juvenile'">
                  <span> {{ $t("form.bait") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.capture.bait.name || $t("notSpecified")
                  }}</span>
                </p>
                <p>
                  <span> {{ $t("form.ocean") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.capture.ocean
                  }}</span>
                </p>
                <p v-if="event !== 'juvenile'">
                  <span> {{ $t("form.event") }}: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.capture.tournament.name &&
                    data.capture.tournament.name !== "NULL"
                      ? data.capture.tournament.name
                      : $t("notSpecified")
                  }}</span>
                </p>
                <p v-if="event !== 'juvenile'">
                  <span class="text-uppercase">
                    {{ $t("form.sharkEncounter") }}:
                  </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.sharkEncounter ? "YES" : "NO"
                  }}</span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" :class="full ? 'pr-8' : 'pr-10'">
            <fishing-locations
              ref="location"
              :fabNavigation="false"
              :fabPosition="false"
              :input="input"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { formatDate } from "../../handlers";
import FishingLocations from "../Dashboard/FishingLocations.vue";
import {
  CrewViewModel,
  CaptureViewModel,
  CoordinatesViewModel,
} from "../models";
export default {
  components: { FishingLocations },
  name: "confirmation-view",
  props: {
    data: {
      type: Object,
      default: () => INPUT,
    },
    event: {
      type: String,
      default: () => "",
    },
    isNew: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    notify: false,
    message: null,
    open: false,
    overlay: false,
    createActions: [],
    updateActions: [],
    input: INPUT,
  }),
  mounted() {
    this.createActions = [
      { name: this.$t("save"), action: () => this.save(), icon: "" },
      {
        name: this.$t("saveNew"),
        action: () => this.save("new"),
        icon: "-edit",
      },
      {
        name: this.$t("saveCopy"),
        action: () => this.save("copy"),
        icon: "-all",
      },
      {
        name: this.$t("back"),
        action: () => (this.open = false),
        icon: "-move",
      },
    ];

    this.updateActions = [
      { name: this.$t("update"), action: () => this.save(), icon: "" },
      {
        name: this.$t("back"),
        action: () => (this.open = false),
        icon: "-move",
      },
    ];
  },
  watch: {
    open: {
      handler: "init",
    },
  },
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    specie() {
      return this.data.capture.fish?.specie?.name || this.$t("notSpecified");
    },
    condition() {
      return this.data.capture.fish?.condition?.name || this.$t("notSpecified");
    },
    actions() {
      return this.isNew ? this.createActions : this.updateActions;
    },
    havePriorTag() {
      return !!this.data?.priorTag?.havePriorTag;
    },
  },
  methods: {
    init(opened) {
      if (!opened) {
        this.overlay = false;
      }

      setTimeout(() => {
        this.input = this.data;
      }, 100);
    },
    getFullName(item) {
      return item && item?.firstName && item?.lastName
        ? `${item.firstName} ${item.lastName}`
        : this.$t("notSpecified");
    },
    getFightTime() {
      const hours = this.data?.capture?.fish?.fight?.hours;
      const minutes = this.data?.capture?.fish?.fight?.minutes;
      return hours || minutes
        ? `${hours || "00"}:${minutes || "00"} h`
        : this.$t("notSpecified");
    },
    getFishStat(data) {
      const { value, unit, determination, type } = data || {};
      return `${value || 0} ${unit || ""} ${type || ""} ${determination || ""}`;
    },
    getPriorTag(data) {
      return data?.priorTag?.priorTagNumber || this.$t("notSpecified");
    },
    fishList(fish, i) {
      let text = `${fish.qty} ${fish.name}`;
      const size = this.data.capture.fish?.length || 0;
      !!size && size !== 1 && i < size - 1 && (text = text.concat(" | "));
      return text;
    },
    save(value) {
      this.overlay = true;
      this.$emit("save", value);
    },
    getFormatted(date) {
      return formatDate(date);
    },
  },
};

const INPUT = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
};
</script>

<style scoped>
.v-card .v-card__text {
  padding: 0;
}
</style>
