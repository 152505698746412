<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" text v-bind="attrs" v-on="on">
          {{ $t("batchFeature.apply") }}
        </v-btn>
      </template>

      <v-card style="max-width: 500px">
        <v-toolbar color="primary" dark flat>
          <span class="text-caption text-center py-2 px-2">
            {{ $t("batchFeature.applyHint") }}
          </span>
        </v-toolbar>
        <v-card-text style="max-height: 350px; overflow-y: scroll">
          <v-list-item
            v-for="({ name, label, description }, index) of params"
            :key="name"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="params[index].value"
                  :input-value="active"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title> {{ label }} </v-list-item-title>
                <v-list-item-subtitle>
                  {{ description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" outlined @click="save" block>
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { cloneObject } from "../../handlers";
export default {
  name: "apply-all",
  props: {
    value: {
      type: Array,
      default: () => null,
    },
    isRelease: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    params: {
      get() {
        if (!this.value) {
          return this.defaultParams;
        }

        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    defaultParams() {
      const onlyTagsParams = ["tournament", "gear"];
      const params = Params.map((param) => {
        const label = String(param.label).replace(/\s/g, "");
        param.description = this.$t(`batchFeature.apply${label}`);
        return param;
      });

      if (this.isRelease) {
        return params.filter((param) => !onlyTagsParams.includes(param.name));
      }

      return cloneObject(params);
    },
  },
  methods: {
    save() {
      this.$emit("input", this.params);
      this.menu = false;
    },
  },
};

const Params = [
  {
    name: "location",
    label: "Location",
    value: false,
  },
  {
    name: "boat",
    label: "Boat",
    value: false,
  },
  {
    name: "angler",
    label: "Angler",
    value: false,
  },
  {
    name: "captain",
    label: "Captain",
    value: false,
  },
  {
    name: "firstMate",
    label: "First Mate",
    value: false,
  },
  {
    name: "secondMate",
    label: "Second Mate",
    value: false,
  },
  {
    name: "tournament",
    label: "Tournament",
    value: false,
  },
  {
    name: "hook",
    label: "Hook",
    value: false,
  },
  {
    name: "bait",
    label: "Bait",
    value: false,
  },
  {
    name: "gear",
    label: "Gear",
    value: false,
  },
  {
    name: "species",
    label: "Species",
    value: false,
  },
];
</script>
