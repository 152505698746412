<template>
  <v-card fluid>
    <v-snackbar v-model="notify" :timeout="3000" top>
      {{ message }}
    </v-snackbar>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> {{ $t("newLocation.title") }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="submit"> {{ $t("save") }} </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="locationForm" class="mt-5">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('newLocation.location')"
              :rules="rule"
              v-model="input.name"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6">
            <auto-complete
              :label="$t('form.ocean')"
              :rules="rule"
              itemType="oceans"
              v-model="input.ocean"
            />
          </v-col>
        </v-row>

        <coordinates-form v-model="input.coordinates" ref="coordsForm" />

        <fishing-locations
          ref="fishingLocations"
          @coords="setCoords"
          draggable
          class="mt-5"
          :input="input"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { CoordinatesViewModel } from "../models";
import AutoComplete from "@/components/appComponents/AutoComplete.vue";
import CoordinatesForm from "./CoordinatesForm.vue";
import FishingLocations from "@/views/Dashboard/FishingLocations.vue";
export default {
  components: { AutoComplete, CoordinatesForm, FishingLocations },
  name: "new-location",
  data: () => ({
    input: INPUT,
    notify: false,
    message: null,
  }),
  mounted() {
    if (!this.input["name"]) {
      setTimeout(() => {
        this.input = { ...window.structuredClone(INPUT) };
      }, 100);
    }

    this.$refs.locationForm && this.$refs.locationForm.resetValidation();
  },
  computed: {
    rule() {
      return [(v) => !!v || this.$t("required")];
    },
  },
  methods: {
    ...mapActions("entry", ["saveLocation"]),
    async submit() {
      let validCoords = false;
      if (this.$refs.coordsForm) {
        validCoords = this.$refs.coordsForm.validate();
      }

      let valid = false;
      if (this.$refs.locationForm) {
        valid = this.$refs.locationForm.validate();
      }

      if (valid && validCoords) {
        this.input["approved"] = false;
        const response = await this.saveLocation(this.input);
        const { data } = response || {};
        const { success } = data || {};
        if (!success) {
          this.message = this.$t("newLocation.failed");
          this.notify = true;
          return;
        }
        this.message = this.$t("newLocation.created");
        this.notify = true;
        this.$emit("location", this.input);
        this.$emit("close");
      }
    },
    setCoords(coordinates) {
      if (!coordinates) {
        this.input.coordinates = new CoordinatesViewModel().coordinates;
        return;
      }

      if (this.$refs.fishingLocations) {
        this.$refs.fishingLocations.getDescription(coordinates);
      }

      this.input.coordinates = { ...coordinates };
    },
  },
};

const INPUT = {
  name: null,
  ocean: null,
  coordinates: new CoordinatesViewModel().coordinates,
};
</script>
