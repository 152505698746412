<template>
  <v-container>
    <img
      src="../../assets/images/path422.png"
      class="float-image"
      @click="donate"
      v-if="!mobileView"
    />
    <reward-solicitation ref="rewardForm" />
    <record-fish ref="recordFish" @done="reload" />
    <juvenile-billfish-form
      :open="openJuvenile"
      @update:open="(value) => (openJuvenile = value)"
      set-user
    />
    <batch-review ref="batchReview" @done="reload" />
    <record-entry @open="openDialog" />
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="showWelcome"
      width="750"
      overlay-opacity="0.7"
      persistent
    >
      <v-card class="text-center pt-5">
        <img :src="defaultAvatar" alt="Billfish" width="160" />
        <v-card-title />
        <v-card-text class="text-left">
          <p>
            {{ $t("dashboard.welcomeMsg.p1") }}
          </p>
          <p>
            {{ $t("dashboard.welcomeMsg.p2") }}
          </p>
          <p>{{ $t("dashboard.welcomeMsg.thanks") }}</p>
          <p>{{ $t("dashboard.welcomeMsg.team") }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-checkbox
            v-model="dontShowAgain"
            :label="$t('dashboard.welcomeMsg.dontShowAgain')"
          />
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeLoginMsg">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="roleConfirmation" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ $t("dashboard.roleConfirm.title") }} </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="roleForm">
            <v-autocomplete
              :items="['angler', 'captain', 'firstMate', 'secondMate']"
              :label="$t('dashboard.roleConfirm.role')"
              :rules="[(v) => !!v || 'Required']"
              v-model="role"
            >
              <template v-slot:selection="{ item }">
                {{ $t(`form.${item}`) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t(`form.${item}`) }}
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="roleConfirmation = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveRole">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" :order="getIndex('location')">
        <v-card flat title class="mt-2">
          <v-card-title>
            <v-icon>mdi-map-marker-multiple</v-icon>
            <h3 class="ml-2">{{ $t("dashboard.fishingLocations.title") }}</h3>
            <v-spacer />
            <v-btn icon @click="up('location')">
              <v-icon>mdi-chevron-up-circle</v-icon>
            </v-btn>
            <v-btn icon @click="down('location')">
              <v-icon>mdi-chevron-down-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="loading"
            striped
            height="5"
          ></v-progress-linear>
          <v-card-text v-else>
            <fishing-locations
              ref="locations"
              class="mb-5"
              :fabPosition="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :order="getIndex('history')">
        <fishing-history
          ref="history"
          class="mt-2"
          @load="loadTagsListView"
          :items="items"
          :totalDocs="totalDocs"
          :pageCount="pageCount"
          :loading="loading"
          @up="up('history')"
          @down="down('history')"
        />
      </v-col>
      <v-col cols="12" :order="getIndex('trophy')">
        <trophy-room
          ref="trophy"
          @up="up('trophy')"
          @down="down('trophy')"
          class="mt-2"
        />
      </v-col>
      <v-col cols="12" :order="getIndex('competition')">
        <competition
          @up="up('competition')"
          @down="down('competition')"
          class="mt-2"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { mapActions } from "vuex";
import { defaultAvatar, getUserInfo, setOrder } from "../../handlers/index";
import { notifyConfirmation } from '../../handlers/notifications';
import FishingHistory from "./FishingHistory.vue";
import FishingLocations from "./FishingLocations.vue";
import TrophyRoom from "./TrophyRoom.vue";
import Competition from "./Competition.vue";
import RecordEntry from "../../components/appComponents/RecordEntry.vue";
import RecordFish from "../Records/RecordFish.vue";
import BatchReview from "../Records/BatchReview.vue";
import JuvenileBillfishForm from "../Records/JuvenileBillfishForm.vue";
import RewardSolicitation from "../Records/RewardSolicitation.vue";
export default {
  name: "DashboardSection",
  components: {
    FishingHistory,
    FishingLocations,
    TrophyRoom,
    Competition,
    RecordEntry,
    RecordFish,
    BatchReview,
    'juvenile-billfish-form': JuvenileBillfishForm,
    RewardSolicitation
  },
  data: () => ({
    legacyId: null,
    items: [],
    totalDocs: 0,
    pageCount: 0,
    loading: false,
    ordered: null,
    overlay: false,
    eventType: "Tags",
    launchMsg: true,
    dontShowAgain: false,
    role: null,
    roleConfirmation: false,
    openJuvenile: false,
    askReward: false,
  }),
  async mounted() {
    const user = getUserInfo();
    this.legacyId = user?.id;
    this.ordered = setOrder();
    this.roleConfirmation = !!this.loggedIn || !user?.selectedRole;
  },
  computed: {
    loggedIn() {
      const { loggedIn } = this.$route.query || {};
      !!loggedIn && this.$router.replace({ query: null });
      return !!loggedIn;
    },
    recoverRecord() {
      const { id, record } = this.$route.query || {};
      !!id && this.$router.replace({ query: null });
      return { id, record };
    },
    showWelcome() {
      const dontShowLoginMsg = localStorage.getItem("dontShowLoginMsg");
      return !!this.loggedIn && this.launchMsg && !dontShowLoginMsg;
    },
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  watch: {
    recoverRecord: {
      handler: "launchRecoveryForm",
      immediate: true,
    },
  },
  methods: {
    ...mapActions("users", ["getUser"]),
    ...mapActions("dashboard", [
      "getTag",
      "getRelease",
      "getRecapture",
      "getTags",
      "getReleases",
      "getRecaptures",
      "getJuveniles",
      "getOthersTags",
      "getOthersReleases",
    ]),
    async loadTagsListView(data) {
      try {
      this.loading = true;
      this.items = [];
      const {tab, forOthers, options, sort, order, filter } = data || {};
      const items = await this[
        forOthers ? `getOthers${tab}` : `get${tab}`
      ]({
        legacyId: this.legacyId,
        page: options?.page || 1,
        limit: options?.itemsPerPage || 10,
        sort,
        order: order ? 1 : 2,
        filter: { ...filter, draft: forOthers ? null : filter.draft }
      });
      this.loading = false;

        const result = items?.data[`${String(tab).toLowerCase()}ById`];
      this.items = this.modifyArray(result?.docs);
      this.pageCount = result?.totalPages;
      this.totalDocs = result?.totalDocs;
      setTimeout(() => {
        if (this.$refs.locations) {
        this.$refs.locations.addMarkers(this.items);
      }
      }, 100);
    } catch(error) {
      this.loading = false;
    }
    },
    modifyArray(array) {
      return array?.map((item) => {
        item.captain.fullName =
          item?.captain?.firstName && item?.captain?.lastName
            ? `${item?.captain?.firstName} ${item?.captain?.lastName}`
            : "";
        item.angler.fullName =
          item?.angler?.firstName && item?.angler?.lastName
            ? `${item?.angler?.firstName} ${item?.angler?.lastName}`
            : "";
        return item;
      });
    },
    up(name) {
      const from = this.getIndex(name);
      this.ordered = setOrder(from, from - 1);
    },
    down(name) {
      const from = this.getIndex(name);
      this.ordered = setOrder(from, from + 1);
    },
    getIndex(name) {
      return this.ordered?.indexOf(name);
    },
    async openDialog(type) {
      this.overlay = true;

      if (type === 'Juvenile') {
        this.openJuvenile = true;
        this.overlay = false;
        return;
      }

      this.eventType = type;

      const response = await this.getUser({ id: this.legacyId });
      const { multipleEvents } = response?.data || {};

      if (multipleEvents) {
        this.askMultiple();
        this.overlay = false;
        return;
      }

      if (this.eventType === "Recaptures") {
        this.addNew();
        this.overlay = false;
        return;
      }

      this.askBatch();
      this.overlay = false;
    },
    addNew() {
      this.$refs.recordFish && this.$refs.recordFish.init(null, this.eventType);
    },
    addMultiple() {
      this.$refs.batchReview && this.$refs.batchReview.init(this.eventType);
    },
    async reload(data) {
      const { recordType } = data || {};
      if (recordType) {
        await this.askForRewardConfig(data);
      }
      this.$refs.history && this.$refs.history.load();
      this.$refs.trophy && this.$refs.trophy.loadListView();
    },
    closeLoginMsg() {
      this.launchMsg = false;
      this.dontShowAgain && localStorage.setItem("dontShowLoginMsg", true);
    },
    saveRole() {
      const valid = this.$refs.roleForm && this.$refs.roleForm.validate();
      if (valid) {
        this.roleConfirmation = false;
        const data = JSON.parse(localStorage.getItem("data"));
        data.user.selectedRole = this.role;
        localStorage.setItem("data", JSON.stringify(data));
      }
    },
    getPlaces(place) {
      return place > 3
        ? `${place}th`
        : place === 3
        ? "3rd"
        : place === 2
        ? "2nd"
        : "1st";
    },
    async launchRecoveryForm({ id, record }) {
      if (!id || !record) {
        return;
      }

      this.overlay = true;
      const type = record[0].toUpperCase() + record.slice(1, record.length);
      const response = await this[`get${type}`]({ id });
      const input = response?.data?.docs;
      this.overlay = false;

      if (!input) {
        return;
      }

      setTimeout(() => {
        this.$refs.recordFish && this.$refs.recordFish.init(input, type + "s");
      }, 150);
    },
    async askForRewardConfig(data) {
      const { recordType, crew, recordId } = data || {};
      if (!/recaptur/i.exec(recordType)) {
        return;
      }

      if (this.$refs.rewardForm) {
        this.overlay = true;
        await this.$refs.rewardForm.init(recordId, crew);
        this.overlay = false;
      }
    },
    donate() {
      window.open("https://billfish.org/donate/", "_blank");
    },
    askBatch() {
      const type = this.eventType.toLowerCase();
      const singular = String(type).slice(0, -1);
      notifyConfirmation(
        this.$t("dashboard.batchUploadConfirm.subtitle", { singular }),
        this.$t("dashboard.batchUploadConfirm.title", { type: this.$t(type) }),
        () => this.addNew(),
        () => this.$router.push({ path: "/batch-import?recordType=" + type }),
        this.$t("dashboard.batchUploadConfirm.single", { singular }),
        this.$t("dashboard.batchUploadConfirm.multiple"),
        false
      );
    },
    askMultiple() {
      notifyConfirmation(
        this.$t("dashboard.forOthersConfirm.title"),
        "",
        () => {
          if (this.eventType === "Recaptures") {
        this.addNew();
        this.overlay = false;
        return;
      }
          this.askBatch()
        },
        () => this.addMultiple(),
        this.$t("dashboard.forOthersConfirm.own"),
        this.$t("dashboard.forOthersConfirm.others")
      );
    },
  },
};
</script>

<style>
.v-text-field input {
  font-size: 1.2em;
}
.sticky-top {
  top: 70px !important;
}

.float-image {
  position: fixed;
  width: 100px;
  top: 50%;
  right: -50px;
  z-index: 99;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: rotate(-90deg);
}

.float-image:hover {
  transform: translateX(-80px) scale(1.5);
}
</style>
