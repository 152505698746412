<template>
  <v-row
    no-gutters
    :class="{
      invalid: !valid,
      valid: valid,
      'text-body-2': true,
      'font-weight-light': true,
      'py-2': true,
    }"
  >
    <v-col cols="12" v-if="!valid && isDuplicated">
      <v-banner color="red--text font-weight-bold text-center" single-line>
        {{ $t("form.recordExists") }}
      </v-banner>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4" v-if="!isRelease">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': tagNumberHasError,
                }"
              >
                {{ $t("form.number") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ tagNumber }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4" v-else>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': speciesHasError,
                }"
              >
                {{ $t("form.species") }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-for="item in speciesList"
                v-bind:key="item"
              >
                {{ item }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="!speciesList.length">
                {{ $t("notSpecified") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': dateHasError }"
              >
                {{ isRelease ? $t("form.releaseDate") : $t("form.tagDate") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ tagDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title>
                {{ $t("form.enteredDate") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ enteredDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': oceanHasError }"
              >
                {{ $t("form.ocean") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ ocean }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="8">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': coordinatesHasError,
                }"
              >
                {{ $t("form.coordinates") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ coords }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="4">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{
              'red--text font-weight-bold': locationHasError,
            }"
          >
            {{ $t("form.location") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ location }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" sm="4" v-if="!isRelease">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{ 'red--text font-weight-bold': fishLengthHasError }"
          >
            {{ $t("form.fishLength") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ fishLength }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" sm="4" v-if="!isRelease">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{ 'red--text font-weight-bold': fishWeightHasError }"
          >
            {{ $t("form.fishWeight") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ fishWeight }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" lg="6" v-if="!isRelease">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': speciesHasError,
                }"
              >
                {{ $t("form.species") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ specieName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': conditionHasError,
                }"
              >
                {{ $t("form.condition") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ fishCondition }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': fightTimeHasError,
                }"
              >
                {{ $t("form.fightTime") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ fightTime }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6" v-else>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title>
                {{ $t("form.linesIn") }}
              </v-list-item-title>
              <v-list-item-subtitle> {{ relLines }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title>
                {{ $t("form.linesOut") }}
              </v-list-item-title>
              <v-list-item-subtitle> {{ relLines0 }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': linesHasError }"
              >
                {{ $t("form.totalLines") }}
              </v-list-item-title>
              <v-list-item-subtitle> {{ totalLines }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': baitHasError }"
              >
                {{ $t("form.bait") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ bait }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': hookHasError }"
              >
                {{ $t("form.hook") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ hook }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4" v-if="!isRelease">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': gearHasError }"
              >
                {{ $t("form.gear") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ gear }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': boatHasError }"
              >
                {{ $t("form.boat") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ boatName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title>
                {{ $t("form.sharkEncounter") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ sharkEncounter }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': anglerHasError }"
              >
                {{ $t("form.angler") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ angler }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': captainHasError,
                }"
              >
                {{ $t("form.captain") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ captain }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': firstMateHasError,
                }"
              >
                {{ $t("form.firstMate") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ firstMate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': secondMateHasError,
                }"
              >
                {{ $t("form.secondMate") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ secondMate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="4" v-if="!isRelease">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{ 'red--text font-weight-bold': tournamentHasError }"
          >
            {{ $t("form.tournament") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ tournament }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" lg="6">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title>
            {{ $t("form.notes") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ notes }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-row>
</template>

<script>
import { fixTimezone, validCoordinates } from "../../handlers";

export default {
  name: "RecordPreview",
  props: {
    record: {
      type: Object,
      required: true,
    },
    recordType: {
      type: String,
      default: "tags",
    },
  },
  computed: {
    valid() {
      const { ready } = this.record || {};
      return Boolean(ready);
    },
    coords() {
      const areValid = validCoordinates(this.record.coordinates);

      if (!areValid) {
        return this.$t("invalidCoords");
      }

      const { latitude, longitude } = this.record.coordinates || {};

      const latitudeStr = `${latitude["degrees"]}° ${latitude["minutes"]}' ${latitude["hemisphere"]}`;
      const longitudeStr = `${longitude["degrees"]}° ${longitude["minutes"]}' ${longitude["hemisphere"]}`;

      return `${latitudeStr} / ${longitudeStr}`;
    },
    boatName() {
      const { boat, boatName } = this.record || {};
      return boat?.name || boatName || this.$t("notSpecified");
    },
    fightTime() {
      const { hours, minutes } = this.record?.capture?.fish?.fight || {};

      if (hours) {
        return [hours, minutes]
          .filter(Boolean)
          .map((value) => (value < 10 ? "0" + value : value))
          .join(":")
          .concat("h");
      }

      if (!minutes) {
        return this.$t("notSpecified");
      }

      return (minutes < 10 ? "0" + minutes : minutes) + "min";
    },
    fishLength() {
      return this.getFishStat(this.record.capture.fish.length);
    },
    fishWeight() {
      return this.getFishStat(this.record.capture.fish.weight);
    },
    location() {
      const { coordinates, locality } = this.record || {};
      return coordinates?.description || locality || this.$t("notSpecified");
    },
    tagDate() {
      const { createdDate } = this.record || {};
      return this.formatDate(createdDate);
    },
    enteredDate() {
      const { enteredDate } = this.record || {};
      return this.formatDate(enteredDate);
    },
    specieName() {
      const { specie } = this.record?.capture?.fish || {};
      return specie?.name || this.$t("notSpecified");
    },
    speciesList() {
      const { fish } = this.record?.capture || {};
      return fish?.map(({ name, qty }) => `${name} (${qty})`) || [];
    },
    bait() {
      const { bait } = this.record?.capture || {};
      return bait?.name || this.$t("notSpecified");
    },
    ocean() {
      const { ocean } = this.record?.capture || {};
      return ocean || this.$t("notSpecified");
    },
    fishCondition() {
      const { condition } = this.record?.capture?.fish || {};
      return condition?.name || this.$t("notSpecified");
    },
    tagNumber() {
      const { number } = this.record || {};
      return number || this.$t("notSpecified");
    },
    sharkEncounter() {
      const { sharkEncounter } = this.record || {};
      return sharkEncounter ? this.$t("yes") : this.$t("no");
    },
    gear() {
      const { gear } = this.record?.capture || {};
      return gear?.name || this.$t("notSpecified");
    },
    hook() {
      const { hook } = this.record?.capture || {};
      return hook?.name || this.$t("notSpecified");
    },
    tournament() {
      const { tournament } = this.record?.capture || {};
      return tournament?.name || this.$t("notSpecified");
    },
    angler() {
      const { angler } = this.record || {};
      return this.fullName(angler);
    },
    captain() {
      const { captain } = this.record || {};
      return this.fullName(captain);
    },
    firstMate() {
      const { firstMate } = this.record || {};
      return this.fullName(firstMate);
    },
    secondMate() {
      const { secondMate } = this.record || {};
      return this.fullName(secondMate);
    },
    notes() {
      const { notes } = this.record?.capture || {};
      return notes || this.$t("notSpecified");
    },
    isRelease() {
      return this.recordType === "releases";
    },
    relLines() {
      const { relLines } = this.record || {};
      return relLines || this.$t("notSpecified");
    },
    relLines0() {
      const { relLines0 } = this.record || {};
      return relLines0 || this.$t("notSpecified");
    },
    totalLines() {
      const { relNumLi } = this.record || {};
      return relNumLi || this.$t("notSpecified");
    },
    tagNumberHasError() {
      return this.getTagError("tagnumber");
    },
    speciesHasError() {
      return this.getTagError("species");
    },
    dateHasError() {
      return this.getTagError("date");
    },
    oceanHasError() {
      return this.getTagError("ocean");
    },
    coordinatesHasError() {
      return this.getTagError("coordinates");
    },
    locationHasError() {
      return this.getTagError("location");
    },
    fishLengthHasError() {
      return this.getTagError("fishlength");
    },
    fishWeightHasError() {
      return this.getTagError("fishweight");
    },
    conditionHasError() {
      return this.getTagError("condition");
    },
    fightTimeHasError() {
      return this.getTagError("fighttime");
    },
    baitHasError() {
      return this.getTagError("bait");
    },
    hookHasError() {
      return this.getTagError("hook");
    },
    gearHasError() {
      return this.getTagError("gear");
    },
    boatHasError() {
      return this.getTagError("boat");
    },
    anglerHasError() {
      return this.getTagError("angler");
    },
    captainHasError() {
      return this.getTagError("captain");
    },
    firstMateHasError() {
      return this.getTagError("firstmate");
    },
    secondMateHasError() {
      return this.getTagError("secondmate");
    },
    tournamentHasError() {
      return this.getTagError("tournament");
    },
    linesHasError() {
      return this.getTagError("lines");
    },
    isDuplicated() {
      return this.getTagError("duplicated");
    },
  },
  methods: {
    getTagError(tag) {
      const { errors } = this.record || {};

      if (!errors?.length) {
        return false;
      }

      return errors.some((error) => error.includes(tag));
    },
    fullName(user) {
      const { firstName, lastName, id } = user || {};

      if (!id) {
        return this.$t("notSpecified");
      }

      return (
        [firstName, lastName].filter(Boolean).join(" ").toUpperCase() || id
      );
    },

    formatDate(dateValue) {
      if (!dateValue) {
        return this.$t("notSpecified");
      }

      const date = fixTimezone(dateValue);
      return date ? date.format("MM/DD/YYYY") : this.$t("invalidDate");
    },

    getFishStat(data) {
      const { value, unit, determination, type } = data || {};

      if (!value && Number(value) !== 0) {
        return this.$t("notSpecified");
      }

      const measure = [value, unit].filter(Boolean).join(" ");
      return (
        [measure, type, determination].filter(Boolean).join(", ") ||
        this.$t("unknown")
      );
    },
  },
};
</script>

<style scoped>
.bg-primary {
  background-color: #1976d2 !important;
  display: flex;
}
.bg-secondary {
  background-color: #424242 !important;
  display: flex;
}

.invalid {
  border-left: 5px solid red;
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-radius: 4px;
}

.valid {
  border-left: 5px solid rgb(30, 136, 229);
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-radius: 4px;
}

.ribbon {
  position: absolute;
  top: 15px;
  right: 20px;
}
</style>
