import { render, staticRenderFns } from "./NewBoat.vue?vue&type=template&id=4b929395"
import script from "./NewBoat.vue?vue&type=script&lang=js"
export * from "./NewBoat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports