class HttpClientFactory {
    constructor(axios) {
        this._baseConfig = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        this._secureHeaders = {};
        this._clients = {};
        this._axios = axios;
    }

    get clients() {
        return this._clients;
    }

    add(client) {
        //Set default client configuration
        let mergedConfig = {...client.config, ...this._baseConfig };

        //Set secure headers if client needs elevated access
        if (client.isSecure) {
            mergedConfig.headers = {...mergedConfig.headers, ...this._secureHeaders };
        }

        //Update http client dictionary
        this._clients[client.name] = this._axios.create(client.config);

        return this;
    }
}

export default HttpClientFactory;