<template>
  <v-container class="fill-height justify-center">
    <v-snackbar v-model="notify" :timeout="5000" top>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="notify = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-col md="8" lg="6">
        <v-card class="elevation-4">
          <div class="pa-7 pa-sm-12 text-center">
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              The Billfish Foundation Tag & Release Application
            </h2>
            <v-avatar class="profile" color="grey" size="164" tile>
              <v-img :src="defaultAvatar" alt="Billfish" />
            </v-avatar>

            <div v-if="loading">
              <v-banner class="mb-4 justify">
                <v-avatar size="80">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-avatar>
                <v-spacer />
                <h3
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                >
                  Activating...
                </h3>
              </v-banner>
            </div>

            <div v-else-if="success">
              <v-banner class="mb-4 justify">
                <v-avatar color="green" size="80">
                  <v-icon icon="mdi-check-decagram" color="white" large>
                    mdi-check-decagram
                  </v-icon>
                </v-avatar>
                <v-spacer />
                <h3
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                >
                  Account successfully activated
                </h3>
              </v-banner>

              <v-btn color="primary" @click="$router.push({ path: 'login' })">
                Sign In
              </v-btn>
            </div>

            <div v-else>
              <v-banner class="mb-4 justify">
                <v-avatar color="red" size="80">
                  <v-icon icon="mdi-check-decagram" color="white" large>
                    mdi-close
                  </v-icon>
                </v-avatar>
                <v-spacer />
                <h3
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                >
                  Account couldn't be activated
                </h3>
              </v-banner>

              <v-btn
                color="primary"
                @click="$router.push({ path: 'activation-email' })"
              >
                Account Activation
              </v-btn>
            </div>

            <v-row justify="end" align="center" class="mt-5">
              <v-col cols="8" align-self="center" class="text-start">
                <span>
                  &copy;
                  <span v-text="` - The Billfish Foundation - ${year}`" />
                </span>
              </v-col>
              <v-spacer />
              <v-col>
                <a href="privacy" target="_blank" class="link">
                  {{ $t("privacy") }}
                </a>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { defaultAvatar } from "../../handlers";

export default {
  name: "account-activated",
  data: () => ({
    notify: false,
    message: null,
    success: true,
    loading: true,
  }),
  computed: {
    year() {
      return new Date().getFullYear();
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  methods: {
    ...mapActions("users", ["accountActivation"]),
    async activateAccount(code, email) {
      this.loading = true;
      try {
        await this.accountActivation({ code, email });
      } catch (error) {
        this.success = false;
      }
      this.loading = false;
    },
  },
  async beforeMount() {
    const { code, email } = this.$route?.query || {};
    await this.activateAccount(code, email);
    this.$router.replace({ query: null });
  },
};
</script>
