const LOGIN_FAQ = [
  {
    question: "How Do I Access the New Site?",
    answer: `
    <p>
        Reset your current login credentials. You can do this by resetting your password. Need more help, please click on <a href="https://youtu.be/MlgM__VUmhc" target="_blank">this link</a> to watch a helpful video tutorial on the process.
    </p>
        `,
  },
  {
    question:
      "Will all the information from the old site be available on the new site?",
    answer: `
    <p>
        Absolutely. All data and personalized fishing history was transferred to the new system. If you do not see data there, please contact us.
    </p>
        `,
  },
  {
    question: "What if my address and/or personal information has changed?",
    answer: `
    <p>
        Go to <a href="https://www.tagbillfish.org/login" target="_blank">tagbillfish.org</a> and visit your Profile page (located on sidebar or by clicking on the TBF label in the top right corner of the dashboard). Update your personal information as needed.
    </p>
        `,
  },
  {
    question: "Can I login using Google? Facebook?",
    answer: `
    <p>
    Yes! All you have to do is choose what platform you would login with. It should take you to another screen to confirm if you approve, and if so, voila! You should have access to our tag and release application.
    </p>
    `,
  },
];

const GENERAL_FAQ = [
  {
    question: "What is the data used for?",
    answer: `
    <p>
        The data TBF receives from dedicated anglers and captains around the globe is crucial to providing vital data related to billfish conservation. By tagging, releasing and reporting billfish catches, TBF can further understand growth rates, migratory patterns, habitat utilization, and post-release survival rates. That data can then be utilized to help ensure fish stocks are protected and ample sportfishing opportunities are available for future generations. The collected data is instrumental in providing the groundwork for conservation-minded policy and scientific advancement, while also giving insight into the demographics and socio-economic benefits generated from billfishing.
    </p>
        `,
  },
  {
    question: "Is there a TBF App?",
    answer: `
    <p>
        There is no TBF application. That being said, we completely revamped the mobile view to be compatible with most smartphones, tablets and desktops. Simply type in the URL into your preferred internet browser and you’re all set.
        
        <br />
        <br />

        Additionally, you can create a shortcut on your phone’s home screen to streamline the process even more. See video tutorial on how to do that here:
        <a href="https://www.youtube.com/watch?v=o73gvyx87ow" target="_blank">Create a shorcut on phone</a>
    </p>
        `,
  },
  {
    question: "Is the site still free to use?",
    answer: `
    <p>
        100% yes. Without the support of the amazing angling community, TBF would not be where we are today. Free accessibility to our site is just one of the ways we can show our appreciation.
    </p>
        `,
  },
  {
    question: "What’s different about the new site?",
    answer: `
    <p>
        While the general layout is similar to the previous version, we’ve highlighted the most frequently utilized features and added a revamped mobile phone interface. Also, located on the sidebar of the site, there is a link to some video tutorials to help get you started.
    </p>
        `,
  },
  {
    question: "Can I purchase tags on this new website?",
    answer: `
    <p>
        While you cannot purchase tags directly on this site, we provide a link to our shop on the sidebar. You can also go directly to <a href="http://shop.billfish.org/" target="_blank">shop.billfish.org</a>.
    </p>
        `,
  },
  {
    question: "Who are the companies listed on the site?",
    answer: `
    <p>
        These companies support TBF’s tag and release program. We encourage you to check out their sites and support them. These companies understand the importance of billfish conservation, how better billfish numbers bring better business, and most importantly, that conservation pays.
    </p>
        `,
  },
  {
    question: "What if Spanish is my first language?",
    answer: `
    <p>
        On the top right of your screen is the option to switch between English and Spanish. ENG = English. ESP = Español (Spanish)
    </p>
    `,
  },
];

const BUGS_FAQ = [
  {
    question: "What if I encounter a glitch (bug) in the new system?",
    answer: `
    <p>
        Let us know! Our goal is to address and/or remove all issues with the new system as quickly as possible, but we need everyone’s help to find them. Email us at <a href="mailto:tag@billfish.com">tag@billfish.com</a> and we will get you set up. You can also contact us directly on our website at <a href="https://billfish.org/contact/" target="_blank">https://billfish.org/contact/</a>
    </p>
        `,
  },
  {
    question:
      "What if I have recommendations or ideas on how to improve the site?",
    answer: `
    <p>
        Let us know that too! We are always looking to improve our application. Even with this recent implementation of the new system, we are already working toward improvements on the next iteration. Your feedback is essential to providing the best user experience possible. Let us know your thoughts by contacting us at <a href="mailto:tag@billfish.com">tag@billfish.com</a> or reaching out directly on the website at <a href="https://billfish.org/contact/" target="_blank">https://billfish.org/contact/</a>
    </p>
        `,
  },
  {
    question: "Why is the map not working?",
    answer: `
    <p>
        This issue is fixed by either refreshing the page or logging out and in again. It seems as though the map malfunctions on occasion after being logged in for an extended duration. To learn how to use the map, refer to “How to use the map function when submitting a tag or release?” within the Data Entry FAQ section.
    </p>
    `,
  },
  {
    question: "What if the portal isn’t working on my browser?",
    answer: `
    <p>
        The portal should function on Google Chrome, Safari, Firefox and Internet Explorer. If you are encountering issues, please send us an email at tag@billfish.com with your current browser and version. In the meantime, we recommend trying another browser until the issue can be resolved.
    </p>
    `,
  },
];

const COMPETITION_FAQ = [
  {
    question: "Can I find out my status of the competition on the site?",
    answer: `
    <p>
        Go to the competition calculator at the bottom of the dashboard. Please note the calculator is not in realtime and it will STOP updating a month prior to October 31. We need to have some suspense!
    </p>
        `,
  },
  {
    question: "Where can I see my achievements/trophies?",
    answer: `
    <p>
        If you scroll down beneath your fishing locations map, you’ll see your fishing history and trophies. The competition calculator is located beneath that.
    </p>
        `,
  },
  {
    question: "Can I see other’s fishing history or achievements?",
    answer: `
    <p>
        To protect angler privacy, you will only be able to see your fishing achievements and history.
    </p>
        `,
  },
  {
    question: "How do I use the Competition Calculator?",
    answer: `
    <p>
        Select one of the locations displayed on the top banner and choose one of the categories from the drop-down menu. Leader board may not be fully accurate until all data has been input. Data will not be accepted after November 15th of each year.
    </p>
        `,
  },
  {
    question: "What are achievements/trophies?",
    answer: `
    <p>
        To show our appreciation for all the captains, anglers, and mates each year, TBF hosts a number of regional competitions as well as an accumulative, annual, worldwide competition between November 1st and October 31st of the following year where we distribute awards and trophies in recognition of all the incredible effort from our members. To be eligible for an award and participate in TBF’s Annual Tag & Release Competition, you must become a TBF member and submit your Tag Data Report cards and Release Notification cards (either online or via mail) to us prior to November 15th of the year in question. TBF provides awards for a number of categories including (but not limited to) top overall tagging captain, top overall release angler, and top overall tagging youth angler.
    </p>
        `,
  },
  {
    question: "What is a TBF Certificate?",
    answer: `
    <p>
        TBF members are eligible for release certificates based on tagging, releases, and recaptures. These certificates document the species caught, including location, date, etc.  By providing vessel information, including captain and angler details, the charter has another way to market for their business or look at as a great memento to revisit that amazing billfish catch!
    </p>
        `,
  },
];

const DATA_ENTRY_FAQ = [
  {
    question: "Do I need to be a member to report billfish data?",
    answer: `
    <p>
        Nope. Although we’d love for you to join the TBF team and become a member, it is not a requirement to submit tagging data. If you’re curious about membership details, click on the following link <a href="https://billfish.org/become-a-member/" target="_blank">https://billfish.org/become-a-member/</a>
        
        <br />
        <br />

        You do however need to be a member to be eligible for the TBF Annual Awards Ceremony.
    </p>
        `,
  },
  {
    question:
      "What’s the difference between submitting a tag, release and recapture?",
    answer: `
    <div>
        <p>
            On the top of the dashboard, there are three tagging options to choose from.
        </p>
        <p>
            <ul>

                <li>
                    To Submit a Tag (blue), means a billfish was caught and a TBF tag was successfully deployed on said fish.
                </li>

                <li>
                    To Submit a Release (green) indicates a billfish was successfully caught and released without deploying a TBF tag.
                </li>

                <li>
                    To Submit a Recapture (orange), this refers to catching a billfish that was previously tagged with a TBF tag. As opposed to selecting “Submit a Tag” or “Submit a Release” options, clicking on the recapture option will direct you to a templated email where you can provide the recapture information to TBF. Make sure to confirm whether a new tag was deployed on any recaptures.
                </li>

            </ul>
        </p>
    </div>
        `,
  },
  {
    question: "Can I submit more than one tag?",
    answer: `
    <p>
        While each tag must be submitted individually, there is an option (Save & Copy) during the data entry process that allows you to use some of the same information from the previous tag submission to be entered on the following submission entry.
    </p>
        `,
  },
  {
    question: "What if I’m missing information on the tag card?",
    answer: `
    <p>
        If you missed or forgot to include information on the tag data report, just leave that field blank during data entry and try to remember to include it for the next tag. The more information we can include in our database, the better, but we understand fields might occasionally be overlooked or forgotten. Email or a phone number should always be included in case we need to contact you regarding information on the tag card.
    </p>
        `,
  },
  {
    question: "How do I know if a tag was successfully entered?",
    answer: `
    <p>
        Once a tag has been entered, it should appear in your fishing history. If it doesn’t appear, refresh the page and view again. You might need to try entering the data one more time. If still no luck, please contact our office for assistance.
    </p>
        `,
  },
  {
    question: "How to use the map function when submitting a tag or release?",
    answer: `
    <div>
        <p>
          There are three methods to utilize the map function:

            <ol>

                <li>
                    Above the map, type the location of the catch in the Location Name data field box.
                    <ol type="a">
                      <li>
                        If a location does not appear, click on the “plus” button to the left of the Location Name and manually enter the information. (This information will then be an option during future data entry)
                      </li>
                    </ol>
                </li>

                <li>
                    Beneath the map, you can also manually type in the latitude and longitude to set location.
                </li>

                <li>
                    On the map, go to the bottom left corner  and click the round button to “Enable Navigation”. This will allow you to drag the map to said fishing location. Once in the general area where the encounter occurred, double click on the specific location to drop a marker. Click on that marker (blue), and then click on the white area above the blue marker to set location. To confirm the location was entered, verify the latitude and longitude auto populated beneath the map.
                </li>

            </ol>
        </p>
    </div>
        `,
  },
  {
    question:
      "If I deployed a new tag on a recapture, should I also “Submit a Tag” in addition to selecting the option for “Submit a Recapture”?",
    answer: `
    <p>
        Yes! If a fish was recaptured and the original tag was successfully removed, it’s not always a guarantee you’ll have time to safely deploy a new tag. If a new tag is successfully deployed however, you’ll need to report both the recapture information and then submit new tag on the site.
    </p>
        `,
  },
  {
    question: "Can I write multiple species on one card?",
    answer: `
    <p>
        For the Tag Data Report (white card), only one fish per card, per angler. For the TBF Release Notification Card (blue card), it’s still one card per angler, but the reporting is per day, not per fish, meaning multiple fish can be added to one card. Just tally the number of fish caught next to each species on the card.
    </p>
        `,
  },
  {
    question:
      "Can I send you the tagging information via email, social media, or phone, without filling out the card?",
    answer: `
    <p>
        You can, but the process is a little slower. On each tagging card there are multiple data fields that must be completed (same for release cards), meaning we need data for all those questions. While it can be done manually via social media or email, the quickest and most efficient methods include filling out the tag data reports and entering the data yourself into the tagging system, or mailing the cards to the TBF office. We understand there may be limits depending on your location though and will be happy to accommodate if needed.
    </p>
        `,
  },
  {
    question:
      "Is it preferred to mail the cards or enter the data directly online?",
    answer: `
    <p>
        Either is acceptable, but entering the data yourself online is always preferred. In addition to being more timely, entering the data in the system yourself will help show if any information was missed from your tagging card. This will only lead to a higher quality of billfish data. Always keep a copy for yourself just in case as well.
    </p>
        `,
  },
  {
    question:
      "Can I submit data to TBF that was already submitted to another organization?",
    answer: `
    <p>
        As long as the information submitted to TBF meets our requirements for data collection, you can also submit the same data to other locations, or fill out a TBF release card for data submitted to another organization. Just remember, to submit a TBF Tag Data Report, you must use a TBF tag. To purchase tags, visit our shop at <a href="https://shop.billfish.org/" target="_blank">https://shop.billfish.org/</a>
    </p>
        `,
  },
  {
    question:
      "As a captain, mate or angler for many vessels, can I only record for one boat?",
    answer: `
    <p>
        You can absolutely fill out cards for multiple boats. Just make sure to differentiate which boat you are submitting data for by filling out a new card for each vessel.
    </p>
        `,
  },
  {
    question:
      "As a solo captain, can I submit my tagging data as a captain and angler?",
    answer: `
    <p>
        As a solo captain, you have the privilege of submitting data under either the angler or captain categories, but not both.
    </p>
        `,
  },
  {
    question: "Can I enter tagging information for someone else?",
    answer: `
    <p>
        Absolutely! When you click “Submit a Tag”, just fill out the information as you would for yourself, except under the “Angler” section, just add the name of the angler you’re entering the data for. After you submit the tag information, the person you entered the tag for will receive an email notifying them of the data submission.

        <br />
        <br />

        On your home dashboard, underneath the map, there are two tabs: “Fishing History” and “Entered for Others”. As you might expect, this is where you can see your personal fishing history and the data you submitted for others.
    </p>
        `,
  },
  {
    question:
      "What if something strange happened during the fish encounter? Or what if we observed something peculiar?",
    answer: `
    <p>
        We included a Notes section just for you! Beneath Hook type and above Gear, there’s a free writing data field. Feel free to add any relevant observations or notes that you think are valuable for this particular encounter.
    </p>
        `,
  },
  {
    question: "Can I search for other users and/or fishing history?",
    answer: `
    <p>
        Yes, but the information is limited to only data you’ve entered for others.
    </p>
        `,
  },
  {
    question: "How to use the map function when submitting a tag or release?",
    answer: `
    <div>
        <p>
            <ul type="-">

                <li>
                    Save: Clicking here will submit your data and return you to your Dashboard
                </li>

                <li>
                    Save & New: Clicking here will submit your data and return you to a <strong>BLANK</strong> form so you can submit all new tagging information
                </li>

                <li>
                    Save & Copy: Clicking here will submit your data and return you to a partially filled form. Fields that are commonly the same between submissions of the same trip will be pre-filled with the information from your previous entry. This will allow you to expedite the data entry process.
                <ul type="-">
                  <li>
                      The following fields will be auto populated for the next submission. (If any of the information for the following fields changes between submissions, manually adjust this information prior to submitting new tag/release information.
                      <ul type="-">
                        <li>
                            Ocean, Date, Location Name, Latitude & Longitude, Bait Type, Hook, Gear, Tournament, Angler, Captain, Boat Name, 1st Mate, 2nd Mate
                            <ul type="-">
                              <li>
                                  Notes also carryover between submissions, so make sure to adjust them if needed
                              </li>
                            </ul>
                        </li>
                      </ul>
                  </li>
                </ul>
                </li>

            </ul>
        </p>
    </div>
        `,
  },
  {
    question: "Can I add photos?",
    answer: `
    <p>
      Yes please! In fact we hope you do! When submitting a tag, enter the Tag Number, Ocean, and Species. Once you enter a species, you’ll see an icon upload. Click here to upload your photo!
    </p>
        `,
  },
];

const RESEARCH_FAQ = [
  {
    question: "I encountered a billfish, what do I do now?",
    answer: `
    <p>
        Decide if you want to tag, release or inspect the billfish for previous tags. Angler safety is paramount, so the ultimate decision will depend on the vessel, crew, environmental conditions, etc. If you don’t feel comfortable or prepared for the tagging process, we completely understand! Feel free to reach out to us if you think we can help in any way for the next encounter.
    </p>
        `,
  },
  {
    question: "I encountered a tagged billfish, what do I do now?",
    answer: `
    <div>
        <p>
            <ul>

                <li>
                    Record all relevant information regarding the recapture (all needed information can be found by visiting <a href="https://www.tagbillfish.org/" target="_blank">tagbillfish.org</a> and clicking on the orange, Submit a Recapture button.
                </li>

                <li>
                    Safely remove the old tag by cutting the monofilament leader attached to the tag head. Do not pull the tag from the billfish, as this can injure and damage the fish.
                </li>

                <li>
                    Safely deploy a new tag and submit this information under the Submit a Tag section of the tagging site.
                </li>

                <li>
                    Patiently revive fish by holding bill underwater as boat idles forward. This process can take up to 30 minutes.
                </li>

                <li>
                    Fill out Tag Data Report card and submit to TBF or manually enter data into system at <a href="https://www.tagbillfish.org/" target="_blank">tagbillfish.org</a>
                </li>

            </ul>
        </p>
    </div>
        `,
  },
  {
    question:
      "I encountered a billfish, and want to tag it. What’s the process?",
    answer: `
    <div>
        <p>
            <ul>
                <li>
                    Once the billfish is subdued and has been safely brought boatside, keep the vessel idling forward, allowing the billfish to remain parallel with the vessel.
                </li>

                <li>
                    While one person maintains hold of the billfish, another one should gather the pre-assembled tag and tagging applicator.
                </li>

                <li>
                    Once positioned appropriately, firmly press the tagging stick into the dorsal muscle above the lateral line, behind the head and gills. Make sure to keep the fish’s head in the water.
                </li>

                <li>
                    Once tagged, remove the hook or cut leader as close to mouth as possible. Measure fish and estimate weight.
                </li>

                <li>
                    Patiently revive fish by holding bill underwater as boat idles forward. This process can take up to 30 minutes.
                </li>

                <li>
                    Fill out the Tag Data Report card and submit to TBF or manually enter data into system at <a href="https://www.tagbillfish.org/" target="_blank">tagbillfish.org</a>
                </li>
            </ul>
        </p>
    </div>
        `,
  },
  {
    question: "I encountered a juvenile billfish. What do I do now?",
    answer: `
    <p>
        Please send the date, location, latitude/longitude to <a href="https://www.tagbillfish.org/" target="_blank">tagbillfish.org</a>. Feel free to include a photograph or any additional information. Thank you for thinking of us!
    </p>
        `,
  },
  {
    question:
      "I encountered a shark during my billfish interaction. What do I do now?",
    answer: `
    <p>
        We want to know about it! The more data we can collect about this ever-growing issue, the better opportunity we will have to advocate on behalf of the angling community. With sound science behind us, we can then make a push for better regulations and management efforts to the powers that be. Simply check the box that states you had a Shark Encounter and if you have it, upload a picture of it.
    </p>
        `,
  },
  {
    question: "Why shouldn’t billfish be removed from the water?",
    answer: `
    <p>
        According to 50 CFR § 635.21 - Gear operation and deployment restrictions, “(2) If a billfish is caught by a hook and not retained, the fish must be released by cutting the line near the hook or by using a dehooking device, in either case without removing the fish from the water.”

        <br />
        <br />

        In addition to the federal regulations, removing a billfish from the water can result in fatal damage to the fish. Excess rubbing and drying can result in the loss of the protective slime coat found on the skin which helps it fight parasites and bacterial infections. Additionally, the skeleton of a billfish is designed for the buoyant conditions of the ocean, not air, meaning a removed billfish (even up to the gunnel) would cause the skeleton to come under the full strain of gravity, potentially damaging it and the internal organs.
    </p>
        `,
  },
  {
    question: "If I have billfish tagging photos, who do I send them to?",
    answer: `
    <p>
        TBF will always accept billfish photos, tagging photos, or you and the crew enjoying some time on the boat! Depending on the quality or our photo needs at the moment, we may even include the photos in our newsletters, magazines, or on social media. We will just need a confirmation from the photographer for use of the photo, as well as their name and/or online handle for proper attribution.

        <br />
        <br />

        For photos of recapture tags, we will send you a template of the information we need. This is done most easily via email at <a href="mailto:tag@billfish.org" target="_blank">tag@billfish.org</a> and selecting Submit a Recapture. Remember to remove the plastic film on any tags you recover from a billfish before sending us a photograph. This will make the tag number more visible.
    </p>
        `,
  },
];

const ABOUT_FAQ = [
  {
    question: "Why be a TBF member?",
    answer: `
    <p>
        Besides the prestige in general and knowing your contributions help ensure the conservation of both billfish and billfishing, only TBF members are eligible for awards in the Annual Tag & Release Competition. There are also a number of fun benefits depending on the membership you choose. From t-shirts and release certificates, to custom Yeti’s and lures, there’s a membership type for everyone.
    </p>
        `,
  },
  {
    question: "Why focus on the conservation of billfishing?",
    answer: `
    <p>
        In 1990, TBF recognized the need to expand beyond research and education due to unregulated billfishing. Efforts were expanded to include advocacy, pushing for responsible fisheries management. TBF recognized that influencing decision makers with sound science was a crucial step in successful billfish conservation.
    </p>
        `,
  },
  {
    question: "I reached out to TBF, but never heard back?",
    answer: `
    <p>
        We do our best to get back to everyone as quickly as possible, but if a conversation slips through the cracks, especially between all the platforms we use, please let us know. In general, if you don’t hear back from us within 48 hours, do not hesitate to reach out again. Emailing tag@billfish.com tends to be the most reliable method to get in touch with us.
    </p>
        `,
  },
  {
    question: "Marketing, advertisement or proposal pitches?",
    answer: `
    <p>
        Please submit all requests through our website at <a href="https://billfish.org/contact/" target="_blank">https://billfish.org/contact/</a> and we will get back to you as soon as we can.
    </p>
        `,
  },
  {
    question: "How can I get involved with the TBF magazine?",
    answer: `
    <p>
        We are always looking for content ideas for our magazines, whether it’s story ideas, photographs, etc. If you have something you think our readers would like to learn more about, send us the idea via email at <a href="mailto:tag@billfish.org" target="_blank">tag@billfish.org</a> and we will work with you to see if it’s a good fit.
    </p>
        `,
  },
  {
    question: "Can I become a sponsored representative for TBF?",
    answer: `
    <p>
        TBF doesn’t have official sponsored representatives, but we are always open to discussing potential options and/or helping individuals and clubs spread awareness about TBF and billfish conservation. If you work for a fishing club or a conservation group, or if you just want to spread news about billfish conservation, we’d be happy to send you some literature and/or some TBF swag to be used for outreach purposes.

        <br />
        <br />

        The best way to connect with TBF is to become a member. With that, you’ll be eligible for perks like our magazine, newsletter and much more. And definitely keep us posted with your TBF outreach progress as well. While we may not have the ability to collaborate at the moment, that doesn’t mean we won’t have opportunities down the line. We are always appreciative of anyone reaching out to help TBF continue its mission.
    </p>
        `,
  },
];

export const FAQ_LIST = [
  { name: "Login FAQs", faqs: LOGIN_FAQ, panel: [] },
  { name: "General FAQs", faqs: GENERAL_FAQ, panel: [] },
  { name: "Errors, Bugs, Glitches - FAQs", faqs: BUGS_FAQ, panel: [] },
  { name: "Competition FAQs", faqs: COMPETITION_FAQ, panel: [] },
  { name: "Data Entry FAQs", faqs: DATA_ENTRY_FAQ, panel: [] },
  { name: "Tagging/Research FAQs", faqs: RESEARCH_FAQ, panel: [] },
  { name: "About TBF FAQs", faqs: ABOUT_FAQ, panel: [] },
];
