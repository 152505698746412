<template>
  <v-card flat title>
    <v-card-title>
      <v-icon large>mdi-trophy-award</v-icon>
      <h3 class="ml-2">{{ $t("dashboard.competitions.title") }}</h3>
      <v-spacer />
      <v-btn icon @click="$emit('up')">
        <v-icon>mdi-chevron-up-circle</v-icon>
      </v-btn>
      <v-btn icon @click="$emit('down')">
        <v-icon>mdi-chevron-down-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-banner v-if="!!limitedMsg" color="light-blue lighten-5 px-5">
      <v-icon slot="icon" color="primary" size="36"> mdi-clock-alert </v-icon>
      {{ limitedMsg }}
    </v-banner>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loader.data"
      striped
      height="5"
    ></v-progress-linear>
    <v-tabs
      v-model="tab"
      fixed-tabs
      dark
      background-color="primary"
      v-if="competitions.length"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-for="item in competitions" :key="item.acronym">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-card-text v-if="competitions.length">
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="4" md="5">
          <v-autocomplete
            :label="$t('dashboard.competitions.category')"
            class="mb-2"
            :items="filteredCategories"
            :rules="rule"
            v-model="category"
            :open-on-clear="false"
            item-value="acronym"
            item-text="name"
            :loading="loader.category"
            :disabled="loader.category"
            @change="items = []"
            @click:clear="items = []"
            hide-details
            outlined
            :dense="full"
            clearable
          />
        </v-col>
      </v-row>
      <v-list v-if="items.length">
        <v-list-item v-for="(item, i) in items" :key="i" :disabled="limited">
          <v-list-item-icon v-if="item.category">
            <v-icon>mdi-anchor</v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <template>
              <v-chip class="ma-2" color="primary" :disabled="limited">
                {{ item.place }}
              </v-chip>
            </template>
          </v-list-item-icon>
          <v-list-item-content v-if="item.category">
            <v-list-item-title>
              {{ item.category }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title>
              {{ `${item.user.firstName} ${item.user.lastName}` }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ address(item.user) }}
            </v-list-item-subtitle>
            <v-divider />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "CompetitionPage",
  data: () => ({
    headers: Headers,
    items: [],
    loader: {
      data: false,
      competition: false,
      category: false,
    },
    competitions: [],
    competition: "TBFA",
    categories: [],
    category: "TTTA",
    rule: [(v) => !!v || "Required"],
    tab: 0,
    limitedMsg: null,
  }),
  watch: {
    tab: {
      handler: "getData",
    },
    category: {
      handler: "getData",
    },
  },
  async mounted() {
    await this.getCompetitions();
    await this.getCategoryList();
  },
  computed: {
    filteredCategories() {
      let result = [];

      switch (this.competition) {
        case "HW":
          result = this.categories
            ?.filter((item) => !String(item?.name).includes("BOAT"))
            ?.filter((item, i) => i < 4);
          break;

        case "SF":
          result = this.categories
            ?.filter((item) => !String(item?.name).includes("BOAT"))
            ?.filter((item, i) => i < 8);
          break;

        case "GM":
          result = this.categories
            ?.filter(
              (item) =>
                !String(item?.name).includes("RELEASE-TOP") ||
                !String(item?.name).includes("BOAT")
            )
            ?.filter((item, i) => i < 8);
          break;

        case "CV":
          result = [
            ...this.categories?.filter((item) =>
              String(item?.name).includes("BOAT")
            ),
            ...this.categories?.filter((item, i) => i < 4),
          ];
          break;

        default:
          result = this.categories?.filter(
            (item) => !String(item?.name).includes("BOAT")
          );
          break;
      }

      return result || [];
    },
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    limited() {
      const start = moment(`08-31-${moment().year()}`, "MM-DD-YYYY");
      const end = moment(`01-15-${moment().year() + 1}`, "MM-DD-YYYY");
      return moment().isAfter(start) && moment().isBefore(end);
    },
  },
  methods: {
    ...mapActions("dashboard", ["getCompetition", "getCategories"]),
    async getCompetitions() {
      this.loader.competition = true;
      const response = await this.getCompetition();
      this.competitions = response?.data?.docs || [];
      this.loader.competition = false;
    },
    async getCategoryList() {
      this.loader.category = true;
      const response = await this.getCategories();
      this.categories = response?.data?.docs || [];
      this.loader.category = false;
      this.categories.length > 0 && this.getData();
    },
    async getData() {
      if (this.competitions[this.tab]?.acronym && this.category) {
        this.loader.data = true;
        const response = await this.getCategories({
          competition: this.competitions[this.tab]?.acronym,
          category: this.category,
        });

        const { docs } = response?.data || {};
        this.limitedMsg = docs?.Message;
        const items = !this.limitedMsg ? docs : [];

        if (items instanceof Array) {
          this.items = this.rebuildList(items);
        } else {
          this.items = [];
          Object.keys(items)?.forEach((specie) => {
            const list = this.rebuildList(items[specie]);
            this.items.push({
              category: String(specie).toUpperCase(),
            });
            this.items.push(...list);
          });
        }

        this.loader.data = false;
      }
    },
    address(user) {
      const { city, state, country } = user?.address || {};
      let address = city || "";
      !!address.length && state && (address = address.concat(", "));
      state && (address = address.concat(state));
      !!address.length && country && (address = address.concat(", "));
      country && (address = address.concat(country));
      !address.length && (address = this.$t("noAddress"));
      return address;
    },
    getPlaces(index) {
      const place = index + 1;
      return place > 3
        ? `${place}th`
        : place === 3
        ? "3rd"
        : place === 2
        ? "2nd"
        : "1st";
    },
    rebuildList(list) {
      let place = 0;
      return (
        list
          ?.filter((doc) => !doc.disqualified)
          ?.map((doc) => {
            return {
              ...doc,
              place: this.getPlaces(place++),
            };
          }) || []
      );
    },
  },
};
</script>
