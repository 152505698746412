<template>
  <v-row class="text-center px-0 mt-1" :class="{ grid: login }">
    <v-chip
      :color="item.color"
      label
      v-for="(item, i) in items"
      :key="i"
      text-color="white"
      :class="
        vertical
          ? 'block gap justify-space-between'
          : 'block set-rect justify-center'
      "
      :small="full"
    >
      <span
        class="mr-2"
        v-html="vertical ? $t(`statistics.${item.key}`) : shortName($t(`statistics.${item.key}`))"
      />
      <v-chip
        :color="`${item.color} lighten-5`"
        :class="`${item.color}--text font-weight-bold`"
        :x-small="full"
        :small="!full"
      >
        {{ amountPerItems[item.key] || defaultAmount }}
      </v-chip>
    </v-chip>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "StatisticsSection",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    login: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [
      {
        color: "primary",
        icon: "mdi-tag-text",
        key: "tags",
      },
      {
        color: "primary",
        icon: "mdi-book-clock",
        key: "tagsLastYear",
      },
      {
        color: "green",
        icon: "mdi-fish",
        key: "releases",
      },
      {
        color: "green",
        icon: "mdi-book-clock",
        key: "releasesLastYear",
      },
      {
        color: "orange",
        icon: "mdi-fishbowl-outline",
        key: "recaptures",
      },
      {
        color: "orange",
        icon: "mdi-book-clock",
        key: "recapturesLastYear",
      },
      {
        color: "red",
        icon: "mdi-account-group",
        key: "users",
      },
    ],
    show: false,
    defaultAmount: 0,
    amountPerItems: {},
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  async mounted() {
    await this.getData();
  },
  watch: {
    open: {
      handler: "launch",
      immediate: true,
    },
  },
  methods: {
    ...mapActions("dashboard", ["getStatistics"]),
    async getData() {
      this.$emit("loading", true);
      const response = await this.getStatistics();
      this.$emit("loading", false);
      const { docs } = response?.data || {};
      if (!docs) {
        return;
      }
      this.amountPerItems = Array.isArray(docs) ? this.migrateData(docs) : docs;
    },
    migrateData(array = []) {
      if (!array?.length) {
        return {};
      }

      const data = {};
      array.forEach((item) => {
        Object.keys(item).forEach((key) => {
          data[key] = item[key];
        });
      });

      return data;
    },
    launch(value) {
      value && (this.show = value);
    },
    shortName(value = "") {
      if (!value) return value;
      const array = String(value).split(" ");
      if (!array?.length) return value;
      return String(value).slice(array[0].length + 1, value.length);
    },
  },
};
</script>

<style>
.set-rect {
  border-radius: 0px !important;
}

.block {
  flex-grow: 1;
}

.gap {
  margin-bottom: 5px;
}

.gap span[class~="v-chip__content"] {
  display: contents !important;
}

.grid {
  display: grid;
}
</style>
