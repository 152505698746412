import { handleCall } from "@/handlers/index";
import { getUserInfo } from "../../handlers";

class EntryModule {
  constructor(httpClientFactory) {
    const entryAPI = httpClientFactory.clients.entries;

    this._store = {
      state: { data: {} },
      getters: {},
      mutations: {
        refreshState(state, tag) {
          state.data = Object.assign(state.data, tag);
        },
        resetState(state) {
          //Reset state data using initial model import
          state.data = {};
        },
      },
      actions: {
        addTag({ state }, params) {
          const user = getUserInfo();
          const path = `tags?userId=${user?.id}`;
          return handleCall("POST", entryAPI, path, params);
        },
        addRecapture({ state }, params) {
          const user = getUserInfo();
          const path = `recaptures?userId=${user?.id}`;
          return handleCall("POST", entryAPI, path, params);
        },
        addRelease({ state }, params) {
          const user = getUserInfo();
          const path = `releases?userId=${user?.id}`;
          return handleCall("POST", entryAPI, path, params);
        },
        editTag({ state }, params) {
          const path = `tags/${params?._id || params?.id}`;
          return handleCall("PATCH", entryAPI, path, params);
        },
        editRecapture({ state }, params) {
          const path = `recaptures/${params?._id || params?.id}`;
          return handleCall("PATCH", entryAPI, path, params);
        },
        editRelease({ state }, params) {
          const path = `releases/${params?._id || params?.id}`;
          return handleCall("PATCH", entryAPI, path, params);
        },
        getJuvenile({ state }, params) {
          let path = "juvenile-billfish";
          const { id } = params;
          if (id) {
            path = path.concat(`?id=${id}`);
          }
          return handleCall("GET", entryAPI, path);
        },
        addJuvenile({ state }, params) {
          const user = getUserInfo();
          const path = `juvenile-billfish`;
          return handleCall("POST", entryAPI, path, params);
        },
        editJuvenile({ state }, params) {
          const { id, _id } = params;
          const path = `juvenile-billfish/${_id || id}`;
          return handleCall("PATCH", entryAPI, path, params);
        },
        deleteJuvenile({ state }, params) {
          const { id, _id } = params;
          const path = `juvenile-billfish/${_id || id}`;
          return handleCall("DELETE", entryAPI, path);
        },
        addItem({ state }, params) {
          return handleCall("POST", entryAPI, params.item, params.data);
        },
        checkEvent({ state }, params) {
          const user = getUserInfo();
          const path = `${params.event}/check?userId=${user?.id}`;
          return handleCall("POST", entryAPI, path, params.payload);
        },
        getAvatar({ state }, params = {}) {
          const user = getUserInfo();
          const path = `images/${user?.id}`;
          return handleCall("GET", entryAPI, path);
        },
        uploadAvatar({ state }, params = {}) {
          const user = getUserInfo();
          const payload = {
            filename: user?.id,
            image: params,
          };
          return handleCall("POST", entryAPI, "images", payload);
        },
        getFishPicture({ state }, params = {}) {
          const path = `images/${params?.id}`;
          return handleCall("GET", entryAPI, path);
        },
        uploadFishPicture({ state }, params = {}) {
          const { id, image } = params;
          const payload = { filename: id, image };
          return handleCall("POST", entryAPI, "images", payload);
        },
        deleteAvatar({ state }, params) {
          const user = getUserInfo();
          const path = `images/${user?.id}`;
          return handleCall("DELETE", entryAPI, path);
        },
        saveLocation({ state }, params = {}) {
          const path = `locations/`;
          return handleCall("POST", entryAPI, path, params);
        },
        deleteTag({ state }, params) {
          const path = `/tags/${params?.id}`;
          return handleCall("DELETE", entryAPI, path);
        },
        deleteRelease({ state }, params) {
          const path = `/releases/${params?.id}`;
          return handleCall("DELETE", entryAPI, path);
        },
        deleteRecapture({ state }, params) {
          const path = `/recaptures/${params?.id}`;
          return handleCall("DELETE", entryAPI, path);
        },
        submitReward({ state }, params = {}) {
          return handleCall("POST", entryAPI, "reward-settings", params);
        },
      },
      namespaced: true,
    };
  }

  get store() {
    return this._store;
  }
}

export default EntryModule;
