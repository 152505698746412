import Vue from 'vue';
import routes from './routes';
import Router from 'vue-router';
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';

Vue.use(Router);
const router = new Router({
    base: '/',
    mode: 'history',
    linkActiveClass: 'nav-item active',
    routes: routes
});

// router guards
router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach((to, from) => {
    // ...
    NProgress.done();
});

export default router;