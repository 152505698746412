<template>
  <v-form ref="coordsForm">
    <v-row class="my-2 pb-2">
      <v-col cols="12" md="6">
        <h3 class="font-weight-medium">Latitude</h3>
        <v-row class="pt-1">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="input.latitude.degrees"
              :label="$t('form.degrees')"
              :rules="rule"
              type="number"
              min="0"
              max="90"
              hide-details
              outlined
              :dense="full"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="latMinutes"
              :label="$t('form.minutes')"
              :rules="rule"
              type="number"
              min="0"
              max="60"
              step="0.01"
              hide-details
              outlined
              :dense="full"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              :label="$t('form.hemisphere')"
              v-model="input.latitude.hemisphere"
              :items="latHemisphere"
              :rules="rule"
              hide-details
              outlined
              :dense="full"
              clearable
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="font-weight-medium">Longitude</h3>
        <v-row class="pt-1">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="input.longitude.degrees"
              :label="$t('form.degrees')"
              :rules="rule"
              type="number"
              min="0"
              max="180"
              hide-details
              outlined
              :dense="full"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="lngMinutes"
              :label="$t('form.minutes')"
              :rules="rule"
              type="number"
              min="0"
              max="60"
              step="0.01"
              hide-details
              outlined
              :dense="full"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              :label="$t('form.hemisphere')"
              v-model="input.longitude.hemisphere"
              :items="longHemisphere"
              :rules="rule"
              hide-details
              outlined
              :dense="full"
              clearable
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col>
        <v-row class="justify-center">
          <span class="font-weight-light caption">
            {{ $t("form.setMarkerHint") }}
          </span>
        </v-row>
        <v-row class="justify-center">
          <v-btn small color="primary" @click="updateMap" :x-large="!full">
            {{ $t("form.setMarker") }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { LATITUDE_HEMISPHERE, LONGITUDE_HEMISPHERE } from "../../handlers";
import { CoordinatesViewModel } from "../models";
export default {
  name: "coordinates-form",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    input: new CoordinatesViewModel().coordinates,
    latHemisphere: LATITUDE_HEMISPHERE,
    longHemisphere: LONGITUDE_HEMISPHERE,
  }),
  computed: {
    latMinutes: {
      get: function () {
        const min = this.input.latitude.minutes;
        const decimals = this.countDecimals(Number(min));
        return min !== null && decimals > 2 ? Number(min).toFixed(2) : min;
      },
      set: function (value) {
        this.input.latitude.minutes = value;
      },
    },
    lngMinutes: {
      get: function () {
        const min = this.input.longitude.minutes;
        const decimals = this.countDecimals(Number(min));
        return min !== null && decimals > 2 ? Number(min).toFixed(2) : min;
      },
      set: function (value) {
        this.input.longitude.minutes = value;
      },
    },
    rule() {
      return [
        (v) => !(v === null || v === undefined) || this.$t("form.required"),
      ];
    },
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    updateMap() {
      const { longitude, latitude } = this.input || {};
      const valid =
        Object.values(longitude || {})?.every((v) => v) &&
        Object.values(latitude || {})?.every((v) => v);
      if (valid) {
        this.$emit("change", this.input);
      } else {
        this.$refs.coordsForm && this.$refs.coordsForm.validate();
      }
    },
    updateForm(value) {
      this.input = { ...value };
      this.$refs.coordsForm && this.$refs.coordsForm.resetValidation();
    },
    validate() {
      return this.$refs.coordsForm && this.$refs.coordsForm.validate();
    },
    countDecimals(value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1]?.length || 0;
    },
  },
  watch: {
    value: {
      handler: "updateForm",
    },
  },
};
</script>
