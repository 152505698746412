import { render, staticRenderFns } from "./TrophyRoom.vue?vue&type=template&id=54cb5c50&scoped=true"
import script from "./TrophyRoom.vue?vue&type=script&lang=js"
export * from "./TrophyRoom.vue?vue&type=script&lang=js"
import style0 from "./TrophyRoom.vue?vue&type=style&index=0&id=54cb5c50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54cb5c50",
  null
  
)

export default component.exports