<template>
  <v-container class="fill-height justify-center">
    <v-snackbar v-model="notify" :timeout="5000" top>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="notify = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="center">
      <v-col md="8" lg="6">
        <v-card class="elevation-4">
          <div class="pa-7 pa-sm-12 text-center">
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              The Billfish Foundation Tag & Release Application
            </h2>
            <img :src="defaultAvatar" alt="Billfish" />
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              {{ $t("resetPwd.title") }}
            </h2>
            <h6 class="subtitle-1 mb-5">
              {{ $t("resetPwd.subtitle") }}
            </h6>
            <v-form ref="form" @submit="send">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('resetPwd.password')"
                    v-model="payload.password"
                    :rules="rule.concat(strengthRule)"
                    type="password"
                    outlined
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('resetPwd.confirm')"
                    v-model="confirmPwd"
                    :rules="rule.concat(match)"
                    type="password"
                    outlined
                    clearable
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-btn color="primary" @click="send">
              {{ $t("submit") }}
            </v-btn>
            <v-spacer />
            <v-btn class="mt-5" depressed @click="goBack">
              {{ $t("cancel") }}
            </v-btn>
            <v-row justify="end" align="center" class="mt-5">
              <v-col cols="8" align-self="center" class="text-start">
                <span>
                  &copy;
                  <span v-text="` - The Billfish Foundation - ${year}`" />
                </span>
              </v-col>
              <v-spacer />
              <v-col>
                <a href="privacy" target="_blank" class="link">
                  {{ $t("privacy") }}
                </a>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { defaultAvatar } from "../../handlers";
export default {
  name: "reset-password",
  data: () => ({
    overlay: false,
    confirmPwd: null,
    payload: {
      email: null,
      password: null,
      token: null,
    },
    notify: false,
    message: null,
  }),
  mounted() {
    localStorage.removeItem("tempReset");
    const { token, email } = this.$route?.query;
    if (token && email) {
      localStorage.setItem("tempReset", JSON.stringify({ token, email }));
      this.$router.replace({ query: null });
    }
  },
  computed: {
    match() {
      return () =>
        this.payload.password === this.confirmPwd ||
        this.$t("resetPwd.mismatch");
    },
    year() {
      return new Date().getFullYear();
    },
    defaultAvatar() {
      return defaultAvatar;
    },
    rule() {
      return [(v) => !!v || this.$t("resetPwd.required")];
    },
    strengthRule() {
      return [
        (v) =>
          /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})/.test(v) ||
          this.$t("resetPwd.strengthRule"),
      ];
    },
  },
  methods: {
    ...mapActions("users", ["resetPwd"]),
    async send() {
      if (this.$refs.form.validate()) {
        const { token, email } = JSON.parse(localStorage.getItem("tempReset"));
        this.payload.token = token;
        this.payload.email = email;
        this.overlay = true;
        const response = await this.resetPwd(this.payload);
        this.overlay = false;
        this.notify = true;
        if (response?.data?.success) {
          this.message = response.data.message;
          localStorage.removeItem("tempReset");
          this.$router.push({ path: "login" });
        } else {
          this.message = this.$t("failed");
        }
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
img {
  background-color: dodgerblue;
  border-radius: 10px;
}
</style>
