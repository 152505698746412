<template>
  <v-dialog
    v-model="preview"
    max-width="1100px"
    scrollable
    :fullscreen="full"
    persistent
  >
    <v-card>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> {{ title }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="getScreenshot"
          :loading="loading"
          rounded
          color="white"
          class="primary--text"
        >
          Get Screenshot
        </v-btn>
      </v-toolbar>
      <v-card-text ref="eventContent">
        <v-row>
          <v-col cols="12" md="6">
            <v-list dense>
              <v-list-item v-if="!isRelease">
                <v-list-item-content v-if="isRecapture">
                  <v-list-item-title> PRIOR TAG NUMBER </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.priorTag.priorTagNumber || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ `${isRecapture ? "NEW " : ""}TAG NUMBER` }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.number || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> {{ `${title} DATE` }} </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ format(data.enteredDate) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> OCEAN </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.capture.ocean || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> SPECIES </v-list-item-title>
                  <span v-if="isRelease">
                    <v-list-item-subtitle
                      v-for="fish in data.capture.fish"
                      :key="fish.name"
                    >
                      {{ `(${fish.qty}) ${fish.name}` }}
                    </v-list-item-subtitle>
                  </span>
                  <v-list-item-subtitle v-else>
                    {{ data.capture.fish.specie.name || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> ANGLER </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getUserName(data.angler) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> CAPTAIN </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getUserName(data.captain) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> FIRST MATE </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getUserName(data.firstMate) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> SECOND MATE </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getUserName(data.secondMate) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title> LOCATION </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.locality || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> SHARK ENCOUNTER </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.sharkEncounter ? "YES" : "NO" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!isRelease">
                <v-list-item-content>
                  <v-list-item-title> FISH LENGTH </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      getFishMetrics(data.capture.fish.length) || defaultValue
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> FISH WEIGHT </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      getFishMetrics(data.capture.fish.weight) || defaultValue
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> FIGHT TIME </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getFightTime(data.capture.fish.fight) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!isRelease">
                <v-list-item-content>
                  <v-list-item-title> FISH CONDITION </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.capture.fish.condition.name || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> GEAR TYPE </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.capture.gear.name || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> TOURNAMENT </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.capture.tournament.name || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> BAIT TYPE </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.capture.bait.name || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> HOOK TYPE </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.capture.hook.name || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> BOAT </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.boatName || data.boat.name || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="isRelease">
                <v-list-item-content>
                  <v-list-item-title> LINES IN </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.relLines || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> LINES OUT </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.relLines0 || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title> TOTAL LINES </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.relNumLi || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="6">
            <fishing-locations
              ref="location"
              class="mt-5"
              :fabPosition="false"
              :fabNavigation="false"
              :input="data"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-list dense>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title> NOTES </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.capture.notes || defaultValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FishingLocations from "@/views/Dashboard/FishingLocations.vue";
import * as html2canvas from "html2canvas";
import { getImg, getEventType, NOT_SPECIFIED, formatDate } from "@/handlers";
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel,
} from "@/views/models";
export default {
  components: { FishingLocations },
  name: "event-preview",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    preview: false,
    loading: false,
    title: "TAG",
    data: window.structuredClone(initData),
    defaultValue: NOT_SPECIFIED,
  }),
  mounted() {},
  watch: {
    value: {
      handler: "updateData",
    },
  },
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isRelease() {
      return this.title === "RELEASE";
    },
    isRecapture() {
      return this.title === "RECAPTURE";
    },
  },
  methods: {
    updateData(data) {
      if (!data) {
        this.preview = false;
        return;
      }
      this.title = getEventType(this.data?.isTagOrRelease) || "TAG";
      this.preview = true;
      setTimeout(() => {
        this.data = window.structuredClone({ ...initData, ...data });
      }, 100);
    },
    async getScreenshot() {
      this.loading = true;
      try {
        if (this.$refs.eventContent) {
          const output = await html2canvas(this.$refs.eventContent, {
            useCORS: true,
            onrendered: function (canvas) {
              document.body.appendChild(canvas);
            },
          });
          if (output) {
            const url = output.toDataURL();
            const number = this.data?.number;
            const prior = this.data?.priorTag?.priorTagNumber;
            const title = this.isRecapture
              ? `PriorTagNumber_${prior}`
              : `Number_${number}`;
            getImg(url, this.isRelease ? this.title : `${this.title}_${title}`);
          }
        }
      } catch (error) {
        // console.log(error);
      }
      this.loading = false;
    },
    getUserName(name) {
      const { firstName, lastName } = name || {};
      return firstName && lastName
        ? `${firstName} ${lastName}`
        : firstName || lastName || NOT_SPECIFIED;
    },
    format(value) {
      return formatDate(value);
    },
    getFightTime(time) {
      const { hours, minutes } = time || {};
      return hours ? `${hours}:${minutes || "00"}` : minutes || NOT_SPECIFIED;
    },
    getCoordinates(coordinates) {
      const { degrees, minutes, hemisphere } = coordinates || {};
      return degrees || minutes || hemisphere
        ? `${Number(degrees).toFixed(0)}º ${Number(minutes).toFixed(
            0
          )}' ${hemisphere}`
        : null;
    },
    getFishMetrics(metric) {
      const { value, type } = metric || {};
      return type ? `${value || 0} ${type}` : value || NOT_SPECIFIED;
    },
  },
};

const initData = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
  ...{ boatName: null },
};
</script>
