<template>
  <v-footer app class="footerpart px-5" inset>
    <span
      >© {{ new Date().getFullYear() }} — The Billfish Foundation |
      {{ $t("footer.rights") }}</span
    >
    <v-spacer />
    <v-speed-dial
      v-model="statistics"
      direction="top"
      transition="slide-x-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn text :loading="loading" class="font-weight-black">
          <v-icon left>mdi-chart-box</v-icon>
          {{ $t("statistics.title") }}
        </v-btn>
      </template>
      <template v-slot:default>
        <statistics open vertical @loading="(value) => (loading = value)" />
      </template>
    </v-speed-dial>
    <v-speed-dial v-model="fab" direction="top" open-on-hover>
      <template v-slot:activator>
        <v-btn text v-model="fab">
          <v-icon left>mdi-share-variant</v-icon>
          {{ $t("footer.findUs") }}
        </v-btn>
      </template>
      <v-btn
        fab
        v-for="(item, i) in social"
        :key="i"
        :href="item.link"
        :color="item.color"
        target="_blank"
      >
        <v-icon> {{ `mdi-${item.icon}` }} </v-icon>
      </v-btn>
    </v-speed-dial>
  </v-footer>
</template>
<script>
import Statistics from "../../../views/Dashboard/Statistics.vue";
export default {
  components: { Statistics },
  name: "FooterSection",
  data: () => ({
    fab: false,
    statistics: false,
    loading: false,
    social: [
      {
        icon: "facebook",
        link: "https://www.facebook.com/BillfishFoundation",
        color: "indigo white--text",
      },
      {
        icon: "twitter",
        link: "https://twitter.com/TagBillfish",
        color: "primary lighten-3",
      },
      {
        icon: "instagram",
        link: "https://www.instagram.com/thebillfishfoundation/",
        color: "purple white--text",
      },
      {
        icon: "linkedin",
        link: "https://www.linkedin.com/company/10665762",
        color: "primary",
      },
    ],
  }),
};
</script>
<style lang="scss">
.v-application .footerpart {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.theme--light.v-footer {
  background: white;
}
</style>
