<template>
  <v-dialog v-model="dialog" max-width="290" persistent>
    <v-card :loading="loading">
      <v-img height="250" :src="srcImg" :lazy-src="defaultAvatar">
        <v-btn
          fab
          top
          right
          color="gray"
          small
          dark
          class="mt-9"
          absolute
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          fab
          bottom
          right
          color="red"
          small
          dark
          class="mb-9"
          absolute
          @click="$emit('input', null)"
          v-if="showDelete && newRecord"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              bottom
              left
              color="indigo"
              small
              dark
              class="mb-9"
              absolute
              v-bind="attrs"
              v-on="on"
              @click="$refs.uploader.click()"
            >
              <v-icon v-if="value">mdi-image-sync</v-icon>
              <v-icon v-else>mdi-cloud-upload</v-icon>
            </v-btn>
          </template>
          <span v-if="value" v-html="$t('replace')" />
          <span v-else v-html="$t('load')" />
        </v-tooltip>
      </v-img>
      <v-divider />

      <v-card-actions>
        <v-btn color="indigo" dark @click="$emit('close')" block>
          {{ $t("apply") }}
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="image/*"
          @change="onFileChanged"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getBase64, defaultAvatar } from "../../handlers";
export default {
  name: "image-uploader",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object],
      default: () => defaultAvatar,
    },
    newRecord: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    dialog: false,
    image: null,
  }),
  watch: {
    show: {
      handler: "showDialog",
    },
  },
  computed: {
    srcImg() {
      let image = this.value;
      if (image && typeof image !== "string") {
        const { mime, data } = image || {};
        image = mime && data && `data:${mime};base64,${data}`;
      }
      return image || defaultAvatar;
    },
    showDelete() {
      return this.srcImg !== defaultAvatar;
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  methods: {
    showDialog(value) {
      this.dialog = value;
    },
    async onFileChanged(e) {
      const file = e?.target?.files[0];
      if (file) {
        const mime = file?.type;
        const image = await getBase64(file);
        const data = String(image).split(",")[1];
        this.$emit("input", { mime, data });
      }
    },
  },
};
</script>
