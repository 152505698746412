<template>
  <v-dialog
    v-model="onRecord"
    max-width="1080px"
    scrollable
    :fullscreen="full"
    persistent
  >
    <v-card title>
      <v-snackbar v-model="notify" :timeout="3000" top>
        {{ message }}
      </v-snackbar>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="showWarning" persistent max-width="290">
        <v-card>
          <v-card-title />
          <v-card-text>
            {{ $t("recordFish.warning") }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="focusNotes"> No </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showConfirmation">
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn icon dark @click="onRecord = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{
            newRecord
              ? $t("batchReview.addRecords")
              : $t("batchReview.editRecords")
          }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text ref="contentCardBatch">
        <release-form ref="releaseForm" v-if="isRelease" :step="coordsStep" />
        <tag-form
          ref="tagForm"
          :isRecapture="isRecapture"
          :step="coordsStep"
          v-else
        />
      </v-card-text>
      <v-toolbar flat dark color="primary">
        <v-btn
          @click="coordsStep = true"
          color="white"
          class="primary--text"
          style="min-width: 164px"
          v-if="!coordsStep"
        >
          {{ $t("back") }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="nextStep"
          color="white"
          class="primary--text"
          style="min-width: 164px"
          v-if="coordsStep"
        >
          {{ $t("next") }}
        </v-btn>

        <div v-else>
          <v-btn
            color="white"
            class="primary--text mr-5"
            style="min-width: 164px"
            v-if="newRecord"
            @click="addBatch(false)"
            :disabled="loading"
          >
            {{ $t("batchReview.addMore") }}
          </v-btn>
          <v-btn
            color="white"
            class="primary--text mr-5"
            style="min-width: 164px"
            @click="submit"
            :disabled="loading"
          >
            {{ newRecord ? $t("finish") : $t("save") }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { cloneObject, getUserInfo } from "../../handlers";
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel,
} from "../models";
import _ from "lodash";
import TagForm from "./TagForm.vue";
import ReleaseForm from "./ReleaseForm.vue";
export default {
  components: { TagForm, ReleaseForm },
  name: "batch-review",
  props: {
    batch: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    data: cloneObject(initInput),
    notify: false,
    message: "",
    onRecord: false,
    type: "tags",
    newRecord: false,
    loading: false,
    index: null,
    eventType: null,
    showWarning: false,
    record: null,
    finish: false,
    coordsStep: true,
  }),
  mounted() {},
  computed: {
    title() {
      if (this.eventType) {
        switch (this.eventType) {
          case "R":
            return this.getUpperCaseSingular(this.$t("releases"));

          case "C":
            return this.getUpperCaseSingular(this.$t("recaptures"));

          case "T":
          default:
            return this.getUpperCaseSingular(this.$t("tags"));
        }
      }

      return this.getUpperCaseSingular(this.$t(this.type));
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isRelease() {
      return this.title === this.getUpperCaseSingular(this.$t("releases"));
    },
    isRecapture() {
      return this.title === this.getUpperCaseSingular(this.$t("recaptures"));
    },
  },
  methods: {
    ...mapActions("entry", ["addTag", "checkEvent"]),
    getUpperCaseSingular(str = "") {
      if (!str) return "";
      return str.toLowerCase() === "devoluciones"
        ? "DEVOLUCIÓN"
        : str.substring(0, str.length - 1).toUpperCase();
    },
    init(record, type = "tags") {
      this.onRecord = true;
      this.coordsStep = true;
      type && (this.type = String(type).toLowerCase());
      this.newRecord = !record;
      if (record?.index !== undefined) {
        this.index = record.index;
        delete record.index;
      }

      if (!this.newRecord) {
        this.eventType = record?.isTagOrRelease;
        this.havePriorTag = record?.priorTag?.priorTagNumber;
        // this.$refs.releaseForm.resetAll();
      }
      setTimeout(() => {
        this.$refs.contentCardBatch &&
          (this.$refs.contentCardBatch.scrollTop = 0);
        if (this.isRelease) {
          // this.$refs.releaseForm.resetAll();
          this.$refs.releaseForm && this.$refs.releaseForm.initRecord(record);
        } else {
          // this.$refs.tagForm.resetAll();
          this.$refs.tagForm && this.$refs.tagForm.initRecord(record);
        }
      }, 50);
    },
    getData() {
      let input = {};
      let emptyNote = false;
      if (this.isRelease) {
        input = this.$refs.releaseForm.submit();
        emptyNote = !input?.data?.capture?.notes;
      } else {
        input = this.$refs.tagForm.submit();
      }

      const user = getUserInfo();

      let record = {
        ...cloneObject(initInput),
        ...cloneObject(input?.data),
        updatedBy: user?.email,
      };

      if (this.newRecord) {
        record = { ...record, enteredBy: user?.email };
      } else if (Object.keys(record).some((key) => key === "enteredBy")) {
        delete record.enteredBy;
      }

      if (input?.valid) {
        this.showWarning = emptyNote;
        this.record = record;
        return true;
      } else {
        this.notify = true;
        this.message = this.$t("recordFish.needFulfill");
        return false;
      }
    },
    async findDuplicate(data) {
      const event = String(this.type).toLowerCase();
      const response = await this.checkEvent({
        event,
        payload: data,
      });
      return !!response?.data?.docs;
    },
    async addBatch() {
      this.loading = true;
      this.$refs.contentCardBatch &&
        (this.$refs.contentCardBatch.scrollTop = 0);

      const valid = this.getData();
      if (this.showWarning || !valid) {
        this.loading = false;
        return;
      }

      await this.submitRecord();
      this.loading = false;
    },
    async submitRecord() {
      const success = await this.checkIssues(this.record);

      if (success) {
        if (this.index !== null) {
          this.$emit("save", { ...this.record, index: this.index });
          this.index = null;
        } else {
          this.$emit("done", this.record);
          this.message = this.$t("recordFish.addedForReview");
          this.notify = true;
        }

        if (this.isRelease) {
          this.finish
            ? this.$refs.releaseForm.resetAll()
            : this.$refs.releaseForm.resetForBatch();
        } else {
          this.finish
            ? this.$refs.tagForm.resetAll()
            : this.$refs.tagForm.resetForBatch();
        }

        if (this.finish) {
          this.onRecord = false;
        } else {
          this.coordsStep = true;
        }
      }
    },
    async submit() {
      this.loading = true;
      this.finish = true;
      const valid = this.getData();

      if (this.showWarning || !valid) {
        this.loading = false;
        return;
      }
      await this.submitRecord();
      this.finish = false;
      this.loading = false;
    },
    async checkIssues(data) {
      if (_.isEqual(data, initInput)) {
        this.message = this.$t("recordFish.needFulfill");
        this.notify = true;
        this.loading = false;
        return false;
      }

      const existsInBatch =
        data &&
        this.newRecord &&
        this.batch?.some((record) => record.number === data?.number);

      const duplicated = !existsInBatch
        ? data
          ? await this.findDuplicate(data)
          : false
        : existsInBatch;

      if (duplicated) {
        this.notify = true;
        this.message = this.isRelease
          ? this.$t("recordFish.releaseExists")
          : this.$t("recordFish.tagExists");
        this.loading = false;
        return false;
      }

      return true;
    },
    focusNotes() {
      this.showWarning = false;
      this.$refs.releaseForm && this.$refs.releaseForm.focusNotes();
    },
    async showConfirmation() {
      this.showWarning = false;
      this.data && (await this.submitRecord());
    },
    nextStep() {
      if (this.isRelease) {
        const valid =
          this.$refs.releaseForm &&
          this.$refs.releaseForm.validateCoordinates();

        if (!valid) {
          this.notify = true;
          this.message = this.$t("recordFish.needFulfill");
          return;
        }
      } else {
        const valid =
          this.$refs.tagForm && this.$refs.tagForm.validateCoordinates();

        if (!valid) {
          this.notify = true;
          this.message = this.$t("recordFish.needFulfill");
          return;
        }
      }

      this.coordsStep = false;
    },
  },
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
};
</script>
