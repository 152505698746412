<template>
  <v-dialog v-model="show" fullscreen transition="dialog-bottom-transition">
    <v-card class="container">
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <v-icon class="mr-2">mdi-frequently-asked-questions</v-icon>
          {{ $t("sidebar.faqs") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="close">
            {{ $t("close") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div v-for="item in items" :key="item.name" class="mt-5 mb-2 mx-5">
        <p>{{ item.name }}</p>
        <v-divider />
        <v-expansion-panels multiple v-model="item.panel">
          <v-expansion-panel v-for="(faq, i) in item.faqs" :key="i">
            <v-expansion-panel-header class="font-weight-bold">
              {{ faq.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-5">
              <span v-html="faq.answer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { FAQ_LIST } from "./FAQs";
export default {
  name: "FrequentAskedQuestions",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: FAQ_LIST,
  }),
  computed: {
    show() {
      return this.open;
    },
  },
  methods: {
    close() {
      this.items.forEach((item) => (item.panel = []));
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.container {
  box-shadow: none !important;
  border: none !important;
}
</style>
