<template>
  <v-dialog
    v-model="open"
    max-width="750px"
    scrollable
    :fullscreen="full"
    persistent
  >
    <v-card>
      <v-snackbar v-model="notify" :timeout="3000" top>
        {{ message }}
      </v-snackbar>
      <v-dialog v-model="openConfirmationDialog" max-width="290">
        <v-card>
          <v-card-title class="headline">
            {{ $t("rewardSolicitation.confirmationTitle") }}
          </v-card-title>
          <v-card-text>
            {{ $t("rewardSolicitation.confirmationSubtitle") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="removeRecipient()">
              {{ $t("rewardSolicitation.confirmationOk") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="
                panels.splice(recipientToBeRemoved - 1, 1);
                recipientToBeRemoved = 0;
              "
            >
              {{ $t("rewardSolicitation.confirmationCancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t("rewardSolicitation.title") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="white"
          class="text--primary"
          @click="submit"
          :loading="loading"
          :disabled="loading || !allReady || !recipientCount"
        >
          {{ $t("submit") }}
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-banner> {{ $t("rewardSolicitation.tbfMsg") }} </v-banner>
          </v-col>

          <transition name="fade">
            <v-col cols="12" v-if="showSingleLocation">
              <v-row no-gutters>
                <v-checkbox
                  v-model="singleLocation"
                  :label="$t('rewardSolicitation.singleLocationTitle')"
                  hide-details
                  class="mt-0 shrink"
                >
                </v-checkbox>
                <v-tooltip max-width="640" bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-variant-circle
                    </v-icon>
                  </template>
                  <span class="caption">
                    {{ $t("rewardSolicitation.singleLocationTooltip") }}
                  </span>
                </v-tooltip>
              </v-row>
            </v-col>
          </transition>

          <v-fade-transition>
            <v-col cols="12" v-if="singleLocation">
              <v-textarea
                auto-grow
                rows="1"
                :label="$t('rewardSolicitation.singleLocationLabel')"
                v-model="rewardNote"
                outlined
                clearable
                dense
                counter
                :rules="addressRule"
              />
            </v-col>
          </v-fade-transition>

          <transition name="fade">
            <v-col cols="12" v-if="!recipientCount">
              <v-banner color="amber lighten-5">
                <v-icon slot="icon" color="warning" size="36">
                  mdi-alert
                </v-icon>
                <h5>{{ $t("rewardSolicitation.recipientCountWarning") }}</h5>
              </v-banner>
            </v-col>
          </transition>

          <v-col cols="12" v-if="this.rewards.length">
            <v-expansion-panels v-model="panels" multiple>
              <v-expansion-panel
                v-for="(item, i) of rewards"
                :key="i"
                :readonly="!item.enabled"
                ref="rewardPanel"
              >
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <v-row no-gutters class="align-center">
                      <v-col class="row align-center">
                        <v-checkbox
                          hide-details
                          class="shrink mt-0"
                          v-model="item.enabled"
                          @click.stop
                          @change="(v) => askRemove(i, v)"
                          :disabled="!item.name"
                        ></v-checkbox>
                        <span v-if="!item.isNew">
                          {{ item.name }}
                        </span>
                        <v-text-field
                          v-else
                          v-model="item.name"
                          dense
                          hide-details
                          :rules="[(v) => !!v || 'Required']"
                          @click.stop
                          @keyup.prevent="onNameTyping(i)"
                        ></v-text-field>
                      </v-col>
                      <v-col class="text--secondary text-right pr-2">
                        <v-fade-transition leave-absolute>
                          <span v-if="item.enabled">
                            <span v-if="open" class="primary--text">
                              {{ $t("rewardSolicitation.recipientSubmit") }}
                            </span>
                            <span v-else>
                              <span v-if="item.ready">
                                {{
                                  $t("rewardSolicitation.recipientSubmitted")
                                }}
                              </span>
                              <span v-else>
                                {{ $t("rewardSolicitation.recipientAction") }}
                              </span>
                            </span>
                          </span>
                          <span v-else>
                            <span v-if="item.name">
                              {{ $t("rewardSolicitation.recipientDiscarded") }}
                            </span>
                            <span v-else>
                              {{ $t("rewardSolicitation.recipientAddName") }}
                            </span>
                          </span>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:actions="{ open }">
                    <v-icon color="primary" v-if="item.enabled && open">
                      $expand
                    </v-icon>
                    <v-icon
                      color="green"
                      v-if="item.ready && !open && item.enabled"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      color="error"
                      v-if="!item.ready && !open && item.enabled"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                    <v-tooltip
                      max-width="320"
                      bottom
                      color="primary"
                      v-if="!item.enabled && Boolean(item.name) && !open"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on">
                          mdi-information-variant-circle
                        </v-icon>
                      </template>
                      <span class="caption">
                        {{ $t("rewardSolicitation.recipientHint") }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form ref="rewardForm">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <p class="mb-0">
                          {{ $t("rewardSolicitation.rewardNotification") }}
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <v-radio-group
                          v-model="item.optionSelected"
                          class="mt-0"
                          hide-details
                        >
                          <v-radio
                            v-for="option in options"
                            :key="option.label"
                            :label="option.label"
                            :value="option.value"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-fade-transition>
                        <div
                          class="flex"
                          v-if="
                            item.optionSelected === 'both' ||
                            item.optionSelected === 'no-certificate'
                          "
                        >
                          <v-col cols="12" class="pb-0">
                            <p class="mb-0">
                              {{ $t("rewardSolicitation.shirtSizeQuestion") }}
                            </p>
                          </v-col>

                          <v-col
                            cols="12"
                            md="3"
                            sm="4"
                            v-if="
                              item.optionSelected === 'both' ||
                              item.optionSelected === 'no-certificate'
                            "
                          >
                            <v-autocomplete
                              :items="['XS', 'S', 'M', 'L', 'XL', 'XXL']"
                              :label="$t('rewardSolicitation.shirtSize')"
                              v-model="item.shirtSize"
                              hide-details
                              outlined
                              dense
                              :rules="shirtSizeRules[i]"
                            />
                          </v-col>
                        </div>
                      </v-fade-transition>

                      <v-fade-transition>
                        <v-col cols="12" v-if="addressRequired[i]">
                          <v-row no-gutters>
                            <v-textarea
                              auto-grow
                              rows="1"
                              :label="$t('rewardSolicitation.address')"
                              v-model="item.rewardNote"
                              outlined
                              clearable
                              dense
                              counter
                              :rules="notesRules[i]"
                            />
                            <v-tooltip max-width="240" top color="primary">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="ml-2"
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-information-variant-circle
                                </v-icon>
                              </template>
                              <span class="caption">
                                {{
                                  $t(
                                    "rewardSolicitation.recipientAddressTooltip"
                                  )
                                }}
                              </span>
                            </v-tooltip>
                          </v-row>
                        </v-col>
                      </v-fade-transition>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col cols="12">
            <v-btn
              block
              text
              depressed
              class="primary"
              style="border-radius: 0"
              @click="addMore"
              v-if="rewards.length < 5"
              :disabled="loading"
            >
              {{ $t("rewardSolicitation.addRecipient") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { getUserInfo } from "../../handlers";

export default {
  name: "reward-form",
  data: () => ({
    rewardNote: "",
    loading: false,
    singleLocation: false,
    panels: [],
    rewards: [],
    open: false,
    recordId: null,
    notify: false,
    message: "",
    recipientToBeRemoved: 0,
    // openConfirmationDialog: false,
  }),
  computed: {
    options() {
      return [
        { label: this.$t("rewardSolicitation.wantIt"), value: "both" },
        {
          label: this.$t("rewardSolicitation.receiveReward"),
          value: "no-certificate",
        },
        {
          label: this.$t("rewardSolicitation.receiveCertificate"),
          value: "no-reward",
        },
        { label: this.$t("rewardSolicitation.doNotWantIt"), value: "none" },
      ];
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    addressRequired() {
      const required = [];

      // item.optionSelected !== 'none' && !singleLocation
      for (const recipient of this.rewards) {
        const { optionSelected } = recipient || {};

        if (!this.singleLocation && optionSelected !== "none") {
          required.push(true);
          continue;
        }

        required.push(false);
      }

      return required;
    },
    shirtSizeRules() {
      const defaultRule = [(v) => !!v || this.$t("form.required")];
      const rules = [];

      for (const recipient of this.rewards) {
        const { enabled, optionSelected } = recipient || {};

        if (
          optionSelected === "no-reward" ||
          optionSelected === "none" ||
          !enabled
        ) {
          rules.push([]);
          continue;
        }

        rules.push(defaultRule);
      }

      return rules;
    },
    notesRules() {
      const defaultRule = [
        (v) => !v || v?.length <= 300 || this.$t("form.maxLimit_300Rule"),
        (v) => !v || v?.length >= 5 || this.$t("form.minLimit_5Rule"),
      ];
      const rules = [];

      for (const recipient of this.rewards) {
        const { enabled, optionSelected } = recipient || {};

        if (this.singleLocation || optionSelected === "none" || !enabled) {
          rules.push([...defaultRule]);
          continue;
        }

        rules.push([
          (v) => Boolean(v) || this.$t("form.required"),
          ...defaultRule,
        ]);
      }

      return rules;
    },
    addressRule() {
      const defaultRule = [
        (v) => !v || v?.length <= 300 || this.$t("form.maxLimit_300Rule"),
        (v) => !v || v?.length >= 5 || this.$t("form.minLimit_5Rule"),
      ];

      if (!this.singleLocation) {
        return [...defaultRule];
      }

      return [(v) => Boolean(v) || this.$t("form.required"), ...defaultRule];
    },
    allReady() {
      if (this.singleLocation && !this.rewardNote) {
        return false;
      }

      if (!this.rewards || !this.rewards.length) {
        return false;
      }

      return this.rewards.every((_, index) => {
        // this.closePanel(index);
        const { enabled, ready } = this.rewards[index];
        return !enabled || (enabled && ready);
      });
    },
    recipientCount() {
      return this.rewards.filter(({ enabled }) => enabled).length;
    },
    showSingleLocation() {
      if (this.recipientCount <= 1) {
        return false;
      }

      return true;
      // (
      //   this.rewards.filter(
      //     ({ enabled, optionSelected }) => enabled && optionSelected !== 'none'
      //   ).length > 1
      // );
    },
    openConfirmationDialog: {
      get: function () {
        return Boolean(this.recipientToBeRemoved);
      },
      set: function (value) {
        if (value) {
          return;
        }

        // this.panels = [];
        this.recipientToBeRemoved = 0;
      },
    },
  },
  methods: {
    ...mapActions("users", ["getUser"]),
    ...mapActions("entry", ["submitReward"]),
    close() {
      if (this.loading) {
        this.loading = false;
      }

      this.rewards = [];
      this.open = false;
    },
    async submit() {
      this.loading = true;

      try {
        const rewardsPayload = [];

        for (const reward of this.rewards) {
          const { ready, enabled } = reward || {};

          if (!enabled || !ready) {
            continue;
          }

          const { id, optionSelected, shirtSize, rewardNote, name } =
            reward || {};

          rewardsPayload.push({
            userId: id,
            name,
            shirtSize,
            notes: rewardNote,
            receiveCertifications:
              optionSelected === "no-reward" || optionSelected === "both",
            receiveRewards:
              optionSelected === "no-certificate" || optionSelected === "both",
          });
        }

        const response = await this.submitReward({
          rewards: rewardsPayload,
          rewardNote: this.rewardNote,
          recordId: this.recordId,
        });
        const { success } = response?.data || {};

        if (!success) {
          this.message = this.$t("rewardSolicitation.submitFailed");
          this.notify = true;
          return;
        }

        this.close();
      } catch {
        this.message = this.$t("rewardSolicitation.submitFailed");
        this.notify = true;
        this.loading = false;
      }
    },
    closePanel(index) {
      const { rewardForm } = this.$refs || {};
      if (!rewardForm || !rewardForm[index]) {
        return;
      }

      const { validate } = rewardForm[index] || {};
      this.rewards[index].ready = validate && validate();
    },
    resetReady(index) {
      const { rewardForm } = this.$refs || {};
      if (!rewardForm || !rewardForm[index]) {
        return;
      }

      this.rewards[index].ready = false;
    },
    addMore() {
      const validRecipients = this.rewards.every(
        ({ isNew, name }) => !isNew || Boolean(name)
      );

      if (!validRecipients) {
        this.message = this.$t("rewardSolicitation.addRecipientWarning");
        this.notify = true;
        return;
      }

      this.rewards.push({
        enabled: false,
        name: null,
        optionSelected: "none",
        shirtSize: null,
        rewardNote: null,
        isNew: true,
        id: null,
        ready: false,
      });
    },
    askRemove(index, value) {
      if (value) {
        return;
      }

      const { isNew } = this.rewards[index] || {};
      if (!isNew) {
        this.panels = this.panels.filter((i) => i !== index);
        return;
      }

      this.recipientToBeRemoved = index + 1;
    },
    removeRecipient() {
      const index = this.recipientToBeRemoved - 1;
      this.confirmation = false;

      if (this.$refs.rewardForm && this.$refs.rewardForm[index]) {
        this.$refs.rewardForm[index].reset();
      }

      this.rewards.splice(index, 1);
      this.recipientToBeRemoved = 0;
    },
    async init(recordId = "", crew = []) {
      if (!recordId) {
        return;
      }

      this.singleLocation = false;
      this.recordId = recordId;
      this.rewards = [];
      this.loading = true;

      try {
        for (const recipient of crew || []) {
          const response = await this.getUser({ id: recipient });
          const { data: userData } = response || {};
          const { shirtSize, rewardsConfig, address, firstName, lastName } =
            userData || {};
          const { receiveCertifications, receiveRewards, notes } =
            rewardsConfig || {};
          const { line1, city, state, zip, country } = address || {};
          const addressNote = [line1, city, state, zip, country]
            .filter(Boolean)
            .join(" ");

          let optionSelected = "none";

          if (receiveCertifications && receiveRewards) {
            optionSelected = "both";
          } else if (receiveCertifications) {
            optionSelected = "no-reward";
          } else if (receiveRewards) {
            optionSelected = "no-certificate";
          }

          this.rewards.push({
            id: recipient,
            enabled: true,
            name: [firstName, lastName].filter(Boolean).join(" "),
            optionSelected,
            shirtSize: shirtSize || "XL",
            rewardNote: notes || addressNote,
            ready: false,
            isNew: false,
          });
        }

        const { id: userId } = getUserInfo();
        const response = await this.getUser({ id: userId });
        const { address } = response?.data || {};
        const { line1, city, state, zip, country } = address || {};
        this.rewardNote = [line1, city, state, zip, country]
          .filter(Boolean)
          .join(" ");

        this.panels = this.rewards.map((_, i) => i);
      } catch {
        this.rewards = [];
      }

      this.loading = false;

      this.open = true;
    },
    onNameTyping(index) {
      const { name, enabled } = this.rewards[index] || {};
      const panelIncluded = this.panels.includes(index);

      if (!name) {
        if (panelIncluded) {
          this.panels = this.panels.filter((i) => i !== index);
        }

        if (enabled) {
          this.rewards[index].enabled = false;
        }

        return;
      }

      this.rewards[index].name = name.toUpperCase();

      if (!enabled) {
        this.rewards[index].enabled = true;
      }

      if (panelIncluded) {
        return;
      }

      this.panels.push(index);
    },
  },
  watch: {
    singleLocation: {
      handler: function () {
        if (this.$refs.rewardForm) {
          this.$refs.rewardForm.forEach((form, i) => {
            this.rewards[i].ready = false;
          });
        }
      },
    },
    panels: {
      handler: function (newValue, prevValue) {
        prevValue?.forEach((index) => {
          if (!newValue?.includes(index)) {
            this.closePanel(index);
          }
        });

        newValue?.forEach((index) => {
          if (!prevValue?.includes(index)) {
            this.resetReady(index);
          }
        });
      },
    },
  },
};
</script>
