<template>
  <v-dialog
    v-model="open"
    width="1200"
    persistent
    :fullscreen="mobileView"
    scrollable
  >
    <v-card class="fill-height">
      <v-toolbar dark color="primary" max-height="100px">
        <v-icon>mdi-cast-education</v-icon>
        <v-toolbar-title class="ml-2">
          {{ $t("tutorials") }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="close"> {{ $t("close") }} </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-data-iterator
          :items="itemsToShow"
          item-key="name"
          hide-default-footer
          class="py-5 px-sm-10 pb-sm-7 pa-sm-8"
          loading="loading"
        >
          <template v-slot:default="{ items }">
            <v-row justify-lg="start" justify="center">
              <v-col
                v-for="item in items"
                :key="item.link"
                cols="12"
                sm="7"
                md="5"
                lg="3"
              >
                <v-card>
                  <iframe
                    :src="loadURL(item.link)"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    width="100%"
                  />
                  <v-card-title v-if="item.title">
                    {{ item.title }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { getUserInfo } from "../../handlers";

export default {
  name: "TutorialPage",
  data: () => ({
    links: Links,
    open: false,
    allowOnlyOnMultiple: false,
    loading: false,
  }),
  async mounted() {
    this.loading = true;
    try {
      const { id } = await getUserInfo();
      const {
        data: { multipleEvents },
      } = await this.getUser({ id });
      this.allowOnlyOnMultiple = !!multipleEvents;
    } catch (e) {
      // console.log({ e });
    }
    this.loading = false;
  },
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    itemsToShow() {
      return this.links.filter(
        (item) =>
          !item?.allowOnlyOnMultiple ||
          (item?.allowOnlyOnMultiple && this.allowOnlyOnMultiple)
      );
    },
  },
  methods: {
    ...mapActions("users", ["getUser"]),
    loadURL(link) {
      const url = link.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      const youtubeID =
        undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      return youtubeEmbedTemplate.concat(youtubeID, "?enablejsapi=1");
    },
    show() {
      this.open = true;
    },
    close() {
      const iframe = document.getElementsByTagName("iframe");
      for (let index = 0; index < iframe.length; index++) {
        iframe[index].contentWindow.postMessage(
          '{"event":"command","func":"' + "stopVideo" + '","args":""}',
          "*"
        );
      }
      this.open = false;
    },
  },
};

const Links = [
  { link: "https://www.youtube.com/watch?v=AjpH1uDfoBkß" },
  { link: "https://www.youtube.com/watch?v=MlgM__VUmhc" },
  { link: "https://www.youtube.com/watch?v=E1-JbF41CMw" },
  { link: "https://www.youtube.com/watch?v=mmyDPGROpmw" },
  { link: "https://www.youtube.com/watch?v=3AeB8m6Pc_Q" },
  {
    link: "https://www.youtube.com/watch?v=D_zdmgxKEXw&t=1s",
    allowOnlyOnMultiple: true,
  },
  { link: "https://youtu.be/o73gvyx87ow" },
  { link: "https://www.youtube.com/watch?v=3dAMuhYAYYU" },
];
</script>
