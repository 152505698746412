<template>
  <v-card flat title>
    <v-snackbar v-model="notify" timeout="2000" top>
      {{ message }}
    </v-snackbar>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-icon v-if="!mobileView">mdi-card-account-details</v-icon>
      <v-toolbar-title class="ml-2" v-if="!mobileView">
        {{ $t("profile") }}
      </v-toolbar-title>
      <v-chip class="ml-2" outlined pill v-if="!!data.memberId">
        Member ID: {{ data.memberId }} until {{ expirationDate }}
      </v-chip>
      <v-btn
        class="ml-2"
        small
        outlined
        rounded
        v-else
        href="https://billfish.org/become-a-member/"
        target="_blank"
      >
        {{ $t("profileForm.becomeMember") }}
      </v-btn>
      <v-spacer />
      <v-toolbar-items>
        <v-btn dark text @click="save">{{ $t("save") }}</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
      striped
      height="5"
    ></v-progress-linear>
    <v-card-text class="pt-3">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-chip outlined pill color="primary">
            {{ $t("profileForm.totalEvents") }}: {{ totalCount || 0 }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-chip outlined pill color="primary">
            {{ $t("profileForm.tagsRecaptured") }}: {{ tagsRecaptured || 0 }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-chip outlined pill color="primary">
            {{ $t("profileForm.anglerEvents") }}: {{ totalEventsAsAngler || 0 }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-chip outlined pill color="primary">
            {{ $t("profileForm.captainEvents") }}:
            {{ totalEventsAsCaptain || 0 }}
          </v-chip>
        </v-col>
      </v-row>
      <v-form :disabled="loading" class="mt-2" ref="userForm">
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <v-list-item>
              <v-btn
                fab
                absolute
                top
                left
                x-small
                color="error"
                @click="remove"
                v-if="!!image"
                :disabled="loading"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-list-item-avatar size="96">
                <v-img :src="srcImg" :lazy-src="defaultAvatar" />
              </v-list-item-avatar>
              <v-list-item-content class="text-center pl-5">
                <v-list-item-title class="mb-3">
                  {{ $t("profileForm.changePhoto") }}
                </v-list-item-title>
                <v-btn
                  color="primary"
                  :disabled="loading"
                  outlined
                  @click="upload"
                >
                  {{ $t("profileForm.upload") }}
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              outlined
              @click="$router.push({ path: 'forgot-password' })"
              block
              class="mb-5"
              color="primary"
            >
              {{ $t("profileForm.resetPwd") }}
            </v-btn>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  :label="$t('profileForm.title')"
                  v-model="data.title"
                  hide-details
                  outlined
                  clearable
                  :dense="!mobileView"
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  :label="$t('profileForm.firstName')"
                  v-model="data.firstName"
                  hide-details
                  outlined
                  clearable
                  :dense="!mobileView"
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  :label="$t('profileForm.lastName')"
                  v-model="data.lastName"
                  hide-details
                  outlined
                  clearable
                  :dense="!mobileView"
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  :label="$t('profileForm.suffix')"
                  v-model="data.suffixName"
                  hide-details
                  outlined
                  clearable
                  :dense="!mobileView"
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('profileForm.email')"
              v-model="data.email"
              :rules="emailRule"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('profileForm.line1')"
              v-model="data.address.line1"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('profileForm.line2')"
              v-model="data.address.line2"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('profileForm.city')"
              v-model="data.address.city"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="4">
            <v-text-field
              :label="$t('profileForm.zip')"
              v-model="data.address.zip"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="6" sm="4">
            <v-autocomplete
              :items="states"
              item-text="name"
              item-value="acronym"
              :label="$t('profileForm.state')"
              v-model="state"
              :search-input.sync="searchInput"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :label="$t('profileForm.country')"
              v-model="data.address.country"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              :label="$t('profileForm.personalPhone')"
              v-model="data.personalPhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :label="$t('profileForm.workPhone')"
              v-model="data.workPhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :label="$t('profileForm.homePhone')"
              v-model="data.homePhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              :label="$t('profileForm.shirtSize')"
              v-model="data.shirtSize"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              :items="['Male', 'Female', 'NA']"
              :label="$t('profileForm.gender')"
              v-model="data.gender"
              hide-details
              outlined
              clearable
              :dense="!mobileView"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <date-picker
              v-model="data.birthday"
              :label="$t('profileForm.birthday')"
              @clear="data.birthday = null"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-banner
        color="light-blue lighten-5 px-5"
        v-if="data['virtuousId']"
        class="mt-5"
      >
        <v-icon slot="icon" color="primary" size="36"> mdi-link-lock </v-icon>
        <i18n path="bindWarning.text" tag="span">
          <template v-slot:url>
            <a href="https://tbf.givevirtuous.org/Account/Register">{{
              $t("bindWarning.link")
            }}</a>
          </template>
          <template v-slot:mail>
            <a href="mailto:tag@billfish.org">{{
              $t("bindWarning.contact")
            }}</a>
          </template>
          <template v-slot:member>
            {{ data["virtuousId"] }}
          </template>
        </i18n>
      </v-banner>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { defaultAvatar, getBase64, getUserInfo } from "../../handlers";
import DatePicker from "../../components/appComponents/DatePicker.vue";
import moment from "moment";
export default {
  components: { DatePicker },
  name: "UserProfile",
  async mounted() {
    const user = getUserInfo();
    this.userId = user?.id;
    this.getStates();
    this.init();
  },
  data: () => ({
    userId: null,
    data: initUser,
    states: [],
    search: null,
    totalCount: 0,
    tagsRecaptured: 0,
    totalEventsAsAngler: 0,
    totalEventsAsCaptain: 0,
    loading: false,
    isSelecting: false,
    image: null,
    notify: false,
    message: null,
    phoneNumberMask: "(###) ### ####",
    emailRule: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
  }),
  computed: {
    expirationDate() {
      return moment(this.data?.membership?.expirationDate).format("MM-DD-YYYY");
    },
    state: {
      get: function () {
        this.data.address.state && this.getStates(this.data.address.state[0]);
        return this.data.address.state;
      },
      set: function (v) {
        this.data.address.state = v;
      },
    },
    searchInput: {
      get: function () {
        return this.search;
      },
      set: function (v) {
        if (v !== this.search) {
          this.search = v;
          this.getStates(v);
        }
      },
    },
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    srcImg() {
      if (typeof this.image === "string") {
        return this.image || defaultAvatar;
      } else {
        const { mime, data } = this.image || {};
        const avatar = mime && data && `data:${mime};base64,${data}`;
        return avatar || defaultAvatar;
      }
    },
    defaultAvatar() {
      return defaultAvatar;
    },
    phoneRule() {
      return [(v) => !v || v?.length === 14 || this.$t("form.phoneRule")];
    },
  },
  methods: {
    ...mapActions("users", ["getUser"]),
    ...mapActions("dashboard", ["getItems"]),
    ...mapActions("entry", ["getAvatar", "uploadAvatar", "deleteAvatar"]),
    cancel() {
      this.$emit("submit", null);
    },
    save() {
      const validForm = this.$refs.userForm && this.$refs.userForm.validate();
      if (validForm) {
        this.$emit("submit", { ...this.data, _id: this.userId });
      }
    },
    async init() {
      this.loading = true;
      this.data = initUser;
      const avatar = await this.getAvatar();
      this.image = avatar?.data?.docs && JSON.parse(avatar.data.docs)?.image;
      const response = await this.getUser({ id: this.userId });
      const { data: userData } = response || {};
      if (userData) {
        this.phoneNumberMask = "";
        Object.keys(this.data).forEach((key) => {
          if (key === "address" && userData[key]) {
            Object.keys(this.data.address || {}).forEach((innerKey) => {
              this.data.address[innerKey] = userData.address[innerKey];
            });
          } else {
            this.data[key] = response.data[key];
          }
        });

        this.$nextTick(() => {
          this.phoneNumberMask = "(###) ### ####";
        });
      }

      const {
        totalEventsGeneral,
        totalTagsRecaptured,
        totalEventsAsAngler,
        totalEventsAsCaptain,
      } = response?.data || {};
      this.totalCount = totalEventsGeneral || 0;
      this.tagsRecaptured = totalTagsRecaptured || 0;
      this.totalEventsAsAngler = totalEventsAsAngler || 0;
      this.totalEventsAsCaptain = totalEventsAsCaptain || 0;
      this.loading = false;
    },
    async getStates(name = "") {
      const payload = { name, item: "states", page: 1, limit: 10 };
      const response = await this.getItems(payload);
      response?.data?.docs && (this.states = response.data.docs);
    },
    upload() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async remove() {
      const response = await this.deleteAvatar();
      this.notify = true;
      if (response?.data?.success) {
        this.message = this.$t("profileForm.removed");
        this.image = null;
        this.$emit("update", this.image);
      } else this.message = this.$t("failed");
    },
    async onFileChanged(e) {
      const file = e?.target?.files[0];
      if (file) {
        const mime = file?.type;
        const image = await getBase64(file);
        const data = String(image).split(",")[1];
        const response = await this.uploadAvatar({ mime, data });
        this.notify = true;
        if (response?.data?.success) {
          this.message = this.$t("profileForm.uploaded");
          this.image = image;
          this.$emit("update", this.image);
        } else this.message = this.$t("failed");
      }
    },
  },
};

const initUser = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  title: null,
  suffixName: null,
  shirtSize: null,
  gender: null,
  personalPhone: null,
  workPhone: null,
  homePhone: null,
  birthday: null,
  memberId: null,
  virtuousId: null,
  membership: {
    expirationDate: null,
  },
  rewardsConfig: {
    receiveCertifications: true,
    receiveRewards: true,
    notes: "",
    updatedAt: null,
  },
};
</script>
