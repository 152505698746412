<template>
  <v-card>
    <v-dialog
      v-model="openHelp"
      max-width="800px"
      :fullscreen="mobileView"
      scrollable
    >
      <v-card class="elevation-4">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <v-icon class="pr-2">mdi-tooltip-question</v-icon>
            {{ $t("dashboard.fishingLocations.help.title") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openHelp = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <h3>{{ $t("dashboard.fishingLocations.help.setMarker") }}</h3>
          <p>
            {{ $t("dashboard.fishingLocations.help.setMarkerDescription") }}
          </p>

          <h3>{{ $t("dashboard.fishingLocations.help.setCurrent") }}</h3>
          <p>
            {{ $t("dashboard.fishingLocations.help.setCurrentDescription") }}
          </p>

          <h3>{{ $t("dashboard.fishingLocations.help.modifyLocation") }}</h3>
          <p>
            {{
              $t("dashboard.fishingLocations.help.modifyLocationDescription")
            }}
          </p>

          <h3>{{ $t("dashboard.fishingLocations.help.removeLocation") }}</h3>
          <p>
            {{
              $t("dashboard.fishingLocations.help.removeLocationDescription")
            }}
          </p>

          <!-- <h3>Create a new location</h3>
          <p>
            In case you didn't find a location description that fits your
            selection, you can create a new one to fill your record. This new
            location will be sent to the admins for review and approval
          </p> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <div id="map" ref="fishingMap">
      <v-snackbar v-model="notify" :timeout="3000" top>
        {{ message }}
      </v-snackbar>
      <div class="leaflet-bottom leaflet-left">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabNavigation">
              <v-btn
                fab
                @click.stop="toggleNavigation"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
              >
                <v-icon color="primary">
                  {{ enabled ? "mdi-cancel" : "mdi-map-check" }}
                </v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>
            {{
              enabled
                ? $t("dashboard.fishingLocations.disableNavigation")
                : $t("dashboard.fishingLocations.enableNavigation")
            }}
          </span>
        </v-tooltip>
      </div>
      <div class="leaflet-top leaflet-right">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabPosition">
              <v-btn
                fab
                @click.stop="setCurrent"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
                :disable="locating"
              >
                <v-icon color="primary">mdi-map-marker-radius</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span> {{ $t("dashboard.fishingLocations.setCurrent") }} </span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabPosition">
              <v-btn
                fab
                @click.stop="clearMarker"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
              >
                <v-icon color="primary">mdi-map-marker-remove-variant</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span> {{ $t("dashboard.fishingLocations.clearMarker") }} </span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabPosition">
              <v-btn
                fab
                @click.stop="openHelp = true"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
              >
                <v-icon color="primary">mdi-map-marker-question</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span> {{ $t("help") }} </span>
        </v-tooltip>
      </div>
      <div class="leaflet-bottom leaflet-right" v-if="fabBaseMap">
        <v-tooltip left v-for="layer in layers" :key="layer">
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition>
              <v-btn
                fab
                @click="setBaseMap(layer)"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                <v-icon color="primary">mdi-map-legend</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span> {{ layer }} </span>
        </v-tooltip>
      </div>
    </div>
  </v-card>
</template>

<script>
import {
  handleCoords,
  reverseCoords,
  LAYERS,
  WATER_SURFACES,
  validCoordinates,
} from "../../handlers";
import { CoordinatesViewModel } from "../models";
import { getUserInfo } from "../../handlers";
export default {
  name: "fishing-locations",
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    fabPosition: {
      type: Boolean,
      default: true,
    },
    fabNavigation: {
      type: Boolean,
      default: true,
    },
    fabBaseMap: {
      type: Boolean,
      default: false,
    },
    input: {
      type: Object,
      default: () => ({ ...new CoordinatesViewModel() }),
    },
  },
  data: () => ({
    items: [],
    map: null,
    markers: null,
    marker: null,
    coords: null,
    notify: false,
    message: null,
    enabled: false,
    layers: LAYERS,
    terrainType: null,
    locating: false,
    openHelp: false,
  }),
  mounted() {
    const user = getUserInfo();
    this.legacyId = user?.id;
  },
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    LatLng() {
      const { coordinates } = this.input || {};
      const valid = validCoordinates(coordinates);

      if (!valid) {
        return JSON.parse(localStorage.getItem("location")) || [0, 0];
      }

      return handleCoords(coordinates);
    },
    data: {
      set: function (data) {
        const { coordinates } = data || {};
        const valid = validCoordinates(coordinates);
        if (valid) {
          this.$emit("coords", coordinates);
        }
      },
      get: function () {
        return this.input;
      },
    },
  },
  watch: {
    "data.coordinates": {
      handler: "handleData",
    },
    enabled: {
      handler: "setHandlerState",
    },
    map: {
      handler: "setHandlerState",
    },
    terrainType: {
      handler: "emitTerrainType",
      immediate: true,
    },
  },
  methods: {
    handleData(coordinates) {
      this.initMap();
      this.clearLayers();

      if (this.marker) {
        this.map.removeLayer(this.marker);
      }

      const valid = validCoordinates(coordinates);

      if (!valid) {
        this.newMarker();
        return;
      }

      this.addMarker();
    },
    newMarker() {
      this.map.on("click", ({ latlng: LatLng }) => {
        if (this.marker) {
          this.map.removeLayer(this.marker);
        }

        this.marker = window.L.marker(LatLng).addTo(this.map);
        this.marker = this.setClickablePopUp(this.marker);
        this.coords = LatLng;
        this.$emit("coords", reverseCoords(LatLng));
        this.marker.on("dragend", ({ target: marker }) => {
          const { lat, lng } = marker.getLatLng();
          this.$emit("coords", reverseCoords({ lat, lng }));
        });
      });
    },
    addMarker() {
      const content = this.getPopUpContent(this.data);

      const popUp = new window.L.Popup();
      const [lat, lng] = this.LatLng;
      popUp.setLatLng(new window.L.LatLng(lat, lng));
      popUp.setContent(content);

      this.marker = window.L.marker(this.LatLng).addTo(this.map);
      this.marker.bindPopup(popUp);
      this.map.setView(this.LatLng, 4);

      if (this.enabled) {
        this.marker.dragging.enable();
      }

      this.marker.on("dragend", ({ target: marker }) => {
        const { lat, lng } = marker.getLatLng();
        this.$emit("coords", reverseCoords({ lat, lng }));
      });
    },
    setClickablePopUp(marker) {
      if (!marker) {
        return marker;
      }

      let btn = document.createElement("button");
      btn.innerText = this.$t("dashboard.fishingLocations.selectLocation");
      btn.onclick = (event) => {
        if ("preventDefault" in event) {
          event.preventDefault();
        }

        marker.closePopup();

        if (this.coords) {
          this.$emit("coords", reverseCoords(this.coords));
        }
      };
      marker.bindPopup(btn, { closeButton: false, maxWidth: "auto" });
      marker.openPopup();
      return marker;
    },
    close() {
      if (!this.map) {
        return;
      }

      if (this.marker) {
        this.map.removeLayer(this.marker);
        this.marker = null;
      }

      this.map.off();
      this.map.remove();
      this.map = null;
    },
    initMap() {
      if (this.map) {
        return;
      }
      this.map = new window.L.Map(this.$refs.fishingMap, {
        dragging: false,
        tap: false,
        scrollWheelZoom: false,
      });
      this.map.options.maxZoom = 15;
      window.L.DomUtil.addClass(
        this.map._container,
        "crosshair-cursor-enabled"
      );
      window.L.esri.basemapLayer("Topographic").addTo(this.map);
      this.map.setView(this.LatLng, 4);
      this.enabled = false;
    },
    async setCurrent() {
      if (!("geolocation" in navigator)) {
        this.message = this.$t(
          "dashboard.fishingLocations.geolocationNotSupported"
        );
        this.notify = true;
        return;
      }

      const permission = await navigator.permissions.query({
        name: "geolocation",
      });
      if (permission.state === "denied") {
        this.message = this.$t("dashboard.fishingLocations.allowGeolocation");
        this.notify = true;
        return;
      }

      const location = localStorage.getItem("location");
      if (!location) {
        this.getLocation();
        return;
      }

      const [lat, lng] = JSON.parse(location);
      this.$emit("coords", reverseCoords({ lat, lng }));
    },
    getLocation() {
      this.locating = true;
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.locating = false;
        const { latitude, longitude } = coords || {};
        localStorage.setItem("location", JSON.stringify([latitude, longitude]));
        this.$emit("coords", reverseCoords({ lat: latitude, lng: longitude }));
      });
    },
    getDescription(coordinates) {
      if (!coordinates || !coordinates["description"]) {
        return;
      }

      const latlng = handleCoords(coordinates);

      const geocoder = window.L.esri.Geocoding.geocodeService({
        apikey:
          "AAPKde199e1968134b64a2c7ae9cd7d2aa13RqAB5ly-c2MylgUNnFHbQQJx12gF_uGlff0j3AfmPwwPaF36YXWLZLzUALO9Vgck",
      });

      geocoder
        .reverse()
        .latlng(latlng)
        .run((error, result) => {
          this.terrainType = result.address["Type"];
        });
    },
    emitTerrainType(value) {
      if (value === null || value === undefined) {
        return;
      }

      const isWater = WATER_SURFACES.some((surface) => surface === value);

      if (!isWater) {
        this.$emit("validate", true);
      }

      this.$emit("validate", isWater);
    },
    setHandlerState() {
      if (!this.map) {
        return;
      }

      if (this.enabled) {
        document.getElementById("map").style.cursor = "grab";
        this.map._handlers.forEach(
          (handler) => "enable" in handler && handler.enable()
        );
      } else {
        document.getElementById("map").style.cursor = "default";
        this.map._handlers.forEach(
          (handler) => "disable" in handler && handler.disable()
        );
      }

      if (!this.marker) {
        return;
      }

      const { dragging } = this.marker;
      this.enabled ? dragging.enable() : dragging.disable();
    },
    toggleNavigation(event) {
      if ("isTrusted" in event && !event["isTrusted"]) {
        return;
      }

      this.enabled = !this.enabled;
    },
    setBaseMap(baseMap = "Topographic") {
      L.esri.basemapLayer(baseMap).addTo(this.map);
    },
    addMarkers(data) {
      this.initMap();
      this.items = data || [];

      if (this.markers && "clearLayers" in this.markers) {
        this.markers.clearLayers();
      }

      if (!this.items.length) {
        return;
      }

      this.markers = L.markerClusterGroup();

      this.items.forEach((item) => {
        const content = this.getPopUpContent(item);
        const LatLng = handleCoords(item.coordinates);
        const [lat, lng] = LatLng;
        const popUp = new L.Popup();
        popUp.setLatLng(new L.LatLng(lat, lng));
        popUp.setContent(content);
        const marker = L.marker(LatLng);
        marker.bindPopup(popUp);
        this.markers.addLayer(marker);
      });

      this.map.addLayer(this.markers);
      const bounds = this.markers.getBounds();
      this.map.fitBounds(bounds, { padding: L.point(35, 35), maxZoom: 10 });
    },
    getPopUpContent(data) {
      return `
        <b>Angler: </b> ${data?.angler?.firstName || ""} ${
        data?.angler?.lastName || ""
      } <br />
        <b>Captain: </b> ${data?.captain?.firstName || ""} ${
        data?.captain?.lastName || ""
      } <br />
        <b>First Mate: </b> ${data?.firstMate?.firstName || ""} ${
        data?.firstMate?.lastName || ""
      } <br />
        <b>Ocean: </b> ${data?.capture?.ocean || ""} <br />
        <b>Event: </b> ${data?.capture?.tournament?.name || ""} <br />
        <b>Bait: </b> ${data?.capture?.bait?.name || ""} <br />
        <b>Fish's Species: </b> ${data?.capture?.fish?.specie?.name} <br />
        <b>Fish's Weight: </b> ${
          data?.capture?.fish?.weight?.value || 0
        } lb <br />
        <b>Fish's Length: </b> ${data?.capture?.fish?.length?.value || 0} ft
      `;
    },
    clearMarker() {
      if (this.marker) {
        this.map.removeLayer(this.marker);
      }
      const { coordinates } = new CoordinatesViewModel();
      this.$emit("coords", coordinates);
      this.initMap();
    },
    clear() {
      if (this.drawnItems) {
        this.drawnItems.clearLayers();
      }

      this.$emit("coords", null);
      this.map.setView(this.LatLng, 4);
    },
    clearLayers() {
      Object.values(this.map._layers)?.forEach((layer) => {
        if (layer.options?.color) {
          try {
            this.map.removeLayer(layer);
          } catch (e) {
            // console.log("problem with " + e + layer);
          }
        }
      });
    },
    setView() {
      setTimeout(() => {
        this.map.setView(this.LatLng, 2);
      }, 50);
    },
  },
  beforeDestroy() {
    localStorage.removeItem("location");
    this.close();
  },
};
</script>

<style>
.title {
  color: white !important;
}

#map {
  height: 500px;
  z-index: 0;
}

.marker-cluster {
  background: rgba(94, 94, 218, 0.8);
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: 700;
  border: 5px solid rgba(94, 94, 218);
}

.marker-cluster div {
  padding-top: 20%;
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: default;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
