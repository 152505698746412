export default [
  {
    icon: "mdi-view-dashboard",
    title: "dashboard",
    to: "/dashboard",
  },
  {
    icon: "mdi-text-box-search",
    title: "search",
    to: "/search",
  },
  {
    icon: "mdi-cast-education",
    title: "tutorials",
    emit: "tutorial",
  },
  {
    icon: "mdi-tag-text",
    title: "submitTag",
    emit: "tag",
    color: "primary",
  },
  {
    icon: "mdi-fish",
    title: "submitRelease",
    emit: "release",
    color: "green",
  },
  {
    icon: "mdi-fishbowl-outline",
    title: "submitRecapture",
    emit: "recapture",
    color: "orange",
  },
  {
    icon: "mdi-fishbowl",
    title: "submitJuvenile",
    emit: "juvenile",
    color: "indigo",
  },
  {
    icon: "mdi-home",
    title: "website",
    href: "https://billfish.org/",
  },
  {
    icon: "mdi-ticket-account",
    title: "join",
    href: "https://billfish.org/become-a-member/",
    bold: true,
  },
  {
    icon: "mdi-gift",
    title: "donate",
    href: "https://billfish.org/donate/",
    bold: true,
  },
  {
    icon: "mdi-shopping",
    title: "shop",
    href: "https://shop.billfish.org/",
  },
  {
    icon: "mdi-account-edit",
    title: "profile",
    emit: "edit",
  },
  {
    icon: "mdi-email-fast",
    title: "contactUs",
    href: "mailto:tag@billfish.org",
  },
  {
    icon: "mdi-shield-check",
    title: "privacy",
    to: "/privacy",
  },
  {
    icon: "mdi-frequently-asked-questions",
    title: "faqs",
    emit: "faqs",
  },
];
