<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col md="8" lg="6">
        <v-card class="elevation-4">
          <div class="pa-7 pa-sm-12 text-center">
            <snackbar :open="notify" :message="notification" :timeout="5000" />
            <snackbar
              :open="failedCaptchaAttempts >= 3"
              :message="$t('recaptcha.failed3Times')"
              :button-label="$t('login.reload')"
              @onclick="$router.go()"
            />
            <snackbar
              :open="passwordMismatchAttempts >= 3"
              :message="$t('login.failedPwd3Times')"
              :button-label="$t('login.resetPwd')"
              @onclick="
                $router.push({
                  path: 'forgot-password',
                  query: { email },
                })
              "
            />
            <snackbar
              :open="needActivation"
              :message="$t('login.activateMsg')"
              :button-label="$t('login.activate')"
              @onclick="
                $router.push({
                  path: 'activation-email',
                  query: { email },
                })
              "
            />
            <v-overlay :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              The Billfish Foundation Tag & Release Application
            </h2>
            <a class="link" @click="goToHomePage">
              <img :src="defaultAvatar" alt="Billfish" />
            </a>
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              {{ $t("login.signIn") }}
            </h2>
            <h6 class="subtitle-1">
              {{ $t("login.haveAccount") }}
              <a
                @click="$router.push({ path: 'signup' })"
                class="link font-weight-bold"
              >
                {{ $t("signUp.title") }}
              </a>
            </h6>

            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              action="/dashboards/analytical"
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :label="$t('email')"
                class="mt-4"
                required
                outlined
                :dense="full"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :rules="passwordRules.concat(passwordRequirements)"
                :label="$t('password')"
                required
                outlined
                :dense="full"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              ></v-text-field>

              <v-expansion-panels v-model="panel" multiple readonly>
                <v-expansion-panel>
                  <v-progress-linear
                    indeterminate
                    color="primary"
                    v-if="loading"
                    striped
                    height="5"
                  ></v-progress-linear>
                  <v-expansion-panel-header
                    class="font-weight-bold"
                    expand-icon=""
                  >
                    {{ $t("statistics.title") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <statistics
                      open
                      login
                      vertical
                      @loading="(value) => (loading = value)"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-row justify="end" align="center">
                <v-col>
                  <v-checkbox
                    v-model="remember"
                    :label="$t('login.rememberMe')"
                    required
                  />
                </v-col>
                <v-spacer />
                <v-col>
                  <a
                    @click="$router.push({ path: 'forgot-password' })"
                    class="link"
                  >
                    {{ $t("login.forgotPwd") }}
                  </a>
                </v-col>
              </v-row>

              <v-btn
                :disabled="!valid"
                color="info"
                block
                class="mr-4 mt-2"
                submit
                @click="recaptcha"
              >
                {{ $t("login.signIn") }}
              </v-btn>
            </v-form>
            <div class="my-2 text-caption">- or sign in with -</div>
            <div class="text-center justify-space-around d-flex">
              <v-chip pill @click.prevent="loginGoogle">
                <v-avatar left>
                  <v-btn color="red" class="white--text">
                    <v-icon>mdi-google</v-icon>
                  </v-btn>
                </v-avatar>
                Google
              </v-chip>
              <v-chip pill @click.prevent="loginFacebook">
                <v-avatar left>
                  <v-btn color="indigo" class="white--text">
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn> </v-avatar
                >Facebook
              </v-chip>
            </div>
            <div class="text-center justify-space-around d-flex">
              <v-chip pill @click.prevent="goToHomePage">
                <v-avatar left>
                  <v-btn color="primary" class="white--text">
                    <v-icon>mdi-home-circle</v-icon>
                  </v-btn>
                </v-avatar>
                TBF Homepage
              </v-chip>
            </div>
            <v-row justify="end" align="center" class="mt-5">
              <v-col cols="8" align-self="center" class="text-start">
                <a @click="goToHomePage" class="link">
                  &copy;
                  <span v-text="` - The Billfish Foundation - ${year}`" />
                </a>
              </v-col>
              <v-spacer />
              <v-col>
                <a href="privacy" target="_blank" class="link">
                  {{ $t("privacy") }}
                </a>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { addDays } from "date-fns";
import Statistics from "../Dashboard/Statistics.vue";
import SnackbarNotification from "../../components/appComponents/SnackbarNotification.vue";
import { defaultAvatar } from "../../handlers";
export default {
  name: "LogIn",
  components: { Statistics, snackbar: SnackbarNotification },
  data: () => ({
    valid: true,
    password: "",
    show1: false,
    passwordRules: [(v) => !!v || "Password is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    remember: false,
    notify: false,
    notification: "",
    overlay: false,
    loading: false,
    panel: [0],
    failedCaptchaAttempts: 0,
    passwordMismatchAttempts: 0,
    needActivation: false,
  }),
  computed: {
    passwordRequirements() {
      const strongRegex = new RegExp("(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})");
      return (v) => strongRegex.test(v) || "Password is not strong enough";
    },
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    year() {
      return new Date().getFullYear();
    },
    firstTime() {
      const { firstTime } = this.$route?.query || {};
      !!firstTime && this.$router.replace({ query: null });
      return !!firstTime;
    },
    tokenFacebook() {
      const { token } = this.$route?.query || {};
      !!token && this.$router.replace({ query: null });
      return token ? JSON.parse(token) : {};
    },
    loginError() {
      const { error } = this.$route?.query || {};
      !!error && this.$router.replace({ query: null });
      return !!error;
    },
    defaultAvatar() {
      return defaultAvatar;
    },
  },
  watch: {
    tokenFacebook: {
      handler: "checkToken",
      immediate: true,
    },
    loginError: {
      handler: "handleLoginError",
      immediate: true,
    },
  },
  mounted() {
    const { email } = this.$route?.query || {};

    if (email) {
      this.email = email;
    }
  },
  methods: {
    ...mapActions("users", [
      "login",
      "facebookLogin",
      "verifyCaptcha",
      "isAccountActivated",
    ]),
    async submit() {
      try {
        if (this.notify) {
          this.notify = false;
        }

        const response = await this.isAccountActivated({ email: this.email });
        const { success, activated } = response?.data || {};

        if (success && !activated) {
          this.needActivation = true;
          return;
        }

        const loginResponse = await this.login({
          email: this.email,
          password: this.password,
          remember: this.remember,
          client: null,
        });

        const { token, message } = loginResponse?.data || {};

        if (!token) {
          if (this.passwordMismatchAttempts >= 3) {
            this.passwordMismatchAttempts = 0;
          }

          if (/forgot password/i.exec(message)) {
            this.passwordMismatchAttempts += 1;

            if (this.passwordMismatchAttempts >= 3) {
              return;
            }
          }

          this.notification = message || "Something went wrong";
          this.notify = true;
          return;
        }

        this.checkToken(loginResponse?.data);
      } catch (err) {
        this.notification = "Something went wrong";
        this.notify = true;
      }
    },
    loginGoogle() {
      window.location.assign(
        `${process.env.VUE_APP_API_BASE_URL}/login/google`
      );
    },
    loginFacebook() {
      window.location.assign(
        `${process.env.VUE_APP_API_BASE_URL}/login/facebook`
      );
    },
    goToHomePage() {
      window.location.assign("https://billfish.org/");
    },
    checkToken(data) {
      if (!data?.token) {
        return;
      }

      localStorage.setItem("data", JSON.stringify(data));
      if (this.firstTime) {
        const remindMe = addDays(new Date(), -1);
        localStorage.setItem("tutorial", JSON.stringify({ remindMe }));
      }

      this.$router.push({
        path: "dashboard",
        query: { loggedIn: true },
      });
    },
    handleLoginError(loginError) {
      if (loginError) {
        this.notification = this.$t("failedCaptcha");
        this.notify = true;
      }
    },
    async recaptcha() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.overlay = true;

        let captchaResponse;
        try {
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded();

          this.$recaptchaInstance.showBadge();

          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha("login");

          const result = await this.verifyCaptcha({ token });
          const { data } = result;
          captchaResponse = JSON.parse(data || "");
        } catch (err) {}

        const {
          success,
          score,
          "error-codes": errorCodes,
        } = captchaResponse || {};

        if (!success) {
          await this.submit();
          this.overlay = false;
          return;
        }

        if (errorCodes) {
          let message = this.$t("recaptcha.failedService");

          if (errorCodes.length) {
            message = message.concat(
              ` ${this.$t("recaptcha.errorCodesIntro")}: ${codes.join(", ")}.`
            );
          }

          this.notification = message;
          this.notify = true;
          this.overlay = false;
          this.failedCaptchaAttempts += 1;
          return;
        }

        if (score < 0.5) {
          this.notification = this.$t("recaptcha.failedValidation");
          this.notify = true;
          this.overlay = false;
          this.failedCaptchaAttempts += 1;
          return;
        }

        await this.submit();
        this.overlay = false;
      }
    },
  },
};
</script>

<style scoped>
img {
  background-color: dodgerblue;
  border-radius: 10px;
}
</style>
