<template>
  <v-container fluid class="down-top-padding">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="notify" :timeout="3000" top>
      {{ message }}
    </v-snackbar>
    <preview :value="selected" @close="selected = null" />
    <v-card flat>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="7">
            <h4 class="font-weight-regular ml-5">
              <span v-if="searched">
                <span
                  v-if="!loading"
                  v-text="
                    `${$t('search.foundText')} ${totalDocs || 0} ${
                      all
                        ? $t('search.records')
                        : String($t(`search.${filter.event}`)).toLowerCase()
                    }`
                  "
                />
                <span v-else>{{ $t("loading") }} ...</span>
              </span>
              <b v-else v-text="$t('search.title')" />
            </h4>
          </v-col>
          <v-col cols="12" sm="12" md="5" :class="!full && 'text-right'">
            <v-btn
              :class="
                !showReset || searched ? 'd-none' : full ? 'mb-2' : 'ma-2'
              "
              color="error"
              dark
              @click="resetFilter"
              :block="full"
            >
              <v-icon left dark>mdi-filter-remove</v-icon>
              {{ $t("search.resetBtn") }}
            </v-btn>
            <v-btn
              :class="!full && 'ma-2'"
              color="primary"
              dark
              @click="submitFilter"
              :block="full"
            >
              <v-icon left dark>
                {{ searched ? "mdi-chevron-left" : "mdi-magnify" }}
              </v-icon>
              {{ searched ? $t("search.back") : $t("search.searchBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!searched" class="px-5">
        <v-form ref="filterForm">
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                :label="$t('search.searchFor')"
                :items="searchForItems"
                item-text="name"
                item-value="key"
                :rules="rule"
                v-model="filter.event"
                :open-on-clear="false"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="4">
              <auto-complete
                :label="$t('search.species')"
                itemType="species"
                v-model="filter.species"
                :ocean="filter.ocean"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <auto-complete
                :label="$t('search.ocean')"
                itemType="oceans"
                v-model="filter.ocean"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                :label="$t('search.number')"
                v-model="filter.tag"
                :rules="tagRule"
                v-mask="'BF########'"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                :label="$t('search.priorNumber')"
                v-model="filter.priorTag"
                :rules="tagRule"
                v-mask="'BF########'"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <date-picker
                v-model="filter.catchDate[0]"
                :label="$t('search.catchStartDate')"
                @clear="filter.catchDate = [null, null]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <date-picker
                v-model="filter.catchDate[1]"
                :label="$t('search.catchEndDate')"
                :minDate="filter.catchDate[0]"
                :disable="!filter.catchDate[0]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <auto-complete-location
                ref="locations"
                :label="$t('search.location')"
                v-model="filter.location"
                @ocean="(value) => (filter.ocean = value)"
                :hint="false"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <list-view
        ref="listView"
        :title="$t('search.tableTitle')"
        :headers="header"
        :records="records"
        :async-loading="loading"
        :footer-options="footer"
        :total-docs="totalDocs"
        :hideHeader="true"
        @onPaginate="(v) => (options = v)"
        v-if="searched"
        class="px-5 pb-5"
      >
        <template slot="list-item" slot-scope="{ row }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                fab
                class="mr-1 primary--text"
                v-bind="attrs"
                v-on="on"
                @click.stop="selected = { ...row }"
              >
                <v-icon> mdi-information-variant </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("search.preview") }}</span>
          </v-tooltip>
        </template>
        <template slot="captures" slot-scope="{ row }">
          <span v-if="isFishArray(row)">
            <v-list-item v-for="(item, i) in row.capture.fish" :key="i">
              <v-list-item-content>
                {{ item.name || "UNASSIGNED NAME" }}
              </v-list-item-content>
              <v-list-item-icon v-if="item.qty">
                <template> {{ item.qty }} </template>
              </v-list-item-icon>
            </v-list-item>
          </span>
          <span v-else>
            <v-list-item>
              <v-list-item-content>
                {{ getSpecieName(row) }}
              </v-list-item-content>
            </v-list-item>
          </span>
        </template>
        <template slot="header" slot-scope="{ row }" v-if="totalDocs">
          <!-- <th v-for="(head, i) in row.headers" :key="i">
            <v-list-item class="header_size" v-if="head.sortable">
              <v-list-item-title class="grey--text darken-1">
                {{ $t(`search.${head.key}`) }}
              </v-list-item-title>
              <v-list-item-action @click="sort(head)" class="mx-5">
                <v-btn icon>
                  <v-icon>mdi-swap-vertical</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </th> -->
          <th
            class="text-left pl-5 pb-5 header_size"
            v-for="(head, i) in row.headers"
            :key="i"
          >
            <div class="d-flex head_div">
              <span
                class="caption font-weight-bold grey--text text--darken-2 pr-1"
              >
                {{ $t(`search.${head.key}`) }}
              </span>
              <v-btn icon v-if="!!head['sortable']" @click="sort(head)" x-small>
                <v-icon v-if="head['order']">mdi-arrow-down</v-icon>
                <v-icon v-else>mdi-arrow-up</v-icon>
              </v-btn>
            </div>
          </th>
        </template>
      </list-view>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ListView from "@/components/appComponents/ListView.vue";
import { getFile } from "@/handlers";
import _ from "lodash";
import AutoComplete from "@/components/appComponents/AutoComplete.vue";
import AutoCompleteLocation from "@/components/appComponents/AutoCompleteLocation.vue";
import DatePicker from "@/components/appComponents/DatePicker.vue";
import Preview from "./Preview.vue";
export default {
  name: "SearchSection",
  components: {
    ListView,
    AutoComplete,
    AutoCompleteLocation,
    DatePicker,
    Preview,
  },
  data: () => ({
    searched: false,
    records: [],
    quadrants: [],
    filter: _.cloneDeep(initFilter),
    loading: false,
    footer: {
      pageCount: 0,
    },
    totalDocs: 0,
    totals: {
      tags: 0,
      releases: 0,
      recaptures: 0,
    },
    // header: Header,
    selected: undefined,
    itemFilter: null,
    rule: [(v) => !!v || "Required"],
    options: null,
    order: false,
    overlay: false,
    preview: false,
    message: null,
    notify: false,
  }),
  computed: {
    header() {
      const headers = _.cloneDeep(Header);

      if (this.filter.event === "tags") {
        headers.splice(1, 1);
      } else if (this.filter.event === "releases") {
        headers.splice(0, 2);
      }

      return headers;
    },
    searchForItems() {
      return [
        { key: "tags", name: this.$t("search.tags") },
        { key: "releases", name: this.$t("search.releases") },
        { key: "recaptures", name: this.$t("search.recaptures") },
        { key: "all", name: this.$t("search.all") },
      ];
    },
    all() {
      return this.filter.event === "all";
    },
    tagRule() {
      return [(v) => !v || v?.length >= 8 || "Minimum 6 digits required"];
    },
    showReset() {
      return !_.isEqual(this.filter, initFilter);
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.resetFilter();
  },
  watch: {
    options: {
      handler: "loadListView",
    },
  },
  methods: {
    ...mapActions("dashboard", ["getFilterResults"]),
    async sort(head) {
      this.header.forEach((item) => {
        if (item.key === head?.key) {
          item.order = !head?.order;
        } else {
          item.order = true;
        }
      });
      const header = this.header.find((item) => item?.key === head?.key);
      this.filter.sort = header.key;
      this.order = header.order;
      await this.loadListView();
    },
    async loadListView() {
      const { page, itemsPerPage: limit } = this.options || {};
      if (!page || !limit) {
        return;
      }

      this.loading = true;
      this.totals = {
        tags: 0,
        releases: 0,
        recaptures: 0,
      };
      this.totalDocs = 0;
      this.records = [];

      const response = await this.getFilterResults({
        ...this.filter,
        page,
        limit,
        order: this.order ? 1 : 2,
      });

      if (response?.data) {
        const { docs, totalPages, page, totalDocs } = response.data || {};
        if (!totalDocs || !docs) {
          this.loading = false;
          return;
        }
        this.records = docs; //this.modifyArray(docs);
        this.footer.pageCount = totalPages;
        this.footer.page = page;
        this.totalDocs = totalDocs;
        this.totals.tags = response.data?.totalTags || 0;
        this.totals.releases = response.data?.totalReleases || 0;
        this.totals.recaptures = response.data?.totalRecaptures || 0;
      }
      this.loading = false;
    },
    modifyArray(array) {
      return array?.map((item) => {
        const { angler, captain } = item || {};
        const { firstName: aFirstName, lastName: aLastName } = angler || {};
        const { firstName: cFirstName, lastName: cLastName } = captain || {};
        item.captain.fullName = [cFirstName, cLastName].join(" ");
        item.angler.fullName = [aFirstName, aLastName].join(" ");
        return item;
      });
    },
    resetFilter() {
      this.filter = window.structuredClone(initFilter);
    },
    submitFilter() {
      if (!this.searched) {
        const valid = this.$refs.filterForm && this.$refs.filterForm.validate();
        valid && (this.searched = true);
      } else this.searched = false;
    },
    async exportFile(format) {
      this.$refs.listView && (this.$refs.listView.loadingExport = true);
      const { page, itemsPerPage: limit } = this.options || {};
      const response = await this.getFilterResults({
        ...this.filter,
        page,
        limit,
        export: true,
        format,
      });

      const { docs, error } = response?.data || {};

      if (error) {
        this.message =
          "An error ocurred, its not possible generate the report file";
        this.notify = true;
        return;
      }

      getFile(docs, format, this.$t("search.tableTitle"));
      this.$refs.listView && (this.$refs.listView.loadingExport = false);
    },
    getSpecieName(item) {
      return item?.capture?.fish?.specie?.name || "";
    },
    isFishArray(item) {
      return item?.capture?.fish && item.capture.fish instanceof Array;
    },
    handleUserChange() {
      if (!this.user) {
        this.filter.role = undefined;
      }
    },
  },
};

const initFilter = {
  event: "all",
  species: undefined,
  ocean: undefined,
  tag: null,
  priorTag: null,
  location: null,
  catchDate: [null, null],
  key: null,
};

const Header = [
  // {
  //   text: "Actions",
  //   value: "actions",
  //   sortable: false,
  //   key: "actions",
  //   order: true,
  // },
  {
    text: "Tag Number",
    align: "start",
    sortable: true,
    value: "number",
    key: "number",
    order: true,
  },
  {
    text: "Prior Tag Number",
    align: "start",
    sortable: true,
    value: "priorTag.priorTagNumber",
    key: "priorNumber",
    order: true,
  },
  {
    text: "Ocean",
    align: "start",
    sortable: true,
    value: "capture.ocean",
    key: "ocean",
    order: true,
  },
  {
    text: "Species",
    align: "start",
    sortable: true,
    value: "captures",
    key: "species",
    order: true,
  },
  {
    text: "Location Description",
    align: "start",
    sortable: true,
    value: "locality",
    key: "location",
    order: true,
  },
  // {
  //   text: "Tag/Release Date",
  //   align: "start",
  //   sortable: true,
  //   value: "createdDate",
  //   key: "tagDate",
  // },
];
</script>

<style scoped>
.v-list-item {
  padding: 0;
}

.header_size {
  min-width: 150px;
}
</style>
