<template>
  <v-snackbar v-model="notify" :timeout="timeout" top>
    {{ message }}
    <template v-slot:action="{ attrs }" v-if="buttonLabel">
      <v-btn color="blue" text v-bind="attrs" @click="$emit('onclick')">
        {{ buttonLabel }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "snackbar-notification",
  props: {
    message: {
      type: String,
      required: true,
      default: "",
    },
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    timeout: {
      type: Number,
      default: -1,
    },
    buttonLabel: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    notify: {
      get() {
        return this.open && !!this.message;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
  },
};
</script>
