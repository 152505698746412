//Import vue components
import Vue from "vue";
import Vuex from "vuex";

//Initialize vuex plugins
Vue.use(Vuex);

//Import module dependencies
import clientFactory from "@/api";

//Import store modules
import EntryModule from "@/stores/modules/entry";
import UsersModule from "@/stores/modules/users";
import DashboardModule from "@/stores/modules/dashboard";
import SideBarModule from "@/stores/modules/sidebar";

//Initialize store modules
const entry = new EntryModule(clientFactory).store;
const users = new UsersModule(clientFactory).store;
const sidebar = new SideBarModule(clientFactory).store;
const dashboard = new DashboardModule(clientFactory).store;

export default new Vuex.Store({
  modules: {
    entry,
    users,
    sidebar,
    dashboard,
  },
});
